import * as React from 'react';
import { useParams } from 'react-router-dom';
import i18n from "i18next";
import { DataPanel } from '../_components/DataPanel';
import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import { history } from '../_helpers';
import { Resizable } from 're-resizable';
import { Selection, ActionButton, DetailsList, ScrollablePane, ScrollbarVisibility, Persona, PersonaSize, CheckboxVisibility, SelectionMode, IRenderFunction, IDetailsHeaderProps, IDetailsListCheckboxProps, ContextualMenu, MessageBar, IDetailsList, IContextualMenuProps, DirectionalHint, Icon, Spinner, SpinnerSize, Sticky, StickyPositionType, DetailsListLayoutMode } from '@fluentui/react';
import Moment from 'moment';
import { ConversationViewer } from '../_components/_cards/ConversationViewer';
import { userService } from '../_services';
import { ConnectInvite } from '../_components/_cards/ConnectInvite';
import { FileReceived } from '../_components/_cards/FileReceived';
import { FileSent } from '../_components/_cards/FileSent';
import { ConnectSent } from '../_components/_cards/ConnectSent';

export interface Props {
  routeProps: any
}

export interface States {
  projectId: string;
  showRightPanel: boolean;
  syncStatus: any;
  breadcrumb: any[];
  updated: boolean;
  query: string;
  selItem: any;
  panelHeight: any;
  panelWidth: any;
  activitiesFull:any;
  activities: any;
  showLast20: boolean;
  newToOld: boolean;
  allCardsCollapsed: boolean;
  cardsSortedBy: string;
  emails: any[];
  searchQuery: string;
  currentEmail: any;
  secondSelection: any;
  alertMessage: any;
  contextMenuProps: any;
  possibleContacts: any[];
  isLoading: boolean;
  view: string;
  columns: any[];
}

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class ConversationsPage extends React.Component<Props, States> {
  private _isMounted: boolean;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();
  private listExplorerRef:any = React.createRef();
  private detailsPanelRef:any = React.createRef();
  private _selection: Selection;
  private _selectionHeader: Selection;
  private _root:any = React.createRef<IDetailsList>();  

  props: any;

  private updateSelection() {
    let selItem:any = this._selection.getSelection().length === 1 ? this._selection.getSelection()[this._selection.getSelection().length - 1] : null;
    if (selItem && !selItem.content) {

    }
    else {
      this._isMounted && this.setState({
        selItem: selItem
      });
    }
  }

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.props = props

    this._selection = new Selection({
      onSelectionChanged: () => {
        let selItem = this._selection.getSelection().length === 1 ? this._selection.getSelection()[this._selection.getSelection().length - 1] : null;
        if (selItem) {
          this._selectionHeader.setAllSelected(false);
          this._isMounted && this.setState({
            selItem: this._selection.getSelection()[this._selection.getSelection().length - 1],
            currentEmail: this._selection.getSelection()[this._selection.getSelection().length - 1],
            showLast20: false
          });
        } else {
          this._isMounted && this.setState({
            selItem: null,
            currentEmail: null,
            secondSelection: null,
            //showLast20: false
          });
        }
        if (this._selection.getSelection().length > 1) {
          this._isMounted && this.setState({
            secondSelection: this._selection.getSelection()
          });
        } else {
          this._isMounted && this.setState({
            secondSelection: []
          });
        }
      }
    });
    this._selectionHeader = new Selection({
      onSelectionChanged: () => {
        if (this._selectionHeader.getSelection().length > 0) {
          this._isMounted && this.setState({
            selItem: null,
            currentEmail: null,
            showLast20: true
          });
        } else {
          this._isMounted && this.setState({
            selItem: null,
            currentEmail: null,
            showLast20: false
          });
        }
      }
    });

    var panelWidth = "260px"
    if (typeof(localStorage.getItem("sidebar3Prefs")) === "string") {
      var panelPrefs = JSON.parse(localStorage.getItem("sidebar3Prefs") || "[]");
      panelWidth = panelPrefs.width
    }

    this.state = {
      projectId: this.props.params.id,
      showRightPanel: false,
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      breadcrumb: [{
        text: i18n.t('app:conversations'),
        key: 'conversations',
        onClick: ()=>{history.push('/conversations')}
      }],
      updated: false,
      query: 'all',
      selItem: null,
      panelHeight: 'auto',
      panelWidth: panelWidth,
      activities: [],
      activitiesFull: [],
      showLast20: true,
      newToOld: true,
      allCardsCollapsed: false,
      cardsSortedBy: 'By date',
      emails: [],
      searchQuery: '',
      currentEmail: null,
      secondSelection: null,
      alertMessage: null,
      contextMenuProps: [],
      possibleContacts: [],
      isLoading: true,
      view: 'panel',
      columns: [],
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this.props.userData && this.getCurrentContent();
    this._isMounted && this._getColumns();
  }
  
  public componentDidUpdate(prevProps:any, prevState:any) {

    //Subpage or userData change
    if ((prevProps.subpage !== this.props.subpage) || (prevProps.userData !== this.props.userData)) {
      this.props.userData && this.getCurrentContent();
    }

    if (prevProps.view !== this.props.view) {
      this.updateSelection()
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  private getCurrentContent(hidden?: boolean) {
    this._isMounted && this.setState({
      activities: hidden ? this.state.activities : [],
      searchQuery: document.location.search,
      isLoading: true,
      syncStatus: {
        message: "Syncing",
        type: "info",
        icon: "spinner"
      }
    })
    
    let activity:any[] = [];
    const params = new URLSearchParams(document.location.search);
    const types = params.get('type')?.split(',') || null;
    const sortBy = params.get('sort') || '';
    const sortOrder = params.get('sortOrder') || '';
    const getConversations = userService.getConversations(sortBy ? '&sort='+sortBy+','+sortOrder : '&sort=modifiedDateTime,DESC').then(async (response:any)=>{
      activity = response.data.content.map((item)=>{return item ? {...item, type: 'CONVERSATION', key: item.id, date: item.modifiedDateTime} : null})
    }).catch((error)=>{
      console.log(error);
      this._isMounted && this.setState({
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      })
    })

    let admins:any[] = []
    const getRepoAdmins = userService.getRepoUsers('ADMIN').then((response)=>{
      admins = response.data;
      for (let i=0; i<admins.length; i++) {
        admins[i].key = admins[i].id
        admins[i].text = admins[i].name + (admins[i].surname ? (' ' + admins[i].surname) : '')
      }
      
    }).catch((error)=>{
      console.log(error);
      this._isMounted && this.setState({
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      });
    })

    let contacts:any[] = []
    const getRepoUsers = userService.getRepoUsers('INTERNAL').then((response)=>{
      contacts = response.data;
      for (let i=0; i<contacts.length; i++) {
          contacts[i].key = contacts[i].id
          contacts[i].text = contacts[i].name + (contacts[i].surname ? (' ' + contacts[i].surname) : '')
      }
    }).catch((error)=>{
      console.log(error);
      this._isMounted && this.setState({
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        }
      });
    })

    Promise.all([getConversations, getRepoAdmins, getRepoUsers]).then((res) => {
      this._isMounted && this.setState({
        activitiesFull: activity.filter((item)=>{return !types || types.includes(item.type.toLowerCase())}),
        activities: activity.filter((item)=>{return !types || types.includes(item.type.toLowerCase())}),
        updated: true,
        possibleContacts: contacts.concat(admins),
        isLoading: false,
        syncStatus: {
          message: "Synced",
          type: "success",
          icon: "SkypeCircleCheck"
        },
        selItem: null
      },()=>{
        setTimeout(()=>{this.updateSelection()},500)
      });
    })
  }

  private _getColumns() {

    const columns = [
      {
        key: 'icon',
        name: '#',
        fieldName: 'icon',
        minWidth: 1,
        isRowHeader: true,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
            item && item.image ?
              <img src={'data:image/png;base64,'+item.image} alt='Logo' style={{width: '28px', height: '28px', borderRadius: '0px'}}/>
            :
              <div style={{borderRadius: '0px'}}><Persona size={PersonaSize.size24} text={item.content && item.content.subject ? item.content.subject : item.subject || ''} /></div>
            )
        }
      },
      {
        key: 'subject',
        name: i18n.t('app:subject'),
        fieldName: 'subject',
        minWidth: 220,
        isRowHeader: true,
        isResizable: true,
        //isSorted: true,
        //isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        //onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <span title={ item.subject }>{ item.subject }</span>
          )
        },
      },
      {
        key: 'conversationType',
        name: i18n.t('app:conversationType'),
        fieldName: 'conversationType',
        minWidth: 220,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        //onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return (
            <div className='d-flex align-items-center w-100' style={{}}>
              <Icon iconName={item.conversationType === 'GENERAL' ? 'Chat' : item.conversationType === 'CONVERSATION' ? 'OfficeChat' : item.conversationType === 'ANNOUNCEMENT' ? 'Megaphone' : item.conversationType === 'APPROVAL' ? 'DocumentApproval' : item.notconversationTypeeType === 'REVIEW' ? 'CheckboxComposite' : item.conversationType === 'MEETING' ? 'JoinOnlineMeeting' : ''} style={{color: 'rgb(16, 110, 190)'}}/>
              <span className='ms-1 pr-2'>{item.conversationType === 'GENERAL' ? 'General' : item.conversationType === 'CONVERSATION' ? 'Conversation' : item.conversationType === 'ANNOUNCEMENT' ? 'Announcement' : item.conversationType === 'APPROVAL' ? 'Approval' : item.conversationType === 'REVIEW' ? 'Review' : item.conversationType === 'MEETING' ? 'Meeting' : ''}</span>
            </div>  
          )
        },
      },
      {
        key: 'modifiedDateTime',
        name: i18n.t('app:modifiedAt'),
        fieldName: 'modifiedDateTime',
        minWidth: 150,
        isResizable: true,
        isSorted: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        //onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return <span title={ Moment(item.modifiedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) }>{ Moment(item.modifiedDateTime).format(i18n.t('Y-MMM-DD HH:mm')) }</span>
        }
      },
      {
        key: 'createdDateTime',
        name: i18n.t('app:createdAt'),
        fieldName: 'createdDateTime',
        minWidth: 150,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        //onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
          return <span title={ Moment(item.createdDateTime).format(i18n.t('Y-MMM-DD HH:mm')) }>{ Moment(item.createdDateTime).format(i18n.t('Y-MMM-DD HH:mm')) }</span>
        }
      }
    ]

    this._isMounted && this.setState({columns: columns})
  }

  private sortItems(field:string) {
    const { newToOld, activities } = this.state;
    let sorted = _copyAndSort(activities, field, newToOld)
    this._isMounted && this.setState({
      newToOld: !newToOld,
      activities: sorted,
      cardsSortedBy: field === 'date' ? 'By date' : field === 'title' ? 'By title' : 'By date'
    })
  }

  private toggleAllCardsCollapse() {
    const { allCardsCollapsed } = this.state;
    this._isMounted && this.setState({allCardsCollapsed: !allCardsCollapsed});
  }

  private _onContextMenu(item, index, ev: MouseEvent | any) {
    ev.preventDefault();
    const selection:any[] = this._selection.getSelection();
    const selectedCount = selection.length;

    const contextMenuProps: IContextualMenuProps = {
      target: ev.target as HTMLElement,
      directionalHint: DirectionalHint.bottomLeftEdge,
      items: item.type === 'CONVERSATION' ? [
        {
          key:'editConversation',
          name:i18n.t('app:editConversation'),
          iconProps: { iconName: 'Edit' },
          className:'blue-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.headerRef && this.headerRef._showConversationFormEdit(),
        },
        {
          key:'deleteConversation',
          name:i18n.t('app:deleteConversation'),
          iconProps: { iconName: 'Delete' },
          className:'grey-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.headerRef && this.headerRef._showConversationFormDelete(),
        }
      ] : item.type === 'NOTE' ? [
        {
          key:'editNote',
          name:i18n.t('app:editNote'),
          iconProps: { iconName: 'Edit' },
          className:'blue-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.headerRef && this.headerRef._showNoteFormEdit(),
        },
        {
          key:'deleteNote',
          name:i18n.t('app:deleteNote'),
          iconProps: { iconName: 'Delete' },
          className:'grey-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.headerRef && this.headerRef._showNoteFormDelete(),
        }
      ] : item.type === 'EMAIL' ? [
        {
          key:'deleteEmail',
          name:i18n.t('app:deleteEmail'),
          iconProps: { iconName: 'Delete' },
          className:'grey-icon',
          disabled: selectedCount !== 1,
          onClick: () => this.headerRef && this.headerRef._showEmailFormDelete(),
        }
      ] : [],
      onDismiss: () => {
        this.setState({
          contextMenuProps: undefined
        });
      }
    }
    
    this._isMounted && this.setState({contextMenuProps: contextMenuProps})
    return true;

  }

  private getContactName(id: string) {
    let user = this.state.possibleContacts.find((item) => {
      return item.id === id;
    })

    return user ? (user.name || '') + ' ' + (user.surname || '') : ''
  }

  public render() {

    const { projectId, breadcrumb, query, activities, selItem, cardsSortedBy } = this.state
    return(
      <div>
        <Header
          ref={instance => { this.headerRef = instance; }}
          userData={ this.props.userData }
          page={this.props.page}
          items={[]}
          callbackFunction={this.callbackFunction}
          showRightPanel={ this.state.showRightPanel }
          syncStatus={this.state.syncStatus}
          breadcrumb={breadcrumb}
          sidebarRef={ this.sidebarRef }
          view={this.state.view}
        />
        <div className="content-wrap d-flex flex-row" >
          <Sidebar
            ref={(instance:any) => { this.sidebarRef = instance; }}
            headerRef={ this.headerRef }
            userData={ this.props.userData }
            page={this.props.page}
            subpage={this.props.subpage}
            callbackFunction={this.callbackFunction}
            items={activities}
            query={query}
          />
          { this.state.view === 'list' ? 
            <div className="list flex-grow-1 justify-content-center" style={{textAlign: 'left'}}>
              <div>
              <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsList
                  componentRef={this._root}
                  className={"listEl"}
                  styles={{root: {overflow: 'visible'}}}
                  items={activities}
                  compact={true}
                  columns={this.state.columns}
                  onRenderDetailsHeader={onRenderDetailsHeader}
                  selectionMode={SelectionMode.multiple}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.fixedColumns}
                  isHeaderVisible={true}
                  selection={this._selection}
                  selectionPreservedOnEmptyClick={false}
                  onItemInvoked={() => this._isMounted && this.setState({view: 'panel'})}
                  //onItemContextMenu={(item,index,ev) => this._onContextMenu(item,index,ev)}
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn="Toggle selection"
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  checkButtonAriaLabel="Row checkbox"
                  //shimmerLines={12}
                  constrainMode={this.props.constrainMode}
                  listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
                 // columnReorderOptions={this.state.groups ? undefined : getColumnReorderOptions()}
                  onRenderMissingItem={()=>{return document.location.search && this.props.searchMore()}}
                />
                { activities && activities.length === 0 && this.state.isLoading ? <Spinner/> : activities && activities.length === 0 ? <p className='text-center mt-2'>Empty</p> : null }
                { this.props.loadingMore && <Spinner/>}
              </ScrollablePane>

              { this.state.contextMenuProps ?
                <ContextualMenu {...this.state.contextMenuProps} />
              : null }

              {this.state.alertMessage ?
                <div id="alertMessage">
                  <MessageBar
                    messageBarType={this.state.alertMessage.type}
                    isMultiline={true}
                    dismissButtonAriaLabel="Close"
                    onDismiss={() => this.setState({alertMessage: null})}
                  >
                    <div dangerouslySetInnerHTML={{__html: this.state.alertMessage.text}}></div>
                  </MessageBar>
                </div>
              : null }
            </div>
            </div>
          :
          <div className='d-flex flex-grow-1' style={{overflowX: 'auto', overflowY: 'hidden', height: 'calc(100vh - 129px)'}}>
          <Resizable
            size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
            onResizeStop={(e, direction, ref, d) => {
              var currentWidth = this.state.panelWidth.match(/\d+/)[0];
              this._isMounted && this.setState({
                panelWidth: +currentWidth + +d.width + "px"
              }, () => {
                this._saveResizeState();
              });
            }}
            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
            minWidth={254}
            //maxWidth={400}
            handleStyles= {{
              right: {
                "right": "-6px",
                "width": "6px",
                "zIndex": 1
              }
            }}
            handleClasses={{"right": "resize-handle"}}
          >
            <nav className="tree-virtual second list-sidebar">
              <ScrollablePane style={{width: 'calc(100% - 1px)'}} scrollbarVisibility={ScrollbarVisibility.always}>
              <DetailsList
                componentRef={this._root}
                className='list-sidebar'
                items={activities}
                checkboxVisibility={CheckboxVisibility.always}
                onRenderCheckbox={checkboxColumn}
                onRenderDetailsHeader={onRenderDetailsHeader}
                onItemContextMenu={(item,index,ev) => this._onContextMenu(item,index,ev)}
                onItemInvoked={(item)=>{history.push('/conversations/id/'+item.id)}}
                columns={[
                  {
                    key: 'title',
                    name: 'Title',
                    fieldName: 'title',
                    minWidth: 1,
                    isRowHeader: true,
                    data: 'string',
                    isPadded: true,
                    onRender: (item: any) => {
                      return (
                        <>
                          <div className='d-flex align-items-center'  style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            <div className='activity-icon' style={{height: '24px', width: '24px', minWidth: '24px', position:'absolute', left: '-33px', top: '8px',  display: (this._selection.getSelection()[0] && this._selection.getSelection()[0].key === item.key) || this._selection.getSelection().length > 1  ? 'none' : 'block'}}>
                            <Persona size={PersonaSize.size32} text={item.content && item.content.subject ? item.content.subject : item.subject || ''} />
                            </div>
                            
                            <div className='align-items-center ms-1 flex-grow-1' style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                              <div className='d-flex'>
                                <span className='span-title me-2' style={{textOverflow: 'ellipsis', overflowX: 'hidden'}}>{item.content && item.content.subject ? item.content.subject : item.subject}</span>
                              </div>
                              <div className='d-flex'>
                                <span className='me-2' style={{fontSize:12, textOverflow: 'ellipsis', overflowX: 'hidden'}}>{this.getContactName(item.createdByUserId)}</span>
                              </div>
                              <div className='d-flex'>
                                <span className='' style={{fontSize:12, color: '#424242', overflowX: 'hidden', textOverflow: 'ellipsis', maxWidth: 'calc(100% - 80px)'}}>{item.from ? item.from.emailAddress.name : ''}</span>
                                <div className='d-flex align-items-center w-100' style={{}}>
                                  <Icon iconName={item.conversationType === 'GENERAL' ? 'Chat' : item.conversationType === 'CONVERSATION' ? 'OfficeChat' : item.conversationType === 'ANNOUNCEMENT' ? 'Megaphone' : item.conversationType === 'APPROVAL' ? 'DocumentApproval' : item.notconversationTypeeType === 'REVIEW' ? 'CheckboxComposite' : item.conversationType === 'MEETING' ? 'JoinOnlineMeeting' : ''} style={{fontSize: '12px', color: 'rgb(16, 110, 190)'}}/>
                                  <span className='ms-1 pr-2' style={{fontSize:12, color: '#424242', overflowX: 'hidden', textOverflow: 'ellipsis'}}>{item.conversationType === 'GENERAL' ? 'General' : item.conversationType === 'CONVERSATION' ? 'Conversation' : item.conversationType === 'ANNOUNCEMENT' ? 'Announcement' : item.conversationType === 'APPROVAL' ? 'Approval' : item.conversationType === 'REVIEW' ? 'Review' : item.conversationType === 'MEETING' ? 'Meeting' : ''}</span>
                                  <span className='ms-auto me-3' style={{fontSize:12, color: '#424242'}}>{item.content && item.content.modifiedDateTime ? Moment(item.content.modifiedDateTime).format('Y-MMM-DD HH:mm') : item.savedDateTime ? Moment(item.savedDateTime).format('Y-MMM-DD HH:mm') : Moment(item.modifiedDateTime).format('Y-MMM-DD HH:mm')}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    },
                    onRenderHeader: (item: any)=>{
                      return(
                        <div className='d-flex align-items-center w-100'>
                          <span style={{marginRight: '28px'}}> 
                          Title
                          </span>
                          <ActionButton 
                            className='ms-auto me-3 px-0' 
                            style={{color: 'rgb(50, 49, 48)'}} 
                            iconProps={{iconName:'SortLines'}}
                            text={cardsSortedBy} 
                            menuProps={{items: [
                              {
                                key: 'byDate',
                                text: 'By date',
                                iconProps: {iconName:'Calendar'},
                                onClick:()=>this.sortItems('date')
                              },
                              {
                                key: 'byName',
                                text: 'By title',
                                iconProps: {iconName:'HalfAlpha'},
                                onClick:()=>this.sortItems('title')
                              }
                            ]}}
                          />
                        </div>
                    )},

                  },
                ]}
                selection={ this._selection }
                setKey="set"
              />
              </ScrollablePane>
              { this.state.contextMenuProps ?
                <ContextualMenu {...this.state.contextMenuProps} />
              : null }
              {(!activities || activities.length === 0) && this.state.syncStatus.message === 'Synced' && <div style={{opacity: .8}} className='mt-5 pt-2 text-center'>No activities</div> }
              {(!activities || activities.length === 0) && this.state.syncStatus.message === 'Syncing' && <Spinner className='mt-5 pt-2 text-center' size={SpinnerSize.medium}/> }
            </nav>
          </Resizable>
          <div className="list me-auto flex-grow-1 d-flex flex-column" style={{minWidth: '420px'}}>
            <div style={{overflowY: 'hidden', overflowX: 'auto', minWidth: '400px', height: '100%'}}>
            <div className='d-flex header-card py-2'>
              <div className='d-flex flex-grow-1 align-items-center ms-1'>
                {this.state.selItem ? 
                <>
                  <div className='activity-icon' style={{height: '24px', width: '24px', minWidth: '24px', position:'absolute', left: '-33px', top: '8px', display: 'block'}}>
                    <Persona size={PersonaSize.size32} text={this.state.selItem.content && this.state.selItem.content.subject ? this.state.selItem.content.subject : this.state.selItem.subject || ''} />
                  </div>
                  <span style={{fontWeight: 600}}>{this.state.selItem.subject}</span>
                </>
                :
                i18n.t('app:mainView')
                }
              </div>
            </div>
            {this.state.alertMessage ?
              <div id="alertMessage">
                <MessageBar
                  messageBarType={this.state.alertMessage.type}
                  isMultiline={true}
                  dismissButtonAriaLabel="Close"
                  onDismiss={() => this.setState({alertMessage: null})}
                >
                  <div dangerouslySetInnerHTML={{__html: this.state.alertMessage.text}}></div>
                </MessageBar>
              </div>
            : null }
            <div className='flex-grow-1' style={{height: 'calc(100% - 44px)'}}>
              { this.state.selItem ?
                <div className='h-100' key={this.state.selItem.key}>
                  {this.activityCard(this.state.selItem)}
                </div>
              : this._selection && this._selection.getSelection().length > 1 ?
              <div className='text-center h-100  d-flex flex-column' style={{backgroundColor: 'rgb(250, 249, 248)'}}> 
                <div className='text-center text-secondary m-auto'>
                  <Icon style={{fontSize: '24px'}} iconName='Documentation'/>
                  <p>{this._selection.getSelection().length + ' ' + i18n.t('app:itemsSelected')}</p>
                </div>
              </div> 
              : 
              <div className='text-center h-100  d-flex flex-column' style={{backgroundColor: 'rgb(250, 249, 248)'}}> 
                <div className='text-center text-secondary m-auto'>
                  <Icon style={{fontSize: '24px'}} iconName='Preview'/>
                  <p>{i18n.t('app:noItemSelected')}</p>
                </div>
              </div> 
              }
              </div>
            </div>
          </div>
          </div>
          }

          {<DataPanel
            appPlatform={ this.props.appPlatform }
            userData={ this.props.userData }
            showRightPanel={ this.state.showRightPanel }
            callbackFunction={this.callbackFunction}
            page={this.props.page}
            id={projectId}
            item={selItem}
          />}
        </div>
      </div>
    )
  }

  private _saveResizeState() {
    const { panelHeight, panelWidth } = this.state;
    
    var prefs = {
      height: panelHeight,
      width: panelWidth
    }

    localStorage.setItem("sidebar3Prefs", JSON.stringify(prefs))
  }

  private activityCard(item) {
    if (this.props.subpage === 'connectInvitesReceived') {
      return (
        <div className='h-100' onClick={()=>{this._isMounted && this.setState({selItem: item})}}>
          <ConnectInvite
            allCardsCollapsed={this.state.allCardsCollapsed}
            item={item}
            userData={this.props.userData}
            headerRef={this.headerRef}
            updateConversation={this.updateConversation.bind(this)}
            possibleContacts={this.state.possibleContacts}
          />
        </div>
      )
    } else if (this.props.subpage === 'connectInvitesSent') {
      return (
        <div className='h-100' onClick={()=>{this._isMounted && this.setState({selItem: item})}}>
          <ConnectSent
            allCardsCollapsed={this.state.allCardsCollapsed}
            item={item}
            userData={this.props.userData}
            headerRef={this.headerRef}
            updateConversation={this.updateConversation.bind(this)}
            possibleContacts={this.state.possibleContacts}
          />
        </div>
      )
    } else if (this.props.subpage === 'filesyncReceived') {
      return (
        <div className='h-100' onClick={()=>{this._isMounted && this.setState({selItem: item})}}>
          <FileReceived
            allCardsCollapsed={this.state.allCardsCollapsed}
            item={item}
            userData={this.props.userData}
            headerRef={this.headerRef}
            updateConversation={this.updateConversation.bind(this)}
            possibleContacts={this.state.possibleContacts}
          />
        </div>
      )
    } else if (this.props.subpage === 'filesyncSent') {
      return (
        <div className='h-100' onClick={()=>{this._isMounted && this.setState({selItem: item})}}>
          <FileSent
            allCardsCollapsed={this.state.allCardsCollapsed}
            item={item}
            userData={this.props.userData}
            headerRef={this.headerRef}
            updateConversation={this.updateConversation.bind(this)}
            possibleContacts={this.state.possibleContacts}
          />
        </div>
      )
    } else
    return (
      <div className='h-100' onClick={()=>{this._isMounted && this.setState({selItem: item})}}>
        <ConversationViewer
          allCardsCollapsed={this.state.allCardsCollapsed}
          item={item}
          userData={this.props.userData}
          headerRef={this.headerRef}
          updateConversation={this.updateConversation.bind(this)}
          possibleContacts={this.state.possibleContacts}
        />
      </div>
    )
  }

  private updateConversation(conversation:any, ) {
    let activites = this.state.activities;
    for (let i in activites) {
      if (activites[i].id === conversation.id) {
        activites[i] = conversation;
      }
    }
    this._isMounted && this.setState({activities: activites})
  }
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean, secondColumnKey?: string): T[] {
  const key = columnKey as keyof any;
  const key2 = secondColumnKey as keyof any;
  if (key === 'amount') {
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? parseFloat(a[key].split(' ')[0]) < parseFloat(b[key].split(' ')[0]) : parseFloat(a[key].split(' ')[0]) > parseFloat(b[key].split(' ')[0])) ? 1 : -1));
  } else {
    if (key2 && key2 !== key) return items.slice(0).sort((a: T, b: T) => {
      if (a[key2] < b[key2]) return -1;
      if (a[key2] > b[key2]) return 1;
      else if (a[key] < b[key]) return isSortedDescending ? 1 : -1;
      else if (a[key] > b[key]) return isSortedDescending ? -1 : 1;
      else return 0;
    })
    else return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  }
}

function checkboxColumn(props: any, defaultRender?: IRenderFunction<IDetailsListCheckboxProps>): JSX.Element {
  return  (
    <div className='d-flex align-items-center justifty-content-center h-100 w-100'>
      {defaultRender!({
        ...props
      })}
    </div>
  )
}

function onRenderDetailsHeader(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
      {defaultRender!({
        ...props
      })}
    </Sticky>
  );
}

export default withParams(ConversationsPage);