import * as React from 'react';
import ReactDOM from 'react-dom';
import { userService } from '../_services';
import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import Moment from 'moment';
import "moment/min/locales";
import { Fabric, ShimmeredDetailsList, ScrollablePane, ScrollbarVisibility, IColumn, ConstrainMode, Selection, Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton, IconButton, TextField, ChoiceGroup, IChoiceGroupOption, MessageBar, MessageBarType, IButtonStyles, Spinner, SpinnerSize, Icon, DetailsHeader, IDetailsHeaderProps, ITooltipHostProps, IRenderFunction, IDetailsListStyles, Dropdown } from '@fluentui/react';
import i18n from "i18next";
import { DetailsPanel } from '../_components/DetailsPanel';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import * as XLSX from 'xlsx';
import { history } from './../_helpers';


export interface Props {
  location: any;
  userData: any;
  page:string;
}

export interface UsersState { 
  userData: any;
  breadcrumb: any[];
  columns: IColumn[];
  selItems: any;
  items: any;
  loadingActivity: boolean;
  users: any[];
  userFull: any[]
  usersNav: string;
  showRightPanel: boolean;
  filterByNameText: string;
  sheetFileUsers: any;
  importing: boolean;
  filterName: string;
  filterSurname: string;
  filterEmail: string;
  filterCategory: string;
  filterLastLogin: string;
  filterState: string;
}

export interface IDocument {
  key: string;
  name: string;
  surname: string
  email: string;
  role: string;
  license: string;
  state: string;
  lastLogin: number;
  activationDate: number;
  invitedBy: string;
}

const iconStyles: Partial<IButtonStyles> = {
  icon: {fontSize: "18px"}
}

export class Users extends React.Component<Props, UsersState> {
  private _isMounted: boolean;
  private _selection: Selection;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();
  private columns:IColumn[];
  private columnsExternalPending:IColumn[];
  private columnsImport:IColumn[];
  private users:any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._selection = new Selection({
      onSelectionChanged: () => {
        var selItem: any = this._selection.getSelection()[this._selection.getSelection().length - 1]

        
        setTimeout(() => {
          if (selItem && this._selection.getSelectedCount() === 1) {
            this._isMounted && this.setState({selItems: selItem});
          } else if (selItem && this._selection.getSelectedCount() > 1) {
            this._isMounted && this.setState({selItems: null});
          } else if (this._selection.getSelectedCount() === 0) {
            this._isMounted && this.setState({selItems: null});
          }
        }, 50)
      }
    });

    this.columns = [
      {
        key: 'name',
        name: i18n.t('users:name'),
        fieldName: 'name',
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        minWidth: 170,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              <div>
                <span>{ item.name }</span>
              </div>
            )
        }
      },
      {
        key: 'surname',
        name: i18n.t('users:surname'),
        fieldName: 'surname',
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        minWidth: 170,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              <div>
                <span>{item.surname}</span>
              </div>
            )
        }
      },
      {
        key: 'email',
        name: i18n.t('users:Email'),
        fieldName: 'email',
        minWidth: 170,
        maxWidth: 240,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              <div>
                <span>{ item.email }</span>
              </div>
            )
        }
      },
      {
        key: 'role',
        name: i18n.t('users:category'),
        fieldName: 'role',
        minWidth: 80,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              <div>
                <span className="capitalize">{ item.role }</span>
              </div>
            )
        }
      },
      {
        key: 'State',
        name: i18n.t('users:state'),
        fieldName: 'state',
        minWidth: 80,
        maxWidth: 120,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              item.state === "PENDING" ?
                <div>
                  <span className="capitalize align-top">{ item.state }</span>
                  <IconButton onClick={() => this._resendActivationEmail(item)} iconProps={{iconName: 'Mail'}} text={i18n.t('users:resend-activation')} className="inline-action-button ml-1" />
                </div>
              : item.state === "REMOVED" ?
                <div>
                  <span className="capitalize align-top">{ item.state }</span>
                  <IconButton onClick={() => this._restoreUser(item)} iconProps={{iconName: 'ArrangeSendToBack'}} text={i18n.t('users:restore-user')} className="inline-action-button ml-1" />
                </div>
              : 
                <div>
                  <span className="capitalize">{ item.state }</span>
                </div>
            )
        }
      },
      {
        key: 'lastLogin',
        name: i18n.t('users:lastLogin'),
        fieldName: 'lastLogin',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        data: 'number',
        onColumnClick: this._onColumnClick,
        onRender: (item: IDocument) => {
            return item.lastLogin ? <span>{ Moment(item.lastLogin).format('Y-MM-DD HH:mm') }</span> : null;
        },
        isPadded: true
      }
    ];

    this.columnsExternalPending = [
      {
        key: 'email',
        name: i18n.t('users:Email'),
        fieldName: 'email',
        isSorted: true,
        isSortedDescending: false,
        minWidth: 170,
        maxWidth: 240,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              <div>
                <span>{ item.email }</span>
              </div>
            )
        }
      },
      {
        key: 'invitedBy',
        name: i18n.t('users:invitedBy'),
        fieldName: 'invitedBy',
        minWidth: 170,
        maxWidth: 240,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              <div>
                <span>{ item.invitedBy }</span>
              </div>
            )
        }
      },
      {
        key: 'role',
        name: i18n.t('users:category'),
        fieldName: 'role',
        minWidth: 80,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              <div>
                <span className="capitalize">{ item.role }</span>
              </div>
            )
        }
      },
      {
        key: 'folder',
        name: i18n.t('users:folder'),
        fieldName: 'folder',
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (
              <div>
                { item.folder ?
                  <span>{item.folder}</span>
                  : <DefaultButton onClick={()=>this.showFolder(item)} title={ i18n.t('users:show') } text={i18n.t('users:show')} />
                }
              </div>
            )
        }
      },
      {
        key: 'date',
        name: i18n.t('users:date'),
        fieldName: 'date',
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        data: 'number',
        onColumnClick: this._onColumnClick,
        onRender: (item: any) => {
            return item.date ? <span>{ Moment(item.date).format('Y-MM-DD HH:mm') }</span> : null;
        },
        isPadded: true
      }
    ];

    this.columnsImport = [
      {
        key: 'name',
        name: i18n.t('users:name'),
        fieldName: 'name',
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        minWidth: 170,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              <div>
                <span>{ item.name }</span>
              </div>
            )
        }
      },
      {
        key: 'surname',
        name: i18n.t('users:surname'),
        fieldName: 'surname',
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        minWidth: 170,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              <div>
                <span>{item.surname}</span>
              </div>
            )
        }
      },
      {
        key: 'email',
        name: i18n.t('users:Email'),
        fieldName: 'email',
        minWidth: 170,
        maxWidth: 240,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              <div>
                <span>{ item.email }</span>
              </div>
            )
        }
      },
      {
        key: 'role',
        name: i18n.t('users:role'),
        fieldName: 'role',
        minWidth: 80,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              <div>
                <span className="capitalize">{ item.role }</span>
              </div>
            )
        }
      },
      {
        key: 'state',
        name: i18n.t('users:state'),
        fieldName: 'role',
        minWidth: 80,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              <div>
                <span className="capitalize">{ i18n.t('users:'+item.state) }</span>
              </div>
            )
        }
      },
    ];

    this.state = {
      userData: null,
      breadcrumb: [],
      columns: this.columns,
      selItems: null,
      items: [],
      loadingActivity: true,
      users: [{id:0, email:"", name:"", surname:"", role:""}],
      userFull: [{id:0, email:"", name:"", surname:"", role:""}],
      usersNav: this.props.page,
      showRightPanel: true,
      filterByNameText: '',
      sheetFileUsers: [],
      importing: false,
      filterName: '',
      filterSurname: '',
      filterEmail: '',
      filterCategory: '',
      filterLastLogin: '',
      filterState: '',
    }
  }

  public componentDidMount() {
      this._isMounted = true;
      this.setBreadcrumb();
      if (this.props.page === 'import') this._getUsersFromFile();
      else if (this.props.userData) {
        var repo = this.props.userData.repository.id;
        this._getUsers(repo);
      }
  }

  componentDidUpdate (prevProps: any, prevState:any) {
    if ((this.props.userData !== prevProps.userData || prevState.usersNav !== this.state.usersNav) && (this.state.usersNav === 'internalUsers' || this.state.usersNav === 'all' || this.state.usersNav === 'externalUsers')) {
      let repo = this.props.userData.repository.id;
      this.setBreadcrumb();
      if (this.props.page === 'import') this._getUsersFromFile();
      else this._getUsers(repo);
    }
    else if ((prevState.usersNav !== this.state.usersNav || prevState.sheetFileUsers !== this.state.sheetFileUsers) && this.state.usersNav === 'import') {
      this.setBreadcrumb();
      this._getUsersFromFile();
    }

    if(prevState.filterByNameText !== this.state.filterByNameText) {
      this._isMounted && this.setState({
        items: this.state.userFull.filter((item)=>{
          return (item.name + " " + item.surname).toLowerCase().includes(this.state.filterByNameText)
        })
      })
    }

    if (prevState.filterName !== this.state.filterName
      || prevState.filterSurname !== this.state.filterSurname
      || prevState.filterEmail !== this.state.filterEmail
      || prevState.filterCategory !== this.state.filterCategory
      || prevState.filterLastLogin !== this.state.filterLastLogin
      || prevState.filterState !== this.state.filterState
    ) {
      this._isMounted && this.setState({items: this.filterResult(this.state.userFull)})
    }
  }

  private filterResult(items) {
    const {filterName, filterSurname, filterEmail, filterCategory, filterState} = this.state;
    let users = items;
    if (filterName.length > 0) {
      users = users.filter((user)=>{return user.name && user.name.toLowerCase().includes(this.state.filterName.toLowerCase())})
    }
    if (filterSurname.length > 0) {
      users = users.filter((user)=>{return user.surname && user.surname.toLowerCase().includes(this.state.filterSurname.toLowerCase())})
    }
    if (filterEmail.length > 0) {
      users = users.filter((user)=>{return user.email && user.email.toLowerCase().includes(this.state.filterEmail.toLowerCase())})
    }
    if (filterCategory.length > 0) {
      users = users.filter((user)=>{return user.role && user.role.toLowerCase().includes(this.state.filterCategory.toLowerCase())})
    }
    if (filterState.length > 0) {
      users = users.filter((user)=>{return user.state && user.state.toLowerCase().includes(this.state.filterState.toLowerCase())})
    }
    return users;
  }

  private setBreadcrumb() {
    this._isMounted && this.setState({
      breadcrumb: [
        {
          text: 'Users',
          key: '/users/active',
          //onClick: this.headerRef._onBreadcrumbItemClicked.bind(this)
        }
      ]
    })
  }

  private showFolder(item:any){
    userService.getFolderDetails(item.folderId).then((response)=>{
      item.folder = response.data.name
    })

    const items = this.state.items;
    for (let i=0; i<items.length;i++) {
      if (items[i].key === item.key) {
        items[i] = item;
      }
    }
    this._isMounted && this.setState({items:items})
    setTimeout(()=>{
      this._isMounted && this.setState({columns:this.columnsExternalPending})
    },50)
  }

  private filtersActive(){
    if (this.state.filterName
      || this.state.filterSurname
      || this.state.filterEmail
      || this.state.filterCategory
      || this.state.filterLastLogin
      || this.state.filterState
    ) return true
    return false
  }

  private clearFilters() {
    this._isMounted && this.setState({
      filterCategory: '',
      filterEmail: '',
      filterLastLogin: '',
      filterName: '',
      filterState: '',
      filterSurname: ''
    })
  }

  public render() {
    const { items, loadingActivity } = this.state;

    const callbackFunction = (childData) => {
      this._isMounted && this.setState(childData)
    }

    return(
      <Fabric>
        <Header
          ref={(instance: any) => { this.headerRef = instance; }}
          userData={ this.props.userData }
          breadcrumb={ this.state.breadcrumb }
          selection={ this._selection }
          addUser={ this._addUser.bind(this) }
          addMultipleUsers={ this._addMultipleUsers.bind(this) }
          editUser={ this.props.page === 'import' ? this._editImportedUser.bind(this) : this._editUser.bind(this) }
          deleteUser= { this._deleteUser.bind(this) }
          copyEmails={this._copyEmails.bind(this)}
          getUsers={()=>{if (this.props.userData && this.props.userData.repository) this._getUsers(this.props.userData.repository.id)}}
          actions="users"
          sidebarRef={this.sidebarRef}
          callbackFunction={ callbackFunction }
          showRightPanel={ this.state.showRightPanel }
          page={this.props.page}
          importing={this.state.importing}
          sheetFileUsers={this.state.sheetFileUsers}
          importUsers={ this._importUsers.bind(this) }
          createUsersFromFile={ this._createUsersFromFile.bind(this) }

        />

        <div className="content-wrap d-flex flex-row">
          <div className="fakeLine"></div>
          <Sidebar ref={(instance: any) => { this.sidebarRef = instance; }} callback={callbackFunction} userData={ this.props.userData } page={this.state.usersNav}/>

          <div className="list mr-auto flex-grow-1">
            {this.filtersActive() && <IconButton onClick={()=>{this.clearFilters()}} iconProps={{iconName: 'ClearFilter'}} style={{position: 'absolute', top: '42px', left: '10px', zIndex: 9}}>asdasdas</IconButton>}
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <ShimmeredDetailsList
                className='users-list'
                items={this.state.usersNav === 'internalUsers' || this.state.usersNav === 'externalUsers' || this.state.usersNav === 'all' || this.state.usersNav === 'import' ? items: []}
                compact={true}
                columns={this.state.usersNav === 'import' ? this.columnsImport : this.columns}
                setKey="set"
                selection={this._selection}
                isHeaderVisible={true}
                enableShimmer={loadingActivity}
                constrainMode={ConstrainMode.unconstrained}
                ariaLabelForShimmer="Content is being fetched"
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                shimmerLines={12}
                onRenderDetailsHeader={this.renderDetailsHeader}
              />
              {!this.state.loadingActivity && !this.state.items && <div className='w-100 mt-2'>
                  <p style={{textAlign: 'center', color: '#6c757d'}}>No users</p>
                </div>
              }
              {this.state.usersNav === 'import' && items && items.length === 0 && <div className='w-100 mt-2'>
                  <p style={{textAlign: 'center', color: '#6c757d'}}>No users to import</p>
                </div>
              }

            </ScrollablePane>
          </div>
          <DetailsPanel
              //ref={instance => { this.detailsPanelRef = instance; }}
              userData={ this.props.userData }
              //items={ companies }
              showRightPanel={ this.state.showRightPanel }
              selItem={ this.state.selItems }
              headerRef={ this.headerRef }
              _selection={this._selection}
              detailsPanelSection="users"
              callbackFunction={ callbackFunction }
            />
        </div>
      </Fabric>
    )
  }

  private onFilterChange(event: any, field: any) {
    console.log(
      "Filter Update - [" + field + " ] :: " + event.currentTarget.value
    );

    let textFilter = event.currentTarget.value;
    this.state.userFull.filter((item)=>{
      return (item.name + " " + item.surname).toLowerCase().includes(this.state.filterByNameText)
    })
    /*this.setState({
      sortedItems: textFilter
        ? items.filter(
            (i) => i.color.toLowerCase().indexOf(textFilter.toLowerCase()) > -1
          )
        : items
    });*/
  }

  private renderCustomHeaderTooltip(tooltipHostProps: ITooltipHostProps) {
    var column = (tooltipHostProps as any).column;
    if (column === undefined) {
      return (
        <div className={tooltipHostProps.hostClassName}>
          <span>{tooltipHostProps.children}</span>
        </div>
      );
    } else if (column && column.key === 'name') {
      return (
        <div className={tooltipHostProps.hostClassName}>
          <span>{tooltipHostProps.children}</span>
          <TextField
            placeholder="Filter"
            onChange={(_e, value) => {this._isMounted && this.setState({filterName: value ?? ''})}}
            value={this.state.filterName}
            className='mx-1'
            styles={{fieldGroup: {borderColor: '#bfbfbf'}}}
          />
        </div>
      );
    } else if (column && column.key === 'surname') {
      return (
        <div className={tooltipHostProps.hostClassName}>
          <span>{tooltipHostProps.children}</span>
          <TextField
            placeholder="Filter"
            onChange={(_e, value) => {this._isMounted && this.setState({filterSurname: value ?? ''})}}
            value={this.state.filterSurname}
            className='mx-1'
            styles={{fieldGroup: {borderColor: '#bfbfbf'}}}
          />
        </div>
      );
    } else if (column && column.key === 'email') {
      return (
        <div className={tooltipHostProps.hostClassName}>
          <span>{tooltipHostProps.children}</span>
          <TextField
            placeholder="Filter"
            onChange={(_e, value) => {this._isMounted && this.setState({filterEmail: value ?? ''})}}
            value={this.state.filterEmail}
            className='mx-1'
            styles={{fieldGroup: {borderColor: '#bfbfbf'}}}
          />
        </div>
      );
    } else if (column && column.key === 'role') {
      return (
        <div className={tooltipHostProps.hostClassName}>
          <span>{tooltipHostProps.children}</span>
          <Dropdown
            styles={{title: {border: '1px solid #bfbfbf'}}}
            className='mx-1'
            options={[{key: '', text: ''}, {key: 'ADMIN', text: i18n.t('users:admin')}, {key: 'INTERNAL', text: i18n.t('users:Internal')}, {key: 'GUEST', text: i18n.t('users:guest')}]}
            selectedKey={this.state.filterCategory}
            onChange={(_e, option:any) => {this._isMounted && this.setState({filterCategory: option.key ?? ''})}}
            />
        </div>
      );
    } else if (column && column.key === 'State') {
      return (
        <div className={tooltipHostProps.hostClassName}>
          <span>{tooltipHostProps.children}</span>
          <Dropdown
            styles={{title: {border: '1px solid #bfbfbf'}}}
            className='mx-1'
            options={[{key: '', text: ''}, {key: 'ACTIVE', text: i18n.t('groups:active')}, {key: 'PENDING', text: i18n.t('groups:pending')}, {key: 'DISABLED', text: i18n.t('groups:disabled')}]}
            selectedKey={this.state.filterState}
            onChange={(_e, option:any) => {this._isMounted && this.setState({filterState: option.key ?? ''})}}
            />
        </div>
      );
    }
    return <div className={tooltipHostProps.hostClassName}>
      <span>{tooltipHostProps.children}</span>
    </div>
  }

  private renderDetailsHeader = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
    return (
      <DetailsHeader
        {...props}
        onRenderColumnHeaderTooltip={this.renderCustomHeaderTooltip.bind(this)}
      />
    );
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    var newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);

    localStorage.setItem("dashboardColumns", JSON.stringify(newColumns))

    this._isMounted && this.setState({
      columns: newColumns,
      items: newItems
    });
  };

  private _getUsers(id:string) {
    this._isMounted && this.setState({ loadingActivity: true })
    userService.getRepoUsers(this.state.usersNav === 'all' ? 'ALL' : this.state.usersNav === 'internalUsers' ? 'INTERNAL,ADMIN' : 'GUEST').then((response: any) => {
      var users = response.data.map((item:any) => {
        return ({
          key: item.id,
          name: item.name,
          surname: item.surname,
          email: item.email,
          license: item.subscription,
          state: item.state,
          role: item.role || "UNDEFINED",
          activationDate: item.activationDate,
          lastLogin: item.lastLogin
        })
      })

      users = users.sort((a:any,b:any) => {
        return (a.name || '').localeCompare(b.name|| '');
      });

      this.users = users;
      this.setState({
        items: this.filterResult(users),
        userFull:users,
        loadingActivity: false
      })
    }).catch((error: any) => {
      console.log(error)
      this._isMounted && this.setState({ loadingActivity: false })
    })
  }

  private _getUsersFromFile() {
    if (this.state.sheetFileUsers && this.state.sheetFileUsers.length > 0) {
      var users = this.state.sheetFileUsers.map((item:any, index:number) => {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return ({
          key: item.email + index,
          name: item.name,
          surname: item.surname,
          email: item.email,
          role: item.role,
          state: item.email.match(validRegex) ? 'notCreated' : 'invalidEmail'
        })
      })
  
      users = users.sort((a:any,b:any) => {
        return (a.email || '').localeCompare(b.email|| '');
      });
  
      this.setState({
        items: users,
        loadingActivity: false
      })
    } else {
      this.setState({
        items: [],
        loadingActivity: false
      })
    }
  }

  private _addUser = () => {
    var repo = this.props.userData.repository;
    var showDialog = true;
    var role: string = "INTERNAL";
    var email: string = "";
    var name: string = "";
    var surname: string = "";

    const options: IChoiceGroupOption[] = [
      { key: 'INTERNAL', text: i18n.t('users:internal') },
      { key: 'GUEST', text: i18n.t('users:guest') },
      { key: 'ADMIN', text: i18n.t('users:admin') }
    ];

    var createUser = () => {
      userService.createRepoUser(email, name, surname, role, localStorage.serverdomain || 'eu.synergy.page')
        .then((response: any) => {
          showDialog = false;
          renderDialog();
          this._getUsers(repo.id)
        }).catch(error => {
          console.log(error)
          showDialog = false;
          renderDialog();
        })
    }

    var handleEmailChange = (ev: any, newValue?: string) => {
      email = newValue || ""
      renderDialog()
    }

    var handleNameChange = (ev: any, newValue?: string) => {
      name = newValue || ""
      renderDialog()
    }

    var handleSurnameChange = (ev: any, newValue?: string) => {
      surname = newValue || ""
      renderDialog()
    }

    var handleUserTypeChange = (ev: any, option: any) => {
      role = option.key || ""
      renderDialog()
    }

    var div = document.createElement('div');
    var renderDialog = () => {

      ReactDOM.render(
        <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: <div>{i18n.t('users:createUser')}</div>
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "add-group-dialog"
            }}
            onDismiss={() => {showDialog = false; renderDialog()}}
          >

          <div className="dialog-content-wrap">
            <TextField label={i18n.t('users:email')} underlined autoFocus placeholder={i18n.t('users:email')}  name="newItemName" value={ email } onChange={ handleEmailChange } required />
            <TextField label={i18n.t('users:name')} className="mt-3" underlined placeholder={i18n.t('users:name')}  name="newItemName" value={ name } onChange={ handleNameChange } required />
            <TextField label={i18n.t('users:surname')} className="mt-3" underlined placeholder={i18n.t('users:surname')}  name="newItemName" value={ surname } onChange={ handleSurnameChange } required />
            <ChoiceGroup className="mt-3" defaultSelectedKey={ role } options={options} onChange={handleUserTypeChange} label={i18n.t('users:pickUserType')} required />

            <DialogFooter className="mt-4">
              <DefaultButton onClick={() => {showDialog = false; renderDialog()}} text={i18n.t('users:cancel')}  />
                <PrimaryButton onClick={() => createUser()} text={i18n.t('users:save')}  disabled={!email.length || !name.length || !surname.length || !role.length} />
            </DialogFooter>
          </div>

          </Dialog>
        , div
      )
    }

    renderDialog();

  }

  private createUser = async (email, name, surname, role) => {
    return await new Promise((resolve, reject) => {
      userService.createRepoUser(email, name, surname, role, localStorage.serverdomain || 'eu.synergyos.com')
      .then((response: any) => {
        resolve(response)
      }).catch(error => {
        resolve(error)
      })
    })
  }

  private async _createUsersFromFile() {
    let sheetFileUsers = this.state.items;
    this._isMounted && this.setState({importing: true})
    for (let i in sheetFileUsers) {
      let user:any = sheetFileUsers[i];
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (user.email.match(validRegex) && user.state !== 'invalidEmail' && user.state !== 'created' && user.state !== 'alreadyExists') {
        await this.createUser(user.email, user.name, user.surname, user.role).then((result:any)=>{
          if (result.status === 409 ) sheetFileUsers[i].state = 'alreadyExists'
          else if (result.status === 201 ) sheetFileUsers[i].state = 'created'
          else sheetFileUsers[i].state = 'failed'
          this._isMounted && this.setState({items: sheetFileUsers.slice(0)})
          console.log(sheetFileUsers)
        })
      }
    }
    this._isMounted && this.setState({importing: false})
  }

  private _importUsers = () => {
    var showDialog = true;
    var file:any;
    var fileExtension = '';

    var importUsers = () => {
      var reader = new FileReader();
      var self = this;
      reader.onload = function(e:any) {
        var data = e.target.result;
        const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'array', raw: true, cellFormula: false });
        var json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
        history.push('/users/import')
        showDialog = false;
        renderDialog();
        self._isMounted && self.setState({sheetFileUsers: json, usersNav: 'import'})
      };
      reader.readAsArrayBuffer(file)
      
    }

    var _showFilePicker = () => {
      var input = document.createElement('input');
      input.type = 'file';
      input.accept= '.xls, .xlsx, .xlsm, .csv,'

      input.onchange = e => {
        let event:any = e as Event;
        event.stopPropagation();
        event.preventDefault();

        file = event.target.files[0]
        fileExtension = file.name.split('.').pop()
        renderDialog()
      }

      input.click();
    }

    var onDragEnter = (event) => {
      if (event.target.className.indexOf("droptarget")!== -1) {
        event.target.style.background = "#C7E0F4";
      }
    }

    var onDragLeave = (event) => {
      if (event.target.className.indexOf("droptarget")!== -1) {
        event.target.style.background = "transparent";
      }
    }

    var onDragOver = (event) => {
      event.stopPropagation();
      event.preventDefault();
    }

    var onFileDrop = (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (event.target.className.indexOf("droptarget")!== -1) {
        event.target.style.background = "transparent";
      }

      if(event.dataTransfer.files.length === 1) {
        file = event.dataTransfer.files[0]
        fileExtension = file.name.split('.').pop()

        renderDialog()
      }
    }

    var div = document.createElement('div');
    var renderDialog = () => {
      ReactDOM.render(
        <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: <div>{i18n.t('users:importUsers')}</div>
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "add-group-dialog"
            }}
            onDismiss={() => {showDialog = false; renderDialog()}}
          >

          <div className="dialog-content-wrap">
            {!file ? <div className={"file-droptarget droptarget"} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDragOver={onDragOver} onDrop={(e) => onFileDrop(e)}>
              <p>{i18n.t('users:dragDrop')}<br />
              <span className="text-uppercase d-inline-block my-2">- {i18n.t('users:or')} -</span><br />
              <PrimaryButton onClick={ _showFilePicker }>
                {i18n.t('users:selectFile')}
              </PrimaryButton>
              </p>
            </div>
            :
            <div className={"file-droptarget pointer-events-enabled addedFile"}>
              <p><Icon {...getFileTypeIconProps({ extension: fileExtension, size: 24, imageFileType: 'svg' }) } /> { file.name } <br />
              <span onClick={ () => {file = null; renderDialog()}} className="remove">{i18n.t('users:remove')}</span>
              </p>
            </div>}
            <DialogFooter className="mt-4">
              <DefaultButton onClick={() => {showDialog = false; renderDialog()}} text={i18n.t('users:cancel')}  />
              <PrimaryButton onClick={() => importUsers()} text={i18n.t('users:next')} disabled={!file}/>
            </DialogFooter>
          </div>

          </Dialog>
        , div
      )
    }

    renderDialog();

  }

  private _addMultipleUsers = () => {
    var repo = this.props.userData.repository;
    var showDialog = true;
    var userId: number = 0;
    var allUsers: any[] = [ {id:userId, email:"", name:"", surname:"", repositoryRole:"INTERNAL"} ]

    const options: IChoiceGroupOption[] = [
      { key: 'INTERNAL', text: i18n.t('users:internal') },
      { key: 'ADMIN', text: i18n.t('users:admin') }
    ];

    var createUser = () => {
      for (var i=0; i< allUsers.length; i++) {
        for (var key in allUsers[i]) {
          var user = allUsers[i]
          if (!user[key].length && key !== "id") {
            errorwarning = true;
            renderDialog();
            return;
          }
        }
      }
      userService.createMultipleRepoUsers(allUsers, repo.id)
        .then((response: any) => {
          showDialog = false;
          allUsers = [ { id:0, email:"", name:"", surname:"", repositoryRole:"INTERNAL" } ]
          renderDialog();
          this._getUsers(repo.id)
        }).catch(error => {
          console.log(error)
          showDialog = false;
          allUsers = [ { id:0, email:"", name:"", surname:"", repositoryRole:"INTERNAL" } ]
          renderDialog();
      }) 
    }

    var handleEmailChange = (ev: any, newValue?: string) => {
      var userArray = allUsers;
      for (var i=0; i < userArray.length; i++) {
        if ("email"+userArray[i].id === ev.target.id) {
          userArray[i].email = newValue || "";
          allUsers = userArray
        }
      }
      renderDialog()
    }

    var handleNameChange = (ev: any, newValue?: string) => {
      var userArray = allUsers;
      for (var i=0; i < userArray.length; i++) {
        if ("name"+userArray[i].id === ev.target.id) {
          userArray[i].name = newValue || "";
          allUsers = userArray
        }
      }
      renderDialog()
    }

    var handleSurnameChange = (ev: any, newValue?: string) => {
      var userArray = allUsers;
      for (var i=0; i < userArray.length; i++) {
        if ("surname"+userArray[i].id === ev.target.id) {
          userArray[i].surname = newValue || "";
          allUsers = userArray
        }
      }
      renderDialog()
    }

    var handleUserTypeChange = (ev: any, option: any ) => {
      var userArray = allUsers;
      var parentId = ev.target.parentNode.parentNode.parentNode.parentNode.parentNode.id
      for (var i=0; i < userArray.length; i++) {
        if ("role"+userArray[i].id === parentId) {
          userArray[i].repositoryRole =  option.key || "";
          allUsers = userArray
        }
      }
      renderDialog()
    }

    var addUserForm = () => {
      userId ++;
      const userArray = allUsers;
      userArray.push({id:userId, email:"", name:"", surname:"", repositoryRole:"INTERNAL"})
      allUsers = userArray
      renderDialog();

    }

    var removeUserForm = (id: any) => {
      var userArray:any[] = [];
      const users = allUsers;
      for (var i=0; i < users.length; i++) {
        if (users[i].id !== id) {
          userArray.push(users[i]);
        }
      }
      allUsers = userArray
      renderDialog();
    }

    var errorwarning = false;

    const ErrorWarning = () => (
      <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline={false}
        dismissButtonAriaLabel="Close"
        onDismiss={() => {errorwarning=false; renderDialog()}}
      >
        Please fill every field before submitting.
      </MessageBar>
    );

    var div = document.createElement('div');
    var renderDialog = () => {

      ReactDOM.render(
        <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "add-group-dialog"
            }}
            onDismiss={() => {allUsers = [ { id:0, email:"", name:"", surname:"", repositoryRole:"INTERNAL" } ];showDialog = false; renderDialog();}}
            
          >

          <div className="dialog-content-wrap">
            <h1>{i18n.t('users:createMultipleUsers')}</h1>
            {allUsers.map((u) => {
              return(
                <div className="row" key={u.id} style={{position:"relative"}}>
                  {u.id!==0 && <IconButton styles={iconStyles} id={"remove"+u.id} onClick={() => removeUserForm(u.id)} iconProps={{iconName: "RemoveFilter"}} style={{position:"absolute", left: "93%", top:"-10px", width:"32px", height: "32px", fontSize:"20px"}} title={i18n.t('users:remove')} ariaLabel="Remove" />}
                  <div className="col-8">
                    <TextField id={"email"+u.id} className="mt-3" label={i18n.t('users:email')} underlined autoFocus placeholder={i18n.t('users:email')}  name="newItemName" value={u.email} onChange={ handleEmailChange } required />
                    <TextField id={"name"+u.id} className="mt-3" label={i18n.t('users:name')} underlined placeholder={i18n.t('users:name')}  name="newItemName" value={ u.name  } onChange={ handleNameChange } required />
                    <TextField id={"surname"+u.id} className="mt-3" label={i18n.t('users:surname')} underlined placeholder={i18n.t('users:surname')} name="newItemName" value={ u.surname  } onChange={ handleSurnameChange } required />
                  </div>
                  <div className="col-4">
                  <ChoiceGroup id={"role"+u.id} className="mt-3" defaultSelectedKey="INTERNAL" options={options} onChange={handleUserTypeChange} label={i18n.t('users:pickUserType')} required />
                  </div>
                  <hr className="mt-5"></hr>
                </div>
                
              )
            })}
            <DefaultButton onClick={addUserForm} text={i18n.t('users:addUser')} iconProps={{ iconName: 'Add' }}/>
            <DialogFooter className="mt-4">
              <DefaultButton onClick={() => { allUsers = [ { id:0, email:"", name:"", surname:"", repositoryRole:"INTERNAL" } ];showDialog = false; renderDialog()}} text={i18n.t('users:cancel')} />
                <PrimaryButton onClick={() => {createUser()}} text={i18n.t('users:save')}  />
            </DialogFooter>
            
            {errorwarning && <div className="mt-4"><ErrorWarning /></div>}
      
          </div>

          </Dialog>
        , div
      )
    }

    renderDialog();

  }

  private _editUser = () => {
    var selUser: any = this._selection.getSelection()[0]
    var repo = this.props.userData.repository;
    var showDialog = true;
    var name: string = selUser.name;
    var surname: string = selUser.surname;
    var email: string = selUser.email;
    var id :string = selUser.key;
    console.log(id)

    var updateUser = () => {
      userService.editRepoUser(id, name, surname)
        .then((response: any) => {
          showDialog = false;
          renderDialog();
          this._getUsers(repo.id)
        }).catch(error => {
          console.log(error)
          showDialog = false;
          renderDialog();
        })
    }

    var handleNameChange = (ev: any, newValue?: string) => {
      name = newValue || ""
      renderDialog()
    }

    var handleSurnameChange = (ev: any, newValue?: string) => {
      surname = newValue || ""
      renderDialog()
    }

    var div = document.createElement('div');
    var renderDialog = () => {

      ReactDOM.render(
        <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: <div>{i18n.t('users:editUser')}</div>
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "add-group-dialog"
            }}
            onDismiss={() => {showDialog = false; renderDialog()}}
          >

          <div className="dialog-content-wrap">
            
              <TextField label={i18n.t('users:email')} underlined placeholder={i18n.t('users:email')} name="email" value={ email } disabled />
              <TextField label={i18n.t('users:name')} className="mt-3" underlined autoFocus placeholder={i18n.t('users:name')} name="name" value={ name } onChange={ handleNameChange } required />
              <TextField label={i18n.t('users:surname')} className="mt-3" underlined autoFocus placeholder={i18n.t('users:surname')} name="surname" value={ surname } onChange={ handleSurnameChange } required />

              <DialogFooter className="mt-4">
                <DefaultButton onClick={() => {showDialog = false; renderDialog()}} text={i18n.t('users:cancel')} />
                  <PrimaryButton onClick={() => updateUser()} text={i18n.t('users:save')} disabled={!name.length || !surname.length} />
              </DialogFooter>
      
          </div>

          </Dialog>
        , div
      )
    }

    renderDialog();

  }

  private _editImportedUser = () => {
    var selUser: any = this._selection.getSelection()[0]
    var showDialog = true;
    var name: string = selUser.name;
    var surname: string = selUser.surname;
    var email: string = selUser.email;

    var updateUser = () => {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      selUser.name = name;
      selUser.surname = surname;
      selUser.email = email;
      if (email.match(validRegex)) selUser.state = 'notCreated'
      else selUser.state = 'invalidEmail'
      let users = this.state.items;
      for(let i in users) {
        if (users[i].key === selUser.key) {
          users[i] = selUser;
        }
        this._isMounted && this.setState({items: users.slice(0)})
      }
      showDialog = false;
      renderDialog();
    }

    var handleEmailChange = (ev: any, newValue?: string) => {
      email = newValue || ""
      renderDialog()
    }

    var handleNameChange = (ev: any, newValue?: string) => {
      name = newValue || ""
      renderDialog()
    }

    var handleSurnameChange = (ev: any, newValue?: string) => {
      surname = newValue || ""
      renderDialog()
    }

    var div = document.createElement('div');
    var renderDialog = () => {

      ReactDOM.render(
        <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: <div>{i18n.t('users:editUser')}</div>
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "add-group-dialog"
            }}
            onDismiss={() => {showDialog = false; renderDialog()}}
          >

          <div className="dialog-content-wrap">
            
              <TextField label={i18n.t('users:email')} underlined placeholder={i18n.t('users:email')} name="email" value={ email } onChange={ handleEmailChange } autoFocus />
              <TextField label={i18n.t('users:name')} className="mt-3" underlined placeholder={i18n.t('users:name')} name="name" value={ name } onChange={ handleNameChange } required />
              <TextField label={i18n.t('users:surname')} className="mt-3" underlined placeholder={i18n.t('users:surname')} name="surname" value={ surname } onChange={ handleSurnameChange } required />

              <DialogFooter className="mt-4">
                <DefaultButton onClick={() => {showDialog = false; renderDialog()}} text={i18n.t('users:cancel')} />
                  <PrimaryButton onClick={() => updateUser()} text={i18n.t('users:save')} disabled={!name.length || !surname.length} />
              </DialogFooter>
      
          </div>

          </Dialog>
        , div
      )
    }

    renderDialog();

  }

  private _deleteUser = () => {
    var showDialog = true;
    var repo = this.props.userData.repository;
    var selUser: any = this._selection.getSelection()[0];

    var div = document.createElement('div');
    var renderDialog = () => {

      ReactDOM.render(
        <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Disabling user"
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
            }}
            onDismiss={() => {showDialog = false; renderDialog()}}
          >

            <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
            <span>Please, wait...</span>
      
          </Dialog>
        , div
      )
    }

    renderDialog();

    userService.deleteRepoUser(repo.id, selUser.key).then(response => {
      showDialog = false
      renderDialog()
      this._getUsers(repo.id)
    }).catch(error => {
      console.log(error)
      showDialog = false
      renderDialog()
      alert(error)
    })
  }

  private _copyEmails = () => {
    let filteredUsers = this.state.items.filter((user)=>{
      return user.state === 'ACTIVE' && (user.role === 'INTERNAL' || user.role === 'ADMIN')
    })
    let links = document.createElement("span");
    document.body.appendChild(links);
    for (let i in filteredUsers) {
      let user:any = filteredUsers[i]
      let anchorElem = document.createElement("span");
      anchorElem.innerText = (user.name ?? '') + ' ' + (user.surname ?? '') +  '<' + user.email + '>; ';
      anchorElem.style.fontFamily = '';
      anchorElem.style.fontSize = 'inherit';
      links.appendChild(anchorElem);
    }
    const linkElement = links;
    const range = document.createRange();
    range.selectNode(linkElement);
    const selection:any = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('copy')
    document.body.removeChild(links);
  }

  private _resendActivationEmail(item: any) {
    var showDialog = true;
    var isLoading = true;
    var repo = this.props.userData.repository;
    var div = document.createElement('div');

    var renderDialog = () => {
      ReactDOM.render(
        <Dialog
          hidden={!showDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: isLoading ? "Sending email" : "Activation link successfully sent to:"
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640, minWidth: 400 } },
            dragOptions: undefined
          }}
          onDismiss={() => {showDialog = false; renderDialog();}}
        >

          { isLoading ?
            <>
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
              <span>Please, wait...</span>
            </>
          :
            <ul>
              <li>{"Name: " + item.name}</li>
              <li>email: <a href={"mailto:" + item.email}>{item.email}</a></li>
            </ul>
          }
        </Dialog>
      , div)
    }

    renderDialog()

    userService.resendActivationEmail(item.key).then(response => {
      isLoading = false
      renderDialog()
      this._getUsers(repo.id)
    }).catch(error => {
      console.log(error)
      isLoading = false
      showDialog = false
      renderDialog()
      alert(error.data.error)
    })
  }

  private _restoreUser(item: any) {    
    var showDialog = true;
    var isLoading = true;
    var repo = this.props.userData.repository;
    var div = document.createElement('div');

    var renderDialog = () => {
      ReactDOM.render(
        <Dialog
          hidden={!showDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: isLoading ? "Restoring user" : "User successfully restored:"
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640, minWidth: 400 } },
            dragOptions: undefined
          }}
          onDismiss={() => {showDialog = false; renderDialog();}}
        >

          { isLoading ?
            <>
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
              <span>Please, wait...</span>
            </>
          :
            <ul>
              <li>{"Name: " + item.name}</li>
              <li>email: <a href={"mailto:" + item.email}>{item.email}</a></li>
            </ul>
          }
        </Dialog>
      , div)
    }

    renderDialog()

    userService.restoreRepoUser(repo.id, item.key).then(response => {
      isLoading = false
      renderDialog()
      this._getUsers(repo.id)
    }).catch(error => {
      console.log(error)
      isLoading = false
      showDialog = false
      renderDialog()
      alert(error)
    })
  }

  private _permanentlyDeleteUser(item: any) {    
    var showDialog = true;
    var isLoading = true;
    var repo = this.props.userData.repository;
    var div = document.createElement('div');

    var renderDialog = () => {
      ReactDOM.render(
        <Dialog
          hidden={!showDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: isLoading ? "Permanently deleting user" : "User permanently deleted:"
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640, minWidth: 400 } },
            dragOptions: undefined
          }}
          onDismiss={() => {showDialog = false; renderDialog();}}
        >

          { isLoading ?
            <>
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block mr-2" />
              <span>Please, wait...</span>
            </>
          :
            <ul>
              <li>{"Name: " + item.name}</li>
              <li>email: <a href={"mailto:" + item.email}>{item.email}</a></li>
            </ul>
          }
        </Dialog>
      , div)
    }
    
    renderDialog()

    userService.deleteRepoUser(repo.id, item.key).then(response => {
      isLoading = false
      renderDialog()
      this._getUsers(repo.id)
    }).catch(error => {
      console.log(error)
      isLoading = false
      showDialog = false
      renderDialog()
      alert(error)
    })
  }

}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  return items.slice(0).sort((a, b) => {
    if (!isSortedDescending) {
      return (a[columnKey] || '').localeCompare(b[columnKey] || '');
    } else {
      return (b[columnKey] || '').localeCompare(a[columnKey] || '');
    }
  });
}