import { ActionButton, ConstrainMode, DefaultButton, DetailsListLayoutMode, Dialog, DialogFooter, DialogType, Icon, IconButton, IDetailsHeaderProps, IRenderFunction, Persona, PersonaSize, PrimaryButton, ScrollablePane, SearchBox, SelectionMode, ShimmeredDetailsList, Spinner, SpinnerSize, TextField, TooltipHost, } from '@fluentui/react';
import * as React from 'react';
import i18n from '../i18n';
import { userService } from '../_services';

export interface Props {
  callback:any;
}

export interface States {
  hidden: boolean;
  loading: boolean;
  loadingEntities: boolean;
  entityType: string;
  filter:string;
  sortedAZ: boolean;
  loadingPossibleUsers: boolean;
  possibleUsers: any;
  currentUsers: any;
  filteredUsers: any;
  name: string;
  description: string;
}

export class FormCreateGroups extends React.Component<Props, States> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this.state = {
      hidden: false,
      loading: false,
      loadingEntities: false,
      entityType: 'guest',
      filter: '',
      sortedAZ: true,
      loadingPossibleUsers: true,
      possibleUsers: [],
      currentUsers: props.currentUsers,
      filteredUsers: [],
      name: props.name,
      description: props.description,
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this.setState({loadingEntities: true})
    this.getRepoUsers();
  }
  
  public componentDidUpdate(prevProps, prevState) {
    if (this.state.hidden && this.state.hidden !== prevState.hidden) {
      this.props.callback()
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  private async _add() {
    this._isMounted && this.setState({loading: true})
    const {currentUsers} = this.state;

    if (currentUsers.length > 0) {
      let userIds:string[] = []
      for (let i in currentUsers) {
        userIds.push(currentUsers[i].id)
      }
      if (userIds.length > 0) {
        userService.createGroup(this.state.name, this.state.description, userIds, this.props.userType)
        .then((response: any) => {
          this._isMounted && this.setState({hidden:true});
        }).catch(error => {
          console.log(error)
          this._isMounted && this.setState({hidden:true});
        })
      }
    }
  }

  private async _edit() {
    this._isMounted && this.setState({loading: true})
    const {currentUsers} = this.state;

    if (currentUsers.length > 0) {
      let userIds:string[] = []
      for (let i in currentUsers) {
        userIds.push(currentUsers[i].id)
      }
      if (userIds.length > 0) {
        userService.editGroup(this.props.id, this.state.name, this.state.description, userIds)
        .then((response: any) => {
          this._isMounted && this.setState({hidden:true});
        }).catch(error => {
          console.log(error)
          this._isMounted && this.setState({hidden:true});
        })
      }
    }
  }

  private getRepoUsers() {
    userService.getRepoUsers(this.props.userType).then((response: any) => {
      if(response.data && response.data.length > 0) {
        let data = response.data.map(user => {
          return({
            id: user.id,
            key: user.id,
            text: user.name + " "+ user.surname + ", " + user.email,
            name: user.name + " " + (user.surname ? user.surname : ''),
            email: user.email,
            type: "user",
            role: user.role
          })
        });

        let possibleUsers = data;


        possibleUsers.sort((a,b) => {
          if(a.text.toLowerCase() < b.text.toLowerCase())
              return -1;
          if(b.text.toLowerCase() < a.text.toLowerCase())
              return 1;
  
          return 0;
        });

        this._isMounted && this.setState({loadingPossibleUsers: false, possibleUsers: possibleUsers, filteredUsers: possibleUsers,})
      } else {
        this._isMounted && this.setState({loadingPossibleUsers: false})
      }
    }).catch(error => {
      console.log(error)
      this._isMounted && this.setState({loadingPossibleUsers: false})
    })
  }

  private sortItems(items, asc:boolean) {
    if (asc) {
      return items.slice(0).sort((a: any, b: any) => {
        if (a.name < b.name || (a.firstName + ' ' + a.lastName) < (b.firstName + ' ' + b.lastName)) return -1;
        if (a.name > b.name || (a.firstName + ' ' + a.lastName) > (b.firstName + ' ' + b.lastName)) return 1;
        else return 0;
      })
    } else {
      return items.slice(0).sort((a: any, b: any) => {
        if (a.name < b.name || (a.firstName + ' ' + a.lastName) < (b.firstName + ' ' + b.lastName)) return 1;
        if (a.name > b.name || (a.firstName + ' ' + a.lastName) > (b.firstName + ' ' + b.lastName)) return -1;
        else return 0;
      })
    }

  }

  private addTo(item: any, type: string) {
    if(!this.isSelected(item,type)) {
      this.setState({currentUsers: this.sortItems([...this.state.currentUsers, item], this.state.sortedAZ)})
    }
  }

  private removeFrom(item: any, type: string) {
    this.setState({currentUsers: this.state.currentUsers.filter((i)=>{return i.id !== item.id})})
  }

  private isSelected(item: any, type: string) {
    return this.state.currentUsers.filter((i)=>{return i.id === item.id}).length > 0
  }

  private filter(filter:string) {
    let value = filter.toLowerCase()
    if (value && value.length > 0) {
      this._isMounted && this.setState({
        filteredUsers: this.state.possibleUsers.filter((item)=>{return (item.name || '').toLowerCase().includes(value)}),
        filter: value
      })
    } else {
      this._isMounted && this.setState({filteredUsers: this.state.possibleUsers, filter: value})
    }
  }

  private toggleSort() {
    this._isMounted && this.setState({
      sortedAZ: !this.state.sortedAZ,
      currentUsers: this.sortItems(this.state.currentUsers, !this.state.sortedAZ),
    })
  }

  public render() {

    const { loading } = this.state;

    const columnsUsers:any[] = [{
      key: 'name',
      name: <div className='ms-1'>{i18n.t('groups:users') + ' (' + this.state.filteredUsers.length + ')'}</div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-2 align-items-center" style={{userSelect:'none'}} onClick={()=>{this.addTo(item,this.state.entityType)}}>
            <Persona
              key={ item.id }
              size={PersonaSize.size24}
              className="persona me-2"
              text={item.name}
              hidePersonaDetails
            />
            <div style={{maxWidth: 'calc(100% - 80px)'}}>
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)', overflowX: 'hidden', textOverflow: 'ellipsis'}}>{item.name}</p>
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)', overflowX: 'hidden', textOverflow: 'ellipsis'}}><a target='_blank' rel="noreferrer noopener" href={'mailto:'+item.email}>{item.email}</a></p>
            </div>
            { !this.isSelected(item,item.key.split('-')[0]) ? 
             <TooltipHost
              content={i18n.t('users:add')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
              >
              <IconButton style={{color: 'rgb(0, 120, 212)'}} className='ms-auto' iconProps={{iconName: 'Add'}} />
            </TooltipHost>
            :
            <IconButton disabled style={{opacity: 1, background: 'transparent', color: 'green', width:'30px', fontSize:18, textAlign: 'center', verticalAlign: 'middle'}} className='ms-auto icon-disabled' iconProps={{iconName:'CheckMark'}} />
          }
          </div>
        </>)
      }
    }]

    const columnsCurrentUsers:any[] = [{
      key: 'name',
      name: <div className='ms-1'>{i18n.t('groups:users') + ' (' + this.state.currentUsers.length + ')'}</div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex py-1 align-items-center h-100" style={{userSelect:'none'}}>
            <Persona
              key={ item.id }
              size={PersonaSize.size24}
              className="persona me-2"
              text={item.name}
              hidePersonaDetails
            />
            <div style={{maxWidth: 'calc(100% - 100px)'}}>
              <p className='p-0 m-0' style={{fontSize: 14, fontWeight: 400, color: 'rgb(50, 49, 48)', overflowX: 'hidden', textOverflow: 'ellipsis'}}>{item.name}</p>
              <p className='p-0 m-0' style={{color:'rgb(96, 94, 92)', overflowX: 'hidden', textOverflow: 'ellipsis'}}><a target='_blank' rel="noreferrer noopener" href={'mailto:'+item.email}>{item.email}</a></p>
            </div>
            <TooltipHost
              content={i18n.t('users:remove')}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
            >
              <IconButton className='ms-auto' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,'restrictedEditorG')}}/>
            </TooltipHost>
          </div>
        </>)
      }
    }]
    
    const onRenderDetailsHeaderRestrictedEditorsG = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center'>
          {defaultRender!({
            ...props
          })}
        </div>
      );
    }

    return(
      <div>
        <Dialog
          hidden={!this.props.showDialog}
          dialogContentProps={{
            type: DialogType.close,
            title: <div className='d-flex'><Icon className='me-3' style={{fontSize:'28px', fontWeight: 500}} iconName='Group'/>{this.props.action === 'add' ? this.props.userType === 'INTERNAL' ? i18n.t('groups:addGroup') : i18n.t('groups:addGuestGroup') : i18n.t('groups:editGroup')}</div>,
            onDismiss: ()=> {this._isMounted && this.setState({hidden:true})},
            styles: {innerContent: {overflowY: 'none', background: '#f2f2f2'}}
          }}
          modalProps={{
            isBlocking: false,
            styles: { main: { maxWidth: 640 } },
            dragOptions: undefined,
            className: "form-dialog",
            onDismiss: ()=> {this._isMounted && this.setState({hidden:true})}
          }}
        >
          <div className='' style={{borderBottom: '1px solid #bfbfbf', position: 'sticky', top: 0, zIndex: 1}}></div>
          <div className='w-100 share-form' style={{display: 'flex', height: '100%'}}>
            <div className='w-50'>
              <div className='d-flex align-items-center py-4 px-4 pe-1' style={{height: '32px', borderBottom: '1px solid #bfbfbf'}}>
                <span style={{fontWeight: 600}}>{i18n.t('groups:groupUsers')}</span>
                <TooltipHost
                  key={'sort'}
                  content={i18n.t('groups:switchOrder')}
                  id={'sort'}
                  calloutProps={{ gapSpace: 0 }}
                  styles={{root: {marginLeft: 'auto'}}}
                >
                  <ActionButton
                    onClick={()=>this.toggleSort()} 
                    className='expand-icon'
                    text={i18n.t('groups:ByName')}
                    iconProps={{iconName: this.state.sortedAZ ? 'SortDown' : 'SortUp'}}
                    style={{height: '32px'}} 
                  />
                </TooltipHost>
              </div>
              <div className='d-flex mt-4 mx-4'>
                <TextField 
                  className='flex-grow-1 ' 
                  placeholder={i18n.t('groups:name')}
                  value={this.state.name} 
                  onChange={(e,v)=>{this.setState({name:v || ''})}}
                  styles={{fieldGroup: {border: '1px solid #bfbfbf'}}}
                  required
                />
              </div>
              <div className='d-flex mx-4' style={{marginTop: '24px'}}>
                <TextField 
                  className='flex-grow-1 ' 
                  placeholder={i18n.t('groups:description')}
                  value={this.state.description} 
                  onChange={(e,v)=>{this.setState({description:v || ''})}}
                  styles={{fieldGroup: {border: '1px solid #bfbfbf'}}}
                  multiline
                  resizable={false}
                />
              </div>

              <ScrollablePane className='relation-list w-50' style={{top: '190px'}}>
                <ShimmeredDetailsList
                  className={'mt-4 height-fix'}
                  items={this.state.currentUsers}
                  columns={columnsCurrentUsers}
                  onRenderDetailsHeader={onRenderDetailsHeaderRestrictedEditorsG}
                  selectionMode={SelectionMode.none}
                  selectionPreservedOnEmptyClick={true}
                  setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  ariaLabelForShimmer="Content is being fetched"
                  enterModalSelectionOnTouch={true}
                  shimmerLines={12}
                  constrainMode={ConstrainMode.unconstrained}
                />
              </ScrollablePane>
            </div>
            <div className='w-50' style={{borderLeft: '1px solid #bfbfbf'}}>
              <div className='d-flex align-items-center ps-4' style={{borderBottom: '1px solid #bfbfbf', height: '49px'}}>
                <span style={{fontWeight: 600,}}>{i18n.t('groups:users')}</span>
              </div>
              <div className='d-flex mt-4 mx-4'>
                <SearchBox 
                  iconProps={{iconName:'Filter'}} 
                  className='flex-grow-1 ' 
                  placeholder={i18n.t('groups:filter') +" "+ i18n.t('groups:byName')}
                  value={this.state.filter} 
                  onChange={(e,v)=>{this.filter(v || '')}}
                  styles={{root: {border: '1px solid #bfbfbf'}}}
                />
              </div>
              <ScrollablePane className='relation-list w-50' style={{left:'50%', top: '130px'}}>
              {!this.state.loadingPossibleUsers ? <>
              <ShimmeredDetailsList
                className={'height-fix'}
                items={ this.state.filteredUsers}
                columns={columnsUsers}
                selectionMode={SelectionMode.none}
                selectionPreservedOnEmptyClick={true}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                ariaLabelForShimmer="Content is being fetched"
                enterModalSelectionOnTouch={true}
                shimmerLines={12}
                constrainMode={ConstrainMode.unconstrained}
              />
              </>
              : <Spinner className='mt-2'/>
              }
              </ScrollablePane>
            </div>
          </div>
          <DialogFooter>
            { loading ?
              <Spinner size={SpinnerSize.xSmall} className="d-inline-block align-baseline" />
            : null }
            <DefaultButton onClick={()=> {this._isMounted && this.setState({hidden:true})}} text={i18n.t('groups:cancel')} disabled={loading} />
            <PrimaryButton onClick={()=>{this.props.action === 'add' ? this._add() : this._edit()}} text={i18n.t('groups:save')} disabled={loading || this.state.name.length === 0 || this.state.currentUsers.length === 0} />                   
          </DialogFooter>
        </Dialog>
      </div>
    )
  }
}

