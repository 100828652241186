import { Selection, IconButton, Icon, Spinner, PrimaryButton, TooltipHost, DirectionalHint } from '@fluentui/react';
import * as React from 'react';
import i18n from 'i18next';
import Tree from 'react-virtualized-tree';
import {createSelector} from 'reselect';
import { userService } from '../../_services/user.service';
import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';

export const getNodeRenderOptions = createSelector(
  (node: any) => (node.state || {}).expanded,
  node => (node.state || {}).favorite,
  node => (node.state || {}).deletable,
  node => node.hasSubFolders,
  node => node.id,
  node => node.type,
  node => node.hidden,
  (expanded, favorite, deletable, hasSubFolders, id, type, hidden = []) => ({
    hasChildren: !!hasSubFolders,
    isExpanded: !!expanded,
    isFavorite: !!favorite,
    isDeletable: !!deletable,
    id: id,
    type: type,
    hidden: !!hidden
  }),
);

export const updateNode = (originalNode, newState: any) =>
  ({
  
  node: {
    ...originalNode,
    state: {
      ...originalNode.state,
      ...newState,
    },
  },
  type: "2" //UPDATE
});

export interface Props {
  type: string;
  items: any;
  save:any;
  callbackFunction: any;
  nodesTree: any[]
}

export interface States {
  panelWidth: any;
  panelHeight: any;
  items: any;
  itemsFull: any;
  itemsChecked: any;
  items2: any;
  folderTree: any;
  filters: any;
  currentFolder: any;
  currentTags: any;
  currentColorTags: any;
  currentFileTypes: any;
  nodesTree: any;
  searchFilters: any;
  loading: boolean;
  loadingMore: boolean;
  canLoadMore: boolean;
}

export class FilePickerSidebarExtra extends React.Component<Props, States> {
  private _isMounted: boolean;
  private _selection: Selection;
  private _selection2: Selection;
  private filteredNested: any;
  private tagColors:any;
  private currentPage:number;
  
  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.currentPage = 1;
    this.props = props;

    this._selection = new Selection({
      onSelectionChanged: () => {
        if (this._selection.getSelection().length > 0 ) {
          this._selection2.setAllSelected(false);
        } else if (this._selection.getSelection().length === 0 && this._selection2.getSelection().length === 0) {
          this._selection2.setIndexSelected(0,true,false);
        }
      }
    });

    this._selection2 = new Selection({
      onSelectionChanged: () => {
        setTimeout(()=>{
          if (this._selection2.getSelection().length === 1 ) {
            this._selection.setAllSelected(false);
          }
          else if (this._selection.getSelection().length === 0 && this._selection2.getSelection().length === 0) {
            this._selection2.setIndexSelected(0,true,false);
          }
        },1)
      }
    });

    var panelWidth = "290px"

    this.state = {
      panelHeight: 'auto',
      panelWidth: panelWidth,
      items: this.props.items,
      itemsFull: null,
      itemsChecked: [],
      items2: [
        { key: 'noFilter', text: 'No filter' },
        { key: 'all', text: 'All' },
        { key: 'none', text: 'None'}
      ],
      folderTree: [],
      filters: [],
      currentFolder: [],
      currentTags: [],
      currentColorTags: [],
      currentFileTypes: [],
      nodesTree: [],
      searchFilters: this.props.folderTree,
      loading: true,
      loadingMore: false,
      canLoadMore: false
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    var params = new URLSearchParams(document.location.search);
    let field:string = params.get(this.props.type.toLowerCase()) || "";
    let fields:string[] = field.split(';');
    if (field === 'all') {
      this._selection2.setAllSelected(false);this._selection2.setIndexSelected(1, true,false)
    } else if (field === 'none') {
      this._selection2.setAllSelected(false);this._selection2.setIndexSelected(2, true,false)
    } else if (fields && fields.length > 0 && field.length > 0) {
      setTimeout(()=>{
        let items:any = this._selection.getItems();
        for (let i=0; i < items.length; i++) {
          if (fields.includes(items[i].tag.replace(this.props.type+':',''))) {
            this._selection.setIndexSelected(i, true, false)
          }
        }
      },1)
    } else {
      this._selection2.setAllSelected(false);this._selection2.setIndexSelected(0, true,false)
    }
    if (this.props.type === 'folder') this._getFolderTree();
    else if (this.props.type === 'colorTag') this._getColorTags();
    else if (this.props.type === 'fileTypes') this._getFileTypes();
    else if (this.props.type === 'searchFolders') this._searchFolders(this.props.searchFilter);
  }
  
  public componentDidUpdate(prevProps:any) {
    if (prevProps.type !== this.props.type || prevProps.searchFilter !== this.props.searchFilter) {
      if (this.props.type === 'folder') this._getFolderTree();
      else if (this.props.type === 'colorTag') this._getColorTags();
      else if (this.props.type === 'fileTypes') this._getFileTypes();
      else if (this.props.type === 'searchFolders') this._searchFolders(this.props.searchFilter);
      this.currentPage = 1;
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  _selectionToFilters = () => {
    const selection:any = this._selection.getSelection();
    let filters:any = [];
    for (let i=0; i<selection.length; i++) {
      filters.push({
          key: 'tag' + i,
          id: 'tag' + i,
          name: selection[i].tag.replace(this.props.type+':',''),
          type: 'tag' + this.props.type,
          category: this.props.type,
          iconName: 'Documentation'
      })
    }
    return filters;
  }

  private getColorTag(key:string = '0') {
    let color = this.tagColors.colors.find((item)=>{
      return item.id === key.toString();
    })
    return key.toString() === '0' ? 'transparent' : color ? color.color : 'transparent'
  }

  private _onRenderLink = (link: any): JSX.Element | null => {
    return (
      link.type === "repo" ?
        <div ref={() => link.id} id={link.id}>
          <Icon   iconName="OfflineStorage" className="repoIcon child-elements" />
          <span className="child-elements">{ link.name }</span>
        </div>
      : link.type === "repoFilter" ?
        <div className={"folderLink dir"}>
          <Icon   iconName="OfflineStorage" className="repoIcon" />
          <span>{ link.name }</span>
        </div>
      : link.type === "folder" ?
        <div ref={() => link.id} id={link.id} className={"folderLink dir"}>
          <div className="icon-wrap">
            <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } className="child-elements" />
            { link.isFolderInPath ?
              <Icon iconName="ChevronRightSmall" className="folder-in-path" />
            : null }
          </div>
          <span className="child-elements">{ link.name}</span>
        </div>
      : link.type === "folder-search" ?
        <div ref={() => link.id} id={link.id} className={"d-flex align-items-center folderLink dir"} style={{maxWidth: '100%', paddingRight: '6px', height: '32px'}}>
          <TooltipHost
            content={<div className='m-1' style={{width: '300px'}}>
              <p className='m-0 pb-1' style={{fontWeight: 600, fontSize: 12}}>{i18n.t('app:path')}</p>
              <span style={{fontSize: 12}}>{link.pathNames}</span>/<span style={{fontWeight: 600, fontSize: 12}}>{link.name}</span>
            </div>}
            calloutProps={{ gapSpace: 0, directionalHint: DirectionalHint.rightCenter }}
            styles={{root: {display: 'flex', alignItems: 'center', width: '100%'}}}
          >
            <div className="icon-wrap">
              <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } className="child-elements" />
              { link.isFolderInPath ?
                <Icon iconName="ChevronRightSmall" className="folder-in-path" />
              : null }
            </div>
            <span style={{flexGrow: 1, maxWidth: 'calc(100% - 70px)', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}} className="child-elements">{ link.name}</span>
            <IconButton onClick={(e)=>{e.preventDefault();this._goToFolderAndClose(e,link)}} className='folder-search' iconProps={{iconName: 'BulletedTreeList'}}/>
          </TooltipHost>
        </div>
        : link.type === "tag"?
        <div className={"folderLink d-flex align-items-center"}>
          <div style={{backgroundColor: link.color, width: '16px', height: '16px', borderRadius: '10px'}} className="tag mr-2" />
          <span >{ link.name }</span>
        </div>
        : link.type === "filterAllTags" ?
        <div className="folderLink tagCategory" >
          <Icon iconName="BulletedList" className="tag" />
          <span>{ link.name }</span>
        </div>
        : link.type === "filterStandardTags" || link.type === "tagCategory" ?
        <div className="folderLink tagCategory">
          <Icon iconName="Tag" className="tag" />
          <span>{ link.name }</span>
        </div>
        : link.type === "filterColors" ?
        <div className={"folderLink d-flex align-items-center"}>
          <div style={{backgroundColor: this.getColorTag(link.search), width: '16px', height: '16px', borderRadius: '10px'}} className="tag mr-2" />
          <span >{ link.name }</span>
        </div>
        :link.type === "filterPdf" ?
        <div className={"folderLink "}>
          <Icon {...getFileTypeIconProps({ extension: 'pdf', size: 20, imageFileType: 'svg' }) } />
          <span>{ link.name }</span>
        </div>
        :link.type === "filterWord" ?
        <div className={"folderLink "}>
          <Icon {...getFileTypeIconProps({ extension: 'docx', size: 20, imageFileType: 'svg' }) } />
          <span>{ link.name }</span>
        </div>
        :link.type === "filterExcel" ?
        <div className={"folderLink "}>
          <Icon {...getFileTypeIconProps({ extension: 'xlsx', size: 20, imageFileType: 'svg' }) } />
          <span>{ link.name }</span>
        </div>
        :link.type === "filterPowerpoint" ?
        <div className={"folderLink "}>
          <Icon {...getFileTypeIconProps({ extension: 'pptx', size: 20, imageFileType: 'svg' }) } />
          <span>{ link.name }</span>
        </div>
        :link.type === "filterEmail" ?
        <div className={"folderLink "}>
          <Icon {...getFileTypeIconProps({ extension: 'eml', size: 20, imageFileType: 'svg' }) } />
          <span>{ link.name }</span>
        </div>
        :
        <div className="link">
          <span>{ link.name }</span>
        </div>
    )
  }

  private _addFilter(ev: React.MouseEvent<HTMLElement> | undefined, item?: any | undefined) {
    if (item && item.type === "tag") {
      this.props.addFilterTags({key: item.id, name:item.name, search: item.id, type: item.type, color: item.color})
    } else if (item && item.type === "filterColors") {
      this.props.addFilterColorTag({key: 'color-'+item.search, name:item.name, search: item.search, id: item.search, type: item.type})
    } else if (item && item.type === "folder") {
      this.props.addFilterFolder(item)
    } else if (item && item.type === "repoFilter") {
      this.props.addFilterFolder(item)
    } else if ((item && item.type === "filterPdf")
      || (item && item.type === "filterWord")
      || (item && item.type === "filterExcel")
      || (item && item.type === "filterPowerpoint")
      || (item && item.type === "filterEmail")) {
        this.props.addFilterFileType({key: item.name, name:item.name, search: item.search, type: item.type})
    }
  };

  private handleNodesChange = nodesTree => {
    this.setState({nodesTree});
  }

  private _handleExpandLinkClick(ev?: React.MouseEvent<HTMLElement> | undefined, item?: any) {
    // Trigger only on direct click on chevron icon
    if(ev) ev.preventDefault()
      
    userService.getFolderContent(item.id).then(response => {
      var repoUsers = this.props.repoUsers;

      var links: any = response.data.folders.map(link => {
        link.key = link.id
        link.isExpanded = false
        link.parentId = link.parent_id
        link.type = "folder"
  
        link.fileName = link.name
        link.fileType = "dir"
        link.accessGranted = true

        let adminsData: any = [];
        let usersData: any = [];
        let groupsData: any = [];
        let externalsData: any = [];

        link.admins && link.admins.forEach(admin => {
            var adminData = repoUsers.filter(repoUser => {
            return repoUser.id === admin
            })[0]
            if(adminData) adminsData.push(adminData)
        })

        link.users && link.users.forEach(user => {
            var userData = repoUsers.filter(repoUser => {
            return repoUser.id === user.id
            })[0]
            if(userData) usersData.push(userData)
        })

        link.groups && link.groups.forEach(group => {
            var groupData = repoUsers.groups.filter(repoGroup => {
            return repoGroup.id === group.id
            })[0]
            if(groupData) groupsData.push(groupData)
        })

        link.externals && link.externals.forEach(user => {
            var userData = repoUsers.filter(repoUser => {
            return repoUser.id === user.id
            })[0]
            if(userData) externalsData.push(userData)
        })

        link.adminsData = adminsData
        link.usersData = usersData
        link.groupsData = groupsData
        link.externalsData = externalsData

        return link
      })

      links = links.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase())
            return -1;
        if(b.name.toLowerCase() < a.name.toLowerCase())
            return 1;

        return 0;
      });

      this._expandFolderWhileBrowsing(item.id, links)
    }).catch(error => {
      console.log(error)
    })
  }

  private _expandFolderWhileBrowsing(folderId, subFolders) {
    function searchRecursive(data, id) {
      
      let found = data.find(d => d.key === id && (d.type === "repo" || d.type === "folder" || d.type === "template"));
      if (!found) {
        let i = 0;
        while(!found && i < data.length) {
          if (data[i].children) {
            found = searchRecursive(data[i].children, id);
          }
          i++;
        }
      }

      return found;
    }

    var folder: any = searchRecursive(this.state.nodesTree, folderId);

    if(folder) {

      var links = subFolders.map(subFolder => {
        delete subFolder.icon
        subFolder.state = {expanded: false}
        subFolder.hasChildren = subFolder.hasSubFolders
        return subFolder
      })

      folder.isExpanded = true
      folder.state = {expanded: true}

      if(folder.id.indexOf("-trash") === -1 && folder.id.indexOf("-templates") === -1) folder.children = links

      this._isMounted && this.setState({
        nodesTree: this.state.nodesTree,
      }, () => {
        var focusEl = document.getElementsByClassName("scrolledFolder")[0] && document.getElementsByClassName("scrolledFolder")[0].parentElement
        if(focusEl) focusEl = focusEl.parentElement
        if(focusEl) focusEl = focusEl.parentElement

        if(focusEl) {
          focusEl.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest'
          });
        }
      })


      var parentFolder: any = searchRecursive(this.state.folderTree, folder.parent_id || folder.parentId);
      if(parentFolder && parentFolder.id) {
        parentFolder.isExpanded = true
      }
    }
  }

  private _getFolderTree = () => {
    const { repoData } = this.props;
    var folderTree: any = [];
    var folders: any = this.props.foldersList;

    if(repoData && repoData.length && folders) {
        // Sorting folders by name
        if (folders) {
          folders = folders.sort((a,b) => {
            if(a.name.toLowerCase() < b.name.toLowerCase())
                return -1;
            if(b.name.toLowerCase() < a.name.toLowerCase())
                return 1;

            return 0;
          });

          var nested = folders.map(folder => {

            folder.key = folder.id;
            folder.parentId = folder.parent_id;
            folder.type = "folder";
            folder.accessGranted = true;
            folder.isExpanded = false;
            folder.hidden = folder.trash;
            folder.state= { expanded: false }
            folder.hasChildren = folder.hasSubFolders

            folder.links = folder.hasSubFolders ? [{}] : [];
            
            return folder
          })

          var internalRepos = repoData.filter(repo => {
            if(repo.role === "ADMIN"
              || repo.role === "USER"
              || repo.role === "INTERNAL"
              || repo.role === "GUEST") {
              return true
            } else {
              return false
            }
          })

          for (let i = 0; i < internalRepos.length; i++) {
            let repo = internalRepos[i];

            if (this.props.userData.user.role === 'GUEST') {
              this.filteredNested = nested.filter(item => {
                return item.id !== repo.id
              })
            } else {
              this.filteredNested = nested.filter(item => {
                return item.parent_id === repo.id
              })
            }

            folderTree.push(
                {
                  key: repo.id,
                  id: repo.id,
                  name: repo.name,
                  isExpanded: true,
                  hasSubFolders: true,
                  children: this.filteredNested,
                  type: "folder",
                  state: {
                    expanded: true
                  }
                }
              )
          }

          var externalRepos = repoData.filter(repo => {
            return repo.role === "EXTERNAL"
          })
          var externalReposLinks: any = []

          for (let i = 0; i < externalRepos.length; i++) {
            let repo = externalRepos[i];

            externalReposLinks.push(
              {
                key: repo.id,
                id: repo.id,
                name: repo.name,
                isExpanded: false,
                type: "repo"
              }
            )
          }

          if(externalRepos.length > 0) {
            folderTree.push({
              name: i18n.t('app:sharedWithMe'),
              children: externalReposLinks
            })
          }

          this._isMounted && this.setState({
            folderTree: folderTree,
            nodesTree: folderTree,
            loading: false
          }, () => {
            //this._getExpandedFolders(folders)
          });    
        }
      }
  }

  private _getColorTags() {
    let colors: any = [];
    for (let i=1; i<this.tagColors.colors.length; i++) {
      colors.push(
        {
          key: "color-" + this.tagColors.colors[i].id,
          name: this.tagColors.colors[i].label,
          search: this.tagColors.colors[i].id,
          id: "color-" + this.tagColors.colors[i].id,
          type: "filterColors",
        }
      )
    }
    this._isMounted && this.setState({nodesTree: colors, loading: false})
  }

  private _getFileTypes() {
    const { repoData } = this.props;
    let repo = repoData;
    let types: any = [
      {
        key: "pdf-" + repo.id,
        id: "pdf-" + repo.id,
        name: i18n.t('app:pdf'),
        search: "pdf",
        repo: repo.id,
        isExpanded: false,
        type: "filterPdf",
      },
      {
        key: "word-" + repo.id,
        id: "word-" + repo.id,
        name: i18n.t('app:word'),
        search: "doc,docx",
        repo: repo.id,
        isExpanded: false,
        type: "filterWord",
      },
      {
        key: "excel-" + repo.id,
        id: "excel-" + repo.id,
        name: i18n.t('app:excel'),
        search: "xls,xlsx,xlsm",
        repo: repo.id,
        isExpanded: false,
        type: "filterExcel",
      },
      {
        key: "powerpoint-" + repo.id,
        id: "powerpoint-" + repo.id,
        name: i18n.t('app:powerpoint'),
        search: "ppt,pptx",
        repo: repo.id,
        isExpanded: false,
        type: "filterPowerpoint",
      },
      {
        key: "email-" + repo.id,
        id: "email-" + repo.id,
        name: i18n.t('app:email'),
        search: "msg,email,eml",
        repo: repo.id,
        isExpanded: false,
        type: "filterEmail",
      }
    ]
    this._isMounted && this.setState({nodesTree: types, loading: false})
  }

  private _searchFolders(filter: string, page:number = 1) {
    const { repoData } = this.props;
    let folderTree:any = [];
    if (page === 1) this.setState({nodesTree: [], loading: true, canLoadMore: false})
    else this.setState({loadingMore: true})
    userService.search('?name='+filter+'&parent='+repoData[0].id+'&searchType=FOLDER&searchInside=true&page='+page).then((response)=>{
      if (this.props.searchFilter === filter) {
        let folders = response.data.folders;
        for (let i in folders) {
          folderTree.push(
            {
              key: folders[i].id,
              id: folders[i].id,
              parent_id: folders[i].parent_id,
              name: folders[i].name,
              pathNames: folders[i].pathNames,
              type: "folder-search",
              isExpanded: false,
              hasSubFolders: false,
              state: {
                expanded: false
              },
            }
          )
        }
        if (page === 1) this._isMounted && this.setState({nodesTree: folderTree, loading: false})
        else this._isMounted && this.setState({nodesTree: this.state.nodesTree.concat(folderTree), loadingMore: false})
  
        if (folders.length >= 50) this._isMounted && this.setState({canLoadMore: true})
        else this._isMounted && this.setState({canLoadMore: false})
  
        this.currentPage = page + 1;
      }
    }).catch(()=>{
      this._isMounted && this.setState({loading: false})
    })
  }

  private _goToFolder(ev, item) {
    if (item) {
      this.props.goToFolder(item)
    }
  };

  private _goToFolderAndClose(ev, item) {
    if (item) {
      this.props.callbackFunction({filterColumnOpen: '', searchPanelFilter: ''})
      this.props.fileExplorerRef._findInFolderTree(item)
      this.props.goToFolder(item)
    }
  };


  public render() {
    this.tagColors = require('./tagColors.json');

    return(
      <div
        id="picker-nav-extra"
        style={{padding: this.state.loading || this.state.nodesTree.length === 0 ? '0 7px 0 0' : '0', borderRight: this.state.loading || this.state.nodesTree.length === 0 ? '1px solid rgb(237, 235, 233)' : 'none', background: '#f2f2f2'}}
      >
        <nav className={"tree filterSidebar" + (this.props.type === 'searchFolders' ? " searchFolders" : "")}>
          { this.props.type === 'searchFolders' ? <>
              <p className="title sidebar2 mb-0" style={{backgroundColor: 'white', borderRight: !this.state.loading && this.state.nodesTree.length > 0 ? '1px solid #f2f2f2' : 'none'}}>{i18n.t('app:searchResults')}</p>
              <IconButton className='panel-icon' iconProps={{iconName:'ClosePaneMirrored'}} styles={{icon: {fontSize: 20}, root: {position: 'absolute', right: '24px',top: '8px', zIndex: 2}}} onClick={()=>{this.props.callbackFunction({filterColumnOpen: '', searchPanelFilter: ''})}}/>
            </>
          : <>
              <p className="title sidebar2 mb-0" style={{backgroundColor: 'white', borderRight: !this.state.loading && this.state.nodesTree.length > 0 ? '1px solid #f2f2f2' : 'none'}}>{i18n.t('app:Filters')} - {this.props.type === 'folder' ? i18n.t('app:folder') : this.props.type === 'tags' ? i18n.t('app:tags') : this.props.type === 'colorTag' ? i18n.t('app:colorTag') : i18n.t('app:fileTypes')}</p>
              <IconButton className='panel-icon' iconProps={{iconName:'ClosePaneMirrored'}} styles={{icon: {fontSize: 20}, root: {position: 'absolute', right: '24px',top: '8px', zIndex: 2}}} onClick={()=>{this.props.callbackFunction({filterColumnOpen: '', searchPanelFilter: ''})}}/>
            </>
          }
          { this.props.type === 'searchFolders' && !this.state.loading && this.state.nodesTree.length > 0 && <>
            <p className="title sidebar2 subtitle mb-0" style={{backgroundColor: 'white', borderRight: !this.state.loading ? '1px solid #f2f2f2' : 'none'}}>{i18n.t('app:clickIconToOpenInFolderTree')}</p>
          </> }
          <ul className="ms-Nav-navItems pl-0 m-0" style={{backgroundColor: 'white', height: this.state.canLoadMore ? 'calc(100% - 42px)' : '100%', overflowY: this.state.loading ? 'hidden' : 'inherit'}}>
          {!this.state.loading ? this.state.nodesTree.length > 0 ? <Tree
            nodes={this.state.nodesTree}
            onChange={this.handleNodesChange}>
            {({onChange, style, node, index, ...rest}) => {

              const {hasChildren, isExpanded, type} = getNodeRenderOptions(node);
              const handleChange = () =>{
                onChange({...updateNode(node, {expanded: !isExpanded}) })
              }
              
              if(typeof(style.marginLeft) === "number") style.paddingLeft = style.marginLeft / 2
              if(typeof(style.marginLeft) === "number") style.marginLeft = 0

              var currentPath = this.props.folderId
              let selected = false;
              selected = node.id === currentPath;

              return(
                <li className={"ms-Nav-navItem " + (selected ? 'bg-selected' : '')} style={style} tabIndex={index} onClick={(ev) => {this.props.type === 'searchFolders' ? this._goToFolder(ev, node) : this._addFilter(ev,node)}}>
                      <div className={ "ms-Nav-compositeLink d-flex"} style={{minWidth: '100%'}} >
                    
                        <button className="chevronButton">
                          { hasChildren && !isExpanded && this.props.userData && this.props.userData.user.role !== 'GUEST'?
                            <Icon iconName="ChevronRight"  onClick={(e) => {e.stopPropagation(); handleChange();
                              if(type === "repo"
                              || type === "folder"
                              || type === "templates")
                                this._handleExpandLinkClick(undefined, node)}} className="ms-Nav-chevron d-inline-block" />
                          : hasChildren && isExpanded && this.props.userData && this.props.userData.user.role !== 'GUEST'?
                            <Icon iconName="ChevronDown"  onClick={(e) => {e.stopPropagation();handleChange()}} className="ms-Nav-chevron d-inline-block" />
                          :
                            <div className="noChevron"></div>
                          }
                        </button>
                        
                        <button className="ms-Button ms-Button--action ms-Button--command ms-Nav-link pl-0 flex-grow-1">
                          { this._onRenderLink(node) }
                        </button>
                  
                  </div>
                    </li>
              )}
            }
          </Tree>
          : <div className='d-flex justify-content-center' style={{ width: 'calc(100% - 13px)'}}>
              <span className='second-sidebar-spinner text-center text-secondary' >{i18n.t('app:noItemsSearch')}</span>
            </div>
          :
          <Spinner className='second-sidebar-spinner'/> }
          </ul>
          {this.state.canLoadMore && <div className='d-flex align-items-center justify-content-center' style={{background: 'white', width: 'calc(100% - 8px)', height: '40px'}}>
            <PrimaryButton style={{width: 'calc(100% - 21px)'}} disabled={this.state.loadingMore || this.state.loading} onClick={()=>this._searchFolders(this.props.searchFilter, this.currentPage)}>
            { this.state.loadingMore ? <Spinner/> : <span style={{fontWeight: 600}}>{i18n.t('app:loadMoreResults')}</span>}
            </PrimaryButton>
          </div>}
        </nav>
      </div>
    )
  }

}
