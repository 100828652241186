import * as React from 'react';
import { history } from '../_helpers';

import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { IconButton, IIconProps } from '@fluentui/react';

import i18n from "i18next";
import { Icon, SearchBox } from '@fluentui/react';
import { Titlebar } from './Titlebar';

export interface Props {
  match: {
    url: string
  }
}

export interface HeaderState {
  userData?: any;
  repoData?: any;
  breadcrumbPath: any[];
  goBack: number;
  selFile: any;
  syncStatus: any;
  showRightPanel: boolean;
  errorMessage: string;
  filterByNameText: string;
}

export class Header extends React.Component<Props, HeaderState> {
  private _isMounted: boolean;

  props: any;

  constructor(props: any) {
      super(props);
      this._isMounted = false;
      
      this.props = props
      const breadcrumbPath = [
            {
                text: 'Synergy',
                key: '/',
                onClick: this._onBreadcrumbItemClicked.bind(this)
            }
        ]

    this.state = {
      userData: props.userData,
      repoData: props.repoData,
      breadcrumbPath: breadcrumbPath,
      goBack: 0,
      selFile: props.selFile,
      syncStatus: props.syncStatus,
      showRightPanel: props.showRightPanel,
      errorMessage: "",
      filterByNameText: ""
    };
  }

  //pendingUploadsIntervalId = 0;

  public componentDidMount() {
    this._isMounted = true;

    var breadcrumbItem = this.props.breadcrumbItem
    if(breadcrumbItem === "activity") {
        var newItem = {
            text: i18n.t('header:users.activity'),
            key: '/activity',
            onClick: this._onBreadcrumbItemClicked.bind(this)
        }

        this._isMounted && this.setState({
            breadcrumbPath: [...this.state.breadcrumbPath, newItem]
        })
    }
  }

  public componentDidUpdate(prevProps:any, prevState:any) {
    if(this.props.showRightPanel !== prevProps.showRightPanel) {
      this._isMounted && this.setState({showRightPanel: this.props.showRightPanel})
    }
    if(prevState.filterByNameText !== this.state.filterByNameText && window.location.href.indexOf("/sessions") === -1) {
      this.props.callbackFunction({filterByNameText: this.state.filterByNameText})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }


  public render() {
    const { showRightPanel } = this.state;

    const backIcon: IIconProps = { iconName: 'Back' };
    const forwardIcon: IIconProps = { iconName: 'Forward' };

    var docActionItems: ICommandBarItemProps[];

    if(this.props.actions === "users"  && this.props.page !== 'import') {
      docActionItems = [
        {
          key: 'showLeftPanel',
          title: i18n.t('app:toggleLeftPanel'), 
          iconProps: {
            iconName: 'SidePanel',
          },
          className: 'sidePanel-button',
          onClick: () => this._toggleLeftPanel(),
        },
        {
          key: 'addUser',
          text: i18n.t('header:users.addUser'),
          iconProps: { iconName: 'AddFriend' },
          onClick: this.props.addUser.bind(this)
        },
        {
          key: 'importUsers',
          text: i18n.t('header:users.importUsers'),
          iconProps: { iconName: 'AddFriend' },
          onClick: this.props.importUsers.bind(this)
        },
        {
          key: 'editUser',
          text: i18n.t('header:users.editUser'),
          iconProps: { iconName: 'Edit' },
          disabled: this.props.selection.getSelectedCount() !== 1,
          onClick: this.props.editUser.bind(this)
        },
        {
          key: 'userTemplates',
          text: i18n.t('header:users.userTemplates'),
          iconProps: { iconName: 'CustomList' },
          hidden: true
        },
        {
          key: 'addMultpleUsers',
          text: i18n.t('header:users.addMultipleUsers'),
          iconProps: { iconName: 'PeopleAdd' },
          onClick: this.props.addMultipleUsers.bind(this),
          hidden: true
        },
        {
          key: 'deleteUser',
          text: i18n.t('header:users.deleteUser'),
          iconProps: { iconName: 'UserRemove' },
          disabled: this.props.selection.getSelectedCount() !== 1 || (this.props.selection.getSelection() && this.props.selection.getSelection()[0]?.state === "REMOVED" ),
          onClick: this.props.deleteUser.bind(this)
        },
        {
          key: 'copyEmails',
          text: i18n.t('header:users.copyEmails'),
          iconProps: { iconName: 'Copy' },
          onClick: this.props.copyEmails.bind(this)
        },
        {
          key: 'multiFactorAuthentication',
          text: i18n.t('header:users.multiFactorAuthentication'),
          iconProps: { iconName: 'Lock' },
          hidden: true
        },
        {
          key: 'filter',
          text: i18n.t('header:users.filter'),
          iconProps: { iconName: 'Filter' },
          hidden: true
        }
      ]
    } else if(this.props.actions === "users" && this.props.page === 'import') {
      docActionItems = [
        {
          key: 'showLeftPanel',
          title: i18n.t('app:toggleLeftPanel'), 
          iconProps: {
            iconName: 'SidePanel',
          },
          className: 'sidePanel-button',
          onClick: () => this._toggleLeftPanel(),
        },
        {
          key: 'importUsers',
          text: i18n.t('header:users.importUsers'),
          iconProps: { iconName: 'AddFriend' },
          onClick: this.props.importUsers.bind(this)
        },
        {
          key: 'editUser',
          text: i18n.t('header:users.editUser'),
          iconProps: { iconName: 'Edit' },
          disabled: this.props.selection.getSelectedCount() !== 1,
          onClick: this.props.editUser.bind(this)
        },
        {
          key: 'confirmImport',
          text: i18n.t('users:confirmImport'),
          iconProps: { iconName: 'WaitlistConfirmMirrored' },
          onClick: this.props.createUsersFromFile.bind(this),
          disabled: this.props.importing || (this.props.sheetFileUsers && this.props.sheetFileUsers.length === 0)
        },
      ]
    } else if(this.props.actions === "groups") {
      docActionItems = [
        {
          key: 'showLeftPanel',
          title: i18n.t('app:toggleLeftPanel'), 
          iconProps: {
            iconName: 'SidePanel',
          },
          className: 'sidePanel-button',
          onClick: () => this._toggleLeftPanel(),
        },
        {
          key: 'refresh',
          text: i18n.t('header:groups.refresh'),
          iconProps: { iconName: 'Refresh' },
          onClick: this.props.getGroups.bind(this),
          hidden: true
        },
        {
          key: 'addGroup',
          text: i18n.t('header:groups.addGroup'),
          iconProps: { iconName: 'AddGroup' },
          onClick: this.props.addGroup.bind(this)
        },
        {
          key: 'addGuestGroup',
          text: i18n.t('header:groups.addGuestGroup'),
          iconProps: { iconName: 'AddGroup' },
          onClick: this.props.addGuestGroup.bind(this)
        },
        {
          key: 'editGroup',
          text: i18n.t('header:groups.editGroup'),
          iconProps: { iconName: 'Edit' },
          disabled: this.props.selection.getSelectedCount() !== 1,
          onClick: this.props.editGroup.bind(this)
        },
        {
          key: 'deleteGroup',
          text: i18n.t('header:groups.deleteGroup'),
          iconProps: { iconName: 'Delete' },
          disabled: this.props.selection.getSelectedCount() !== 1,
          onClick: this.props.deleteGroup.bind(this)
        }
      ]
    } else if (this.props.actions === "home") {
      docActionItems = [
        {
          key: 'showLeftPanel',
          title: i18n.t('app:toggleLeftPanel'), 
          iconProps: {
            iconName: 'SidePanel',
          },
          className: 'sidePanel-button',
          onClick: () => this._toggleLeftPanel(),
        }
      ]
    } else if (this.props.headerSection === "approvals") {
      docActionItems = [
        {
          key: 'showLeftPanel',
          title: i18n.t('app:toggleLeftPanel'), 
          iconProps: {
            iconName: 'SidePanel',
          },
          className: 'sidePanel-button',
          onClick: () => this._toggleLeftPanel(),
        },
        /*{
          key: 'refresh',
          text: 'Refresh', 
          iconProps: {
            iconName: 'Refresh',
          },
          onClick: () => this._addApproval(),
        }*/
      ]
    } else {
      docActionItems = [
        {
          key: 'showLeftPanel',
          title: i18n.t('app:toggleLeftPanel'), 
          iconProps: {
            iconName: 'SidePanel',
          },
          className: 'sidePanel-button',
          onClick: () => this._toggleLeftPanel(),
        }
      ]
    }

    // function changeLanguage(language: string) {
    //   i18n.changeLanguage(language);
    // }

    // const languageProps: IContextualMenuProps = ({
    //   shouldFocusOnMount: true,
    //   items: [
    //     { key: 'english', text: 'English', onClick: () => {changeLanguage('en'); window.location.href = i18n.language; }},
    //     { key: 'portuguese', text: 'Português', onClick: () => {changeLanguage('pt'); window.location.href = i18n.language; }}
    //   ],
    // });

    const farItems: ICommandBarItemProps[] = [
      {
        key: 'filter',
        title: i18n.t('app:toggleRightPanel'), 
        iconProps: { iconName: 'SidePanelMirrored' },
        className: 'sidePanel-button',
        onRender: ()=> {
          if (window.location.pathname !== '/home' && window.location.pathname !== '/policies' && window.location.pathname !== '/settings' && window.location.pathname !== '/users/active') {
            return(
              <div className={"filtersBox content-wrap d-flex flex-row justify-content-center" + (window.innerWidth < 480 ? " noFilterBox" : window.innerWidth < 640 ? " smallFilterBox" : "")}>
                <div className="filter">
                  <SearchBox placeholder={i18n.t('header:filter') +" "+ i18n.t('header:byName')} value={this.state.filterByNameText} onChange={(e,v)=>{this._isMounted && this.setState({filterByNameText: v || ""})}} iconProps={{iconName: "Filter"}} />
                </div>
              </div>
            )
          }
          else {
            return null;
          }
        }
      },
      {
        key: 'showRightPanel',
        title: i18n.t('app:toggleRightPanel'), 
        iconProps: { iconName: 'SidePanelMirrored' },
        className: 'sidePanel-button',
        onClick: () => { this.props.callbackFunction({showRightPanel: !showRightPanel}) },
        hidden: window.location.pathname === '/home' || window.location.pathname === '/policies' || window.location.pathname === '/settings'
      }
    ]

    return(
      <div className="toolbar">
        <Titlebar disabledSearch={true}/>
        <div className="top-bar d-flex flex-row">
            <div className="history-actions">
              <IconButton iconProps={backIcon} title={i18n.t('header:back')} ariaLabel="Back" onClick={ this._goBack.bind(this) }  />
              <IconButton iconProps={forwardIcon} title={i18n.t('header:forward')} ariaLabel="Forward" onClick={ this._goForward.bind(this) } />
              <IconButton className='refresh-button' iconProps={{ iconName: 'Refresh' }} style={{marginLeft: '9px'}} title={i18n.t('app:refresh')} ariaLabel="Refresh" onClick={this.refreshContent.bind(this)} />          
            </div>
            <div className={"folders-breadcrumb flex-grow-1"}>
                <div className="d-flex m-0 p-0">
                <div className="breadcrumb-icon">
                { window.location.pathname.includes('users') ?
                  <Icon iconName="Contact" />
                : window.location.pathname.includes('groups') ?
                  <Icon iconName="Group" />
                : window.location.pathname.includes('sharing') ?
                  <Icon iconName="Share" />
                : window.location.pathname.includes('activity') ?
                  <Icon iconName="LineChart" />
                : window.location.pathname.includes('approvals') ?
                  <Icon iconName="CheckboxComposite" />
                : window.location.pathname.includes('policies') ?
                  <Icon iconName="Repair" />
                : window.location.pathname.includes('sessions') ?
                  <Icon iconName="Devices3" />
                : window.location.pathname.includes('settings') ?
                  <Icon iconName="Settings" />
                : window.location.pathname.includes('conversations') ?
                  <Icon iconName="Chat" />
                : <Icon iconName="home" />
                }
              </div>
                <div className="col m-0 p-0">
                    <Breadcrumb
                        items={ this.props.breadcrumb }
                        ariaLabel="Breadcrumb"
                        overflowAriaLabel="More..."
                        overflowIndex={ 0 }
                        //onRenderItem={ this._onRenderBreadcrumbItem }
                    />
                </div>
                </div>
            </div>
        </div>

        <CommandBar
          items={docActionItems}
          farItems={farItems}
          ariaLabel="Use left and right arrow keys to navigate between commands"
          className="commandBar"
        />
      </div>
    )
  }

  private _goBack() {
    history.goBack();
  }

  private _goForward() {
    history.goForward();
  }

  private _goToHome() {
    history.push("/");
  }

  private _onBreadcrumbItemClicked(ev: React.MouseEvent<HTMLElement> | undefined, item: IBreadcrumbItem | undefined): void {
    if (item) {
      history.push(item.key)
    }
  }

  private _toggleLeftPanel() {
    var nav = '';
    if (window.location.pathname.includes('home')) nav = "home"
    if(window.location.pathname.includes('users')) nav = "users"
    if (window.location.pathname.includes('groups')) nav = "groups"
    if (window.location.pathname.includes('approvals')) nav = "approvals"
    if (window.location.pathname.includes('sharing')) nav = "sharing"
    if (window.location.pathname.includes('activity')) nav = "activity"
    if (window.location.pathname.includes('sessions')) nav = "sessions"
    if (window.location.pathname.includes('policies')) nav = "policies"
    if (window.location.pathname.includes('settings')) nav = "settings"
    if(nav) this.props.sidebarRef.toggleNav(nav)
  }

  private refreshContent() {
    if (window.location.pathname.includes('sharing') || window.location.pathname.includes('activity')) this.props.getActivity()
    if (window.location.pathname.includes('groups')) this.props.getGroups()
    if (window.location.pathname.includes('users') && this.props.page !== 'import') this.props.getUsers()
    if (window.location.pathname.includes('sessions')) this.props.getCurrentContent()
  }

  private _addApproval() {
    setTimeout(()=>{
      this.props.callbackFunction({approvalAdded: true})
    },500)
  }
}