import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import headerEN from './locales/en/header.json';
import sidebarEN from './locales/en/sidebar.json';
import usersEN from './locales/en/users.json';
import groupsEN from './locales/en/groups.json';
import activityEN from './locales/en/activity.json';
import actionsEN from './locales/en/actions.json';
import activityMessagesEN from './locales/en/activity-messages.json';
import loginPageEN from './locales/en/loginPage.json';
import homeEN from './locales/en/home.json';
import detailsEN from './locales/en/details.json';
import settingsEN from './locales/en/settings.json';
import policiesEN from './locales/en/policies.json';
import approvalsEN from './locales/en/approvals.json';
import sessionsEN from './locales/en/sessions.json';
import appEN from './locales/en/app.json';

import usersPT from './locales/pt/users.json';




// the translations
const resources = {
  en: {
    header: headerEN,
    sidebar: sidebarEN,
    users: usersEN,
    groups: groupsEN,
    activity: activityEN,
    actions: actionsEN,
    activityMessages: activityMessagesEN,
    loginPage: loginPageEN,
    home: homeEN,
    details: detailsEN,
    settings: settingsEN,
    policies: policiesEN,
    approvals: approvalsEN,
    sessions: sessionsEN,
    app: appEN
  },
  pt: {
    users: usersPT
  }
};


i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    detection: {
      order: ["querystring", "localStorage"],
      lookupQuerystring: "lng",
      caches: ["localStorage"]
    },
    fallbackLng: "en", // use en if detected lng is not available
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;