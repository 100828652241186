import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';

export const userActions = {
    login,
    logout
};

function login(username: string, password: string, domain: string, staySignedIn: boolean) {
    return (dispatch: any) => {
        dispatch(request({ username }));

        return userService.login(username, password, domain, staySignedIn )
            .then(
                (user: any) => {
                    dispatch(success(user));
                    return Promise.resolve(user);
                },
                (error: string) => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user: any) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user: any) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error: string) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}