import * as React from 'react';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import "moment/min/locales";

import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, Persona, PersonaSize, PrimaryButton, TextField, TooltipHost } from '@fluentui/react';
import Moment from 'moment';

import i18n from "i18next";
import { DetailsPanel } from '../_components/DetailsPanel';
import ReactDOM from 'react-dom';
import { userService } from '../_services';

/*const fakeApprovals = [
  {
    name: 'Jaime Rivas',
    account: 'jaime@synergy.page',
    action: 'Created public link',
    approved: false,
    item: 'Platform Features v2.00.docx',
    requestMessage: 'Need approval to share with Dave.',
    date: 1659518990000
  },
  {
    name: 'Adele Vance',
    account: 'vance@synergy.page',
    action: 'Created public link',
    approved: false,
    item: 'Annual Report.xlsx',
    requestMessage: '',
    date: 1659517890000
  },
  {
    name: 'Jaime Rivas',
    account: 'jaime@synergy.page',
    action: 'Created public link',
    approved: true,
    by: 'Jaime Rivas',
    item: 'Platform Features v1.00.docx',
    requestMessage: '',
    date: 1655467890000
  },
]*/

export interface ApprovalsPageState {
  userData?: any;
  repoData?: any;
  showLeftPanel: boolean;
  selLink: any;
  syncStatus: any;
  downloadingFiles: any;
  uploadsQueue: any;
  isLoading: boolean;
  goBack: number;
  contextualMenuProps?: IContextualMenuProps;
  breadcrumb:any;
  showRightPanel: boolean;
  selItem: any;
  approvalAdded: boolean;
  approvals: any;
}

export interface Props {
  match: {
    url: string
  },
  location: any,
  appPlatform: string,
  isOnline: boolean,
  maintenance: boolean,
  socket: any,
  pusher: any,
  appUpdateAvailable: boolean,
  webUpdateAvailable: boolean,
  fileExplorerType: string,
  userData: any,
}

export class ApprovalsPage extends React.Component<Props, ApprovalsPageState> {
  private _isMounted: boolean;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this.state = {
      userData: null,
      repoData: null,
      showLeftPanel: true,
      selLink: null,
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      downloadingFiles: [],
      uploadsQueue: this._getImports(),
      isLoading: false,
      goBack: 0,
      contextualMenuProps: undefined,
      breadcrumb: [],
      showRightPanel: true,
      selItem: null,
      approvalAdded: false,
      approvals: null//fakeApprovals
    }
  }

  public componentDidMount() {
    this._isMounted = true;
    this.setState({breadcrumb: [{
      text: 'Approvals',
      key: '/approvals',
      onClick: this.headerRef._onBreadcrumbItemClicked.bind(this)
    }]});
    if (!localStorage.openFilePath) localStorage.setItem('openFilePath','askToOpen')
    if (!localStorage.pdfApplication) localStorage.setItem('pdfApplication','Default')
  }

  public componentDidUpdate(prevProps, prevState) {
    if (prevState.approvalAdded !== this.state.approvalAdded && this.state.approvals && this.state.approvals.length < 4) {
      let tempApprovals = this.state.approvals
      userService.sendApproval();
      tempApprovals.unshift({
        name: 'Adele Vance',
        account: 'vance@synergy.page',
        action: 'Created public link',
        approved: false,
        item: 'Blog posts for website.docx',
        date: new Date().getTime()
      },)
      this._isMounted && this.setState({approvals: tempApprovals})
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  public render() {

    const { approvals } = this.state;

    return(
      <Fabric>
        <Header
          ref={instance => { this.headerRef = instance; }}
          headerSection="approvals"
          appPlatform={ this.props.appPlatform }
          isOnline={ this.props.isOnline }
          maintenance={ this.props.maintenance }
          pusher={this.props.pusher}
          repoData={ this.state.repoData }
          folderId={ this.props.match['params'].id }
          syncStatus={ this.state.syncStatus }
          downloadingFiles={ [] }
          uploadsQueue={ [] }
          getCurrentContent={ this._getCurrentContent.bind(this) }
          fileExplorerType={this.props.fileExplorerType}
          sidebarRef={this.sidebarRef}
          breadcrumb={this.state.breadcrumb}
          callbackFunction={ this.callbackFunction }
          showRightPanel={ this.state.showRightPanel }
        />
        <div className="d-flex flex-row">      
          <Sidebar
            ref={instance => { this.sidebarRef = instance; }}
            appPlatform={ this.props.appPlatform }
            isOnline={ this.props.isOnline }
            maintenance={ this.props.maintenance }
            appUpdateAvailable={ this.props.appUpdateAvailable }
            webUpdateAvailable={ this.props.webUpdateAvailable }
            userData={ this.props.userData }
            downloadingFiles={ this.state.downloadingFiles }
            uploadsQueue={ this.state.uploadsQueue }
            headerRef={ this.headerRef }
            searchQuery={ this.props.location.search }
            fileExplorerType={this.props.fileExplorerType}
            callbackFunction={ this.callbackFunction }
            sidebarNav="approvals"
            headerSection="approvals"
          />
          <div className={"list mr-auto flex-grow-1 "  + this.props.appPlatform} style={{background:'rgb(250, 249, 248)'}}>
            <div className='m-3'>
              {approvals && approvals.length > 0 && approvals.map((request,index)=> {
                return(
                  <div key={index} className={'session w-100 px-3 pt-3'  + (this.state.selItem === request ? ' selected' : '') } onClick={()=>{this._isMounted && this.setState({selItem: request})}}>
                    <div className='session-header w-100'>
                      <div className='d-flex align-items-center mb-3'>
                        <Icon className='me-3' iconName='Globe' style={{fontSize: 24}}/>
                        <span className='me-3' style={{fontWeight: 600}}>{request.action}</span>
                        {request.approved ? 
                          <div className='ms-auto me-0 d-flex align-items-center'>
                            <span className='me-2'>Approved by {'Jaime Rivas'}</span>
                            <Icon className='approve-icon' iconName='CheckMark'/>
                          </div>
                        : request.denied ? 
                          <div className='ms-auto me-0 d-flex align-items-center'>
                            <span className='me-2'>Denied by {'Jaime Rivas'}</span>
                            <Icon className='approve-icon' iconName='Cancel'/>
                          </div>
                        : <div className='ms-auto me-0'>
                            <DefaultButton onClick={()=>this._approveMessage(false,index)} className='me-2' style={{border:'1px solid black'}} text='Deny' />
                            <PrimaryButton onClick={()=>this._approveMessage(true,index)} text='Approve' />
                          </div>
                        }
                      </div>
                      <hr className='w-100'/>
                    </div>
                    <div className='d-flex w-100 session-body'>
                      <div className='me-4'>
                        <p>Created by</p>
                        <TooltipHost
                          key={'createdBy'}
                          content= {
                            <div className='m-1'>
                              <Persona className='mb-2' text={request.name} initialsColor="#7F7F7F" size={PersonaSize.size32}/>
                              <span className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</span>
                              <p className='p-0 m-0' style={{fontSize:'14px'}}>{request.account}</p>
                            </div>
                          }
                          id={'createdBy'}
                          calloutProps={{ gapSpace: 0 }}
                        >
                          <span className='mb-1 pr-1 persona-link'>{request.name}</span>
                        </TooltipHost>
                      </div>
                      <div className='me-4'>
                        <p>Date</p>
                        <span>{Moment(request.date).format('Y-MM-DD HH:mm')}</span>
                      </div>
                      {request.requestMessage && request.requestMessage.length > 0 && <div className='me-4 flex-grow-1'>
                        <p>Message</p>
                        <span>{request.requestMessage}</span>
                      </div>}
                    </div>
                  </div>
                )
              })}
              { !approvals  && 
              <div className="empty-folder-msg p-3">
                <p className="text-center text-secondary mx-auto px-3">
                  Empty
                </p>
              </div>
              }
            </div>
          </div>
          <DetailsPanel
            //ref={instance => { this.detailsPanelRef = instance; }}
            userData={ this.props.userData }
            //items={ companies }
            showRightPanel={ this.state.showRightPanel }
            selItem={ this.state.selItem }
            headerRef={ this.headerRef }
            detailsPanelSection="approvals"
            callbackFunction={ this.callbackFunction }
          />
        </div>
      </Fabric>
    )
  }

  private _getCurrentContent() {
    this._isMounted && this.setState({
      syncStatus: {
        message: "Syncing",
        type: "info",
        icon: "spinner"
      },
      isLoading: true
    })

  }

  private _getImports() {
    if(localStorage.getItem("uploadsQueue")) {
      return JSON.parse(localStorage.getItem("uploadsQueue") || "{}");
    } else {
      return [];
    }
  }

  private _approveMessage = (approve:boolean,index:number) => {
    var showDialog = true;
    var message: string = "";

    var approveExit = () => {
      showDialog = false;
      renderDialog();
      let approvals = this.state.approvals
      approvals[index].approved = true;
      approvals[index].by = 'Jaime Rivas';
      approvals[index].finalMessage = message;
      this._isMounted && this.setState({approvals: approvals})
    }

    var denyExit = () => {
      showDialog = false;
      renderDialog();
      let approvals = this.state.approvals
      approvals[index].denied = true;
      approvals[index].by = 'Jaime Rivas';
      approvals[index].finalMessage = message;
      this._isMounted && this.setState({approvals: approvals})    }

    var handleMessageChange = (ev: any, newValue?: string) => {
      message = newValue || ""
      renderDialog()
    }

    var div = document.createElement('div');
    var renderDialog = () => {

      ReactDOM.render(
        <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: <div>{approve ? 'Approve Request' : 'Deny Request'}</div>
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "add-group-dialog"
            }}
            onDismiss={() => {showDialog = false; renderDialog()}}
          >

          <div className="dialog-content-wrap">
            <TextField multiline autoFocus placeholder={'Message (Optional)'}  name="newItemName" value={ message } onChange={ handleMessageChange } />

            <DialogFooter className="mt-4">
              <DefaultButton onClick={() => {showDialog = false; renderDialog()}} text={'Cancel'}  />
              <PrimaryButton onClick={() => approve ? approveExit() : denyExit()} text={approve? 'Approve': 'Deny'} />
            </DialogFooter>
          </div>

          </Dialog>
        , div
      )
    }

    renderDialog();

  }
}
