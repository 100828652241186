import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends Omit<RouteProps, "component"> {
  component: React.ElementType;
  data: any;
  // any additional vars
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, data, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem('user')
            ? <Component {...props} {...data} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
)