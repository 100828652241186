import * as React from 'react';
import ReactDOM from 'react-dom';
import { userService } from '../_services';
import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import { Fabric } from '@fluentui/react';
import { ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { ShimmeredDetailsList } from '@fluentui/react';
import Moment from 'moment';
import "moment/min/locales";
import { Dialog, DialogType, DialogFooter } from '@fluentui/react';
import { PrimaryButton, DefaultButton } from '@fluentui/react';
import {
  ComboBox,
  IComboBox,
  IComboBoxOption
} from '@fluentui/react';
import { TextField } from '@fluentui/react';
import { Icon } from '@fluentui/react';
import { IColumn, ConstrainMode, SelectionMode, Selection } from '@fluentui/react';
import i18n from "i18next";
import { DetailsPanel } from '../_components/DetailsPanel';
import { FormCreateGroups } from '../_components/FormCreateGroups';

export interface Props {
  location: any;
  userData: any;
}

export interface UsersState { 
  userData: any;
  breadcrumb: any[];
  columns: IColumn[];
  selItems: any;
  items: any;
  itemsFull: any;
  loadingGroups: boolean;
  showRightPanel: boolean;
  filterByNameText: string;
}

export interface IDocument {
  key: string;
  name: string;
  people_count: number;
  date_created: Date;
}

export class Groups extends React.Component<Props, UsersState> {
  private _isMounted: boolean;
  private _selection: Selection;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this._selection = new Selection({
      onSelectionChanged: () => {
        var selItem: any = this._selection.getSelection()[this._selection.getSelection().length - 1]

        
        setTimeout(() => {
          if (selItem && this._selection.getSelectedCount() === 1) {
            this._isMounted && this.setState({selItems: selItem});
          } else if (selItem && this._selection.getSelectedCount() > 1) {
            this._isMounted && this.setState({selItems: null});
          } else if (this._selection.getSelectedCount() === 0) {
            this._isMounted && this.setState({selItems: null});
          }
        }, 50)
      }
    });

    const columns: IColumn[] = [
      {
        key: 'name',
        name: i18n.t('groups:name'),
        fieldName: 'name',
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        minWidth: 170,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              <div>
                <span>{ item.name }</span>
              </div>
            )
        }
      },
      {
        key: 'people_count',
        name: i18n.t('groups:people'),
        fieldName: 'people_count',
        minWidth: 90,
        maxWidth: 90,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'number',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              <div>
                <span>{ item.people_count }</span>
              </div>
            )
        }
      },
      {
        key: 'date_created',
        name: i18n.t('groups:dateCreated'),
        fieldName: 'date_created',
        minWidth: 170,
        maxWidth: 170,
        isRowHeader: true,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'Date',
        isPadded: true,
        onRender: (item: IDocument) => {
          return (
              <div>
                <span>{ Moment(item.date_created).format('Y-MM-DD HH:mm:ss') }</span>
              </div>
            )
        }
      },
    ];

    this.state = {
      userData: null,
      breadcrumb: [],
      columns: columns,
      selItems: null,
      items: [],
      itemsFull: [],
      loadingGroups: true,
      showRightPanel: true,
      filterByNameText: '',
    }
  }

  public componentDidMount() {
      this._isMounted = true;
      if(this.props.userData) {
        this.setBreadcrumb();
        this._getGroups();
      }

  }

  componentDidUpdate (prevProps: any, prevState: any) {
    if(this.props.userData !== prevProps.userData) {
      this.setBreadcrumb();
      this.setBreadcrumb();
      this._getGroups();
    }

    if(prevState.filterByNameText !== this.state.filterByNameText) {
      this._isMounted && this.setState({
        items: this.state.itemsFull.filter((item)=>{
          return (item.name + " " + item.surname).toLowerCase().includes(this.state.filterByNameText)
        })
      })
    }
  }

  private setBreadcrumb() {
    this._isMounted && this.setState({
      breadcrumb: [
        {
          text: i18n.t('groups:groups'),
          key: '/groups',
          onClick: this.headerRef._onBreadcrumbItemClicked.bind(this)
        },
        window.location.pathname.endsWith("active") ?
          {
            text: i18n.t('groups:active'),
            key: '/groups/active',
            onClick: this.headerRef._onBreadcrumbItemClicked.bind(this)
          }
        :
          {
            text: i18n.t('groups:deleted'),
            key: '/groups/deleted',
            onClick: this.headerRef._onBreadcrumbItemClicked.bind(this)
          }
      ]
    })
  }

  public render() {
    const { columns, items, loadingGroups } = this.state;

    const callbackFunction = (childData) => {
      this._isMounted && this.setState(childData)
    }

    return(
      <Fabric>
        <Header
          ref={(instance: any) => { this.headerRef = instance; }}
          userData={ this.props.userData }
          breadcrumb={ this.state.breadcrumb }
          selection={ this._selection }
          getGroups={ this._getGroups.bind(this) }
          addGroup={ this._addGroup.bind(this) }
          addGuestGroup={ this._addGuestGroup.bind(this) }
          editGroup={ this._editGroup.bind(this) }
          deleteGroup={ this._deleteGroup.bind(this) }
          actions="groups"
          sidebarRef={this.sidebarRef}
          callbackFunction={ callbackFunction }
          showRightPanel={ this.state.showRightPanel }
        />

        <div className="content-wrap d-flex flex-row">
          <div className="fakeLine"></div>
          <Sidebar ref={(instance: any) => { this.sidebarRef = instance; }} userData={ this.props.userData } />

          <div className="list mr-auto flex-grow-1">
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <ShimmeredDetailsList
                items={items}
                compact={true}
                columns={columns}
                setKey="set"
                selection={this._selection}
                selectionMode={SelectionMode.single}
                isHeaderVisible={true}
                enableShimmer={loadingGroups}
                constrainMode={ConstrainMode.unconstrained}
                ariaLabelForShimmer="Content is being fetched"
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox"
                shimmerLines={12}
              />
            </ScrollablePane>
          </div>
          <DetailsPanel
            //ref={instance => { this.detailsPanelRef = instance; }}
            userData={ this.props.userData }
            //items={ companies }
            showRightPanel={ this.state.showRightPanel }
            selItem={ this.state.selItems }
            headerRef={ this.headerRef }
            _selection={this._selection}
            detailsPanelSection="groups"
            callbackFunction={ callbackFunction }
          />
        </div>
      </Fabric>
    )
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    var newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);

    localStorage.setItem("groupsColumns", JSON.stringify(newColumns))

    this._isMounted && this.setState({
      columns: newColumns,
      items: newItems
    });
  };

  private _getGroups() {
    this._isMounted && this.setState({ loadingGroups: true })
    
    userService.getGroups('ALL').then((response: any) => {
      var groups = response.data.map((group: any) => {
        if(!group.name) group.name = "Undefined name"
        group.people_count = group.users.length
        return group
      })

      groups = groups.sort(function(a:any, b: any){
        if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        return 0;
      })

      this._isMounted && this.setState({
        items: groups,
        itemsFull: groups,
        loadingGroups: false
      })
    }).catch((error: any) => {
      console.log(error)
    })
  }

  private _addGroup0 = () => {
    var showDialog = true;
    var possibleUsers: any = [];
    var name: string = "";
    var selUsers:any = [];

    function getGroupPossibleUsers() {
      userService.getRepoUsers('INTERNAL').then((response: any) => {
        if(response.data.length > 0) {
          possibleUsers = response.data.map((item: any) => {
            return({
              key: item.id,
              text: item.name + " "+ item.surname + ", " + item.email
            })
          });

          renderDialog();
        }
      }).catch(error => {
        console.log(error)
      })
    }
    getGroupPossibleUsers();

    var createGroup = () => {
      var usersId = selUsers.map((user: any) => {
        return user.key;
      })

      var description = '';

      userService.createGroup(name, description, usersId)
        .then((response: any) => {
          showDialog = false;
          renderDialog();
          this._getGroups()
        }).catch(error => {
          console.log(error)
          showDialog = false;
          renderDialog();
        })
    }

    var handleNameChange = (ev: any, newValue?: string) => {
      name = newValue || ""
      renderDialog()
    }

    var addUser = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number) => {
      if(option) {
        selUsers.push(option)
        renderDialog();
      }
    }

    var removelUser = (user: any) => {
      selUsers = selUsers.filter((selUser: any) => {
        return selUser.key !== user.key
      })
      renderDialog();
    }

    var div = document.createElement('div');
    var renderDialog = () => {

      ReactDOM.render(
        <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: <div>{i18n.t('groups:createGroup')}</div>
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "add-group-dialog"
            }}
            onDismiss={() => {showDialog = false; renderDialog()}}
          >

          <div className="dialog-content-wrap">
            
                { possibleUsers.length > 0 ?
                  <>
                  <TextField autoFocus label={i18n.t('groups:groupName')} placeholder={i18n.t('groups:groupName')} name="newItemName" value={ name } onChange={ handleNameChange } required />

                  <ComboBox
                    label={i18n.t('groups:invitePeople')}
                    key={'shareSuggestions'}
                    allowFreeform={true}
                    autoComplete={'on'}
                    options={possibleUsers}
                    onChange={ addUser }
                    shouldRestoreFocus={true}
                    className="my-2"
                    scrollSelectedToTop={true}
                    placeholder={i18n.t('groups:choose')}
                    required
                  />
                  <ul className="my-3 mx-0 p-0">
                    {selUsers.map((selUser: any) =>
                      <li key={selUser.key} className="selUser p-0 mx-0 my-2">
                        {selUser.text} <Icon iconName="Cancel" className="text-danger align-bottom ml-2" onClick={ () => removelUser(selUser)} />
                      </li>
                    )}
                  </ul>
                  </>
                :
                  <p>{i18n.t('groups:notAvailable')}</p>
                }
                <DialogFooter className="mt-4">
                  <DefaultButton onClick={() => {showDialog = false; renderDialog()}} text={i18n.t('groups:cancel')} />
                  { possibleUsers.length > 0 ?
                    <PrimaryButton onClick={() => createGroup()} text={i18n.t('groups:save')} disabled={selUsers.length === 0 || !name.length} />
                  :
                    null
                  }
                </DialogFooter>
            </div>

          </Dialog>
        , div
      )
    }

    renderDialog();
  }

  private _addGuestGroup0 = () => {
    var showDialog = true;
    var possibleUsers: any = [];
    var name: string = "";
    var selUsers:any = [];

    function getGroupPossibleUsers() {
      userService.getRepoUsers('GUEST').then((response: any) => {
        if(response.data.length > 0) {
          possibleUsers = response.data.map((item: any) => {
            return({
              key: item.id,
              text: item.name + " "+ item.surname + ", " + item.email
            })
          });

          renderDialog();
        }
      }).catch(error => {
        console.log(error)
      })
    }
    getGroupPossibleUsers();

    var createGroup = () => {
      var usersId = selUsers.map((user: any) => {
        return user.key;
      })

      var description = '';

      userService.createGroup(name, description, usersId, 'GUEST')
        .then((response: any) => {
          showDialog = false;
          renderDialog();
          this._getGroups()
        }).catch(error => {
          console.log(error)
          showDialog = false;
          renderDialog();
        })
    }

    var handleNameChange = (ev: any, newValue?: string) => {
      name = newValue || ""
      renderDialog()
    }

    var addUser = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number) => {
      if(option) {
        selUsers.push(option)
        renderDialog();
      }
    }

    var removelUser = (user: any) => {
      selUsers = selUsers.filter((selUser: any) => {
        return selUser.key !== user.key
      })
      renderDialog();
    }

    var div = document.createElement('div');
    var renderDialog = () => {

      ReactDOM.render(
        <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: <div>{i18n.t('groups:createGroup')}</div>
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "add-group-dialog"
            }}
            onDismiss={() => {showDialog = false; renderDialog()}}
          >

          <div className="dialog-content-wrap">
            
                { possibleUsers.length > 0 ?
                  <>
                  <TextField autoFocus label={i18n.t('groups:groupName')} placeholder={i18n.t('groups:groupName')} name="newItemName" value={ name } onChange={ handleNameChange } required />

                  <ComboBox
                    label={i18n.t('groups:invitePeople')}
                    key={'shareSuggestions'}
                    allowFreeform={true}
                    autoComplete={'on'}
                    options={possibleUsers}
                    onChange={ addUser }
                    shouldRestoreFocus={true}
                    className="my-2"
                    scrollSelectedToTop={true}
                    placeholder={i18n.t('groups:choose')}
                    required
                  />
                  <ul className="my-3 mx-0 p-0">
                    {selUsers.map((selUser: any) =>
                      <li key={selUser.key} className="selUser p-0 mx-0 my-2">
                        {selUser.text} <Icon iconName="Cancel" className="text-danger align-bottom ml-2" onClick={ () => removelUser(selUser)} />
                      </li>
                    )}
                  </ul>
                  </>
                :
                  <p>{i18n.t('groups:notAvailable')}</p>
                }
                <DialogFooter className="mt-4">
                  <DefaultButton onClick={() => {showDialog = false; renderDialog()}} text={i18n.t('groups:cancel')} />
                  { possibleUsers.length > 0 ?
                    <PrimaryButton onClick={() => createGroup()} text={i18n.t('groups:save')} disabled={selUsers.length === 0 || !name.length} />
                  :
                    null
                  }
                </DialogFooter>
            </div>

          </Dialog>
        , div
      )
    }

    renderDialog();
  }

  private _editGroup0 = () => {
    var selGroup: any = this._selection.getSelection()[0]
    var showDialog = true;
    var possibleUsers: any = [];
    var name: string = selGroup.name;
    var selUsers:any = [];
    let userString = '';
    for (let i in selGroup.users) {
      userString += ('&user='+selGroup.users[i])
    }
    userString !== '' && userService.getRepoUsers('ALL', userString).then((response)=>{
      selUsers = response.data.map((item: any) => {
        return({
          key: item.id,
          text: item.name + " "+ item.surname + ", " + item.email
        })
      });
      renderDialog();
    })

    function getGroupPossibleUsers() {
      userService.getGroupPossibleUsers(selGroup.id).then((response: any) => {
        if(response.data.length > 0) {
          possibleUsers = response.data.map((item: any) => {
            return({
              key: item.id,
              text: item.name + " "+ item.surname + ", " + item.email
            })
          });

          renderDialog();
        }
      }).catch(error => {
        console.log(error)
      })
    }
    getGroupPossibleUsers();

    var updateGroup = () => {
      var usersId:string[] = selUsers.map((item)=>{
        return item.key
      });

      var description = '';

      userService.editGroup(selGroup.id, name, description, usersId)
        .then((response: any) => {
          showDialog = false;
          renderDialog();
          this._getGroups()
        }).catch(error => {
          console.log(error)
          showDialog = false;
          renderDialog();
        })
    }

    var handleNameChange = (ev: any, newValue?: string) => {
      name = newValue || ""
      renderDialog()
    }

    var addUser = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number) => {
      if(option) {
        selUsers.push(option)
        renderDialog();
      }
    }

    var removelUser = (user: any) => {
      selUsers = selUsers.filter((selUser: any) => {
        return selUser.key !== user.key
      })
      renderDialog();
    }

    var div = document.createElement('div');
    var renderDialog = () => {

      ReactDOM.render(
        <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: <div>{i18n.t('groups:editGroup')}</div>
            }}
            modalProps={{
              isBlocking: false,
              styles: { main: { maxWidth: 640 } },
              dragOptions: undefined,
              className: "add-group-dialog"
            }}
            onDismiss={() => {showDialog = false; renderDialog()}}
          >

          <div className="dialog-content-wrap">
            
                { possibleUsers.length > 0 ?
                  <>
                  <TextField autoFocus label={i18n.t('groups:groupName')}  placeholder={i18n.t('groups:groupName')} name="newItemName" value={ name } onChange={ handleNameChange } required />

                  <ComboBox
                    label={i18n.t('groups:invitePeople')}
                    key={'shareSuggestions'}
                    allowFreeform={true}
                    autoComplete={'on'}
                    options={possibleUsers}
                    onChange={ addUser }
                    shouldRestoreFocus={true}
                    className="my-2"
                    scrollSelectedToTop={true}
                    placeholder={i18n.t('groups:choose')}
                  />
                  <ul className="my-3 mx-0 p-0">
                    {selUsers.map((selUser: any) =>
                      <li key={selUser.key} className="selUser p-0 mx-0 my-2">
                        {selUser.text} <Icon iconName="Cancel" className="text-danger align-bottom ml-2" onClick={ () => removelUser(selUser)} />
                      </li>
                    )}
                  </ul>
                  </>
                :
                  <p>{i18n.t('groups:notAvailable')}</p>
                }
                <DialogFooter className="mt-4">
                  <DefaultButton onClick={() => {showDialog = false; renderDialog()}} text={i18n.t('groups:cancel')} />
                  { possibleUsers.length > 0 ?
                    <PrimaryButton onClick={() => updateGroup()} text={i18n.t('groups:save')} disabled={selUsers.length === 0 || !name.length} />
                  :
                    null
                  }
                </DialogFooter>
            </div>

          </Dialog>
        , div
      )
    }

    renderDialog();
  }

  private _deleteGroup = () => {
    var selGroup: any = this._selection.getSelection()[0]

    userService.deleteGroup(selGroup.id).then(response => {
      this._getGroups()
    }).catch(error => {
      console.log(error)
    })
  }

  private _addGroup = () => {
    var showDialog = true;

    var div = document.createElement('div');
    var self = this;

    function callback() {
      showDialog = false;
      renderDialog();
      self._getGroups();
    }

    var renderDialog = () => {
      ReactDOM.render(
        <FormCreateGroups showDialog={showDialog} userData={this.props.userData} callback={callback} action='add' userType='INTERNAL' currentUsers={[]} name='' description=''/>
        , div
      )
    }

    renderDialog();
  }

  private _addGuestGroup = () => {
    var showDialog = true;

    var div = document.createElement('div');
    var self = this;

    function callback() {
      showDialog = false;
      renderDialog();
      self._getGroups();
    }

    var renderDialog = () => {
      ReactDOM.render(
        <FormCreateGroups showDialog={showDialog} userData={this.props.userData} callback={callback} action='add' userType='GUEST' currentUsers={[]} name='' description=''/>
        , div
      )
    }

    renderDialog();
  }

  private _editGroup = () => {
    var showDialog = true;

    var div = document.createElement('div');
    var self = this;

    let selGroup = this.state.selItems;
    let userString = '';
    var selUsers = [];

    for (let i in selGroup.users) {
      userString += ('&user='+selGroup.users[i])
    }
    userString !== '' && userService.getRepoUsers(selGroup.role, userString).then((response)=>{
      selUsers = response.data
      renderDialog();
    })

    function callback() {
      showDialog = false;
      renderDialog();
      self._getGroups();
    }

    var renderDialog = () => {
      ReactDOM.render(
        <FormCreateGroups showDialog={showDialog} userData={this.props.userData} callback={callback} action='edit' userType={this.state.selItems.role} currentUsers={selUsers} id={this.state.selItems.id} name={selGroup.name} description={selGroup.description}/>
        , div
      )
    }
  }
  
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}