import * as React from 'react';
import { history } from '../_helpers';
import { Fabric, TooltipHost } from '@fluentui/react';
import { Resizable } from 're-resizable';
import { Nav, INavLink, INavLinkGroup } from '@fluentui/react';
import { IconButton, DatePicker, DayOfWeek, IDatePickerStrings } from '@fluentui/react';
import { ComboBox, IComboBox, IComboBoxOption,} from '@fluentui/react';
import {createSelector} from 'reselect';

import i18n from "i18next";

import { userService } from '../_services';
import { ContextualMenu, ContextualMenuItemType, Icon, IContextualMenuItem, Persona, PersonaSize } from '@fluentui/react';
import Tree from 'react-virtualized-tree';
import { MailInbox20Regular, ChatMultiple20Regular, Chat20Regular, FolderSync20Regular, Send20Regular, PersonChat20Regular } from '@fluentui/react-icons'

export const getNodeRenderOptions = createSelector(
  (node: any) => (node.state || {}).expanded,
  node => (node.state || {}).favorite,
  node => (node.state || {}).deletable,
  node => node.hasChildren,
  node => node.id,
  node => node.type,
  node => node.hidden,
  (expanded, favorite, deletable, hasChildren, id, type, hidden = []) => ({
    hasChildren: !!hasChildren,
    isExpanded: !!expanded,
    isFavorite: !!favorite,
    isDeletable: !!deletable,
    id: id,
    type: type,
    hidden: !!hidden
  }),
);

export const updateNode = (originalNode, newState: any) =>
  ({
  
  node: {
    ...originalNode,
    state: {
      ...originalNode.state,
      ...newState,
    },
  },
  type: "2" //UPDATE
});

export interface Props {
  match: {
    url: string
  },
  location: any,
  searchType: string
}

export interface SidebarState {
  userData?: any;
  repoData?: any;
  activityTree: INavLinkGroup[];
  companyUsers: any;
  selFolder: any;
  selUser: string;
  selAction: string;
  minDate: Date;
  maxDate: Date;
  initialDate: Date;
  finalDate: Date;
  panelHeight: any;
  panelWidth: any;
  selNav: string;
  goBack: number;
  settingsMenu: boolean;
  personaMenu: boolean;
  userName: string;
}

export class Sidebar extends React.Component<Props, SidebarState> {
  private _isMounted: boolean;
  private userDataSubscription: any = null;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    var panelWidth = "240px"
    if (typeof(localStorage.getItem("sidebarPrefs")) === "string") {
      var panelPrefs = JSON.parse(localStorage.getItem("sidebarPrefs") || "[]");
      panelWidth = panelPrefs.width
    }

    this.state = {
      userData: null,
      repoData: null,
      goBack: 0,
      activityTree: [],
      companyUsers: [],
      selFolder: {},
      selUser: "allUsers",
      selAction: "allActions",
      minDate: new Date(2020,0,1),
      maxDate: new Date(Date.now()),
      initialDate: new Date(2020,0,1),
      finalDate: new Date(Date.now()),
      panelHeight: 'auto',
      panelWidth: panelWidth,
      selNav: this._getSelNav(),
      settingsMenu: false,
      personaMenu: false,
      userName: ''
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    const { selNav } = this.state;
    
    this._isMounted && this.props.userData && this._getRepoData();
      
    if(selNav === "activity" && this.props.userData) {
      this._getActivityTree();
      this._getRepoUsers();
      this._getActivitySelFilters();
    }
    else if(selNav === "sharing" && this.props.userData) {
      this._getSharingTree();
      this._getRepoUsers();
      this._getSharingSelFilters();
    }
    else if(selNav === "sessions" && this.props.userData) {
      this._getActivityTree();
      this._getRepoUsers();
      this._getActivitySelFilters();
    }
    else if(selNav === "approvals" && this.props.userData) {
      this._getApprovalTree();
      this._getRepoUsers();
      this._getActivitySelFilters();
    }

    // Custom titlebar content height 100vh fix
    var titlebar : HTMLDivElement | null  = document.querySelector('.titlebar');
    if(titlebar instanceof HTMLDivElement) {
      var titlebarHeight = titlebar.offsetHeight;
      var toolbar : HTMLDivElement | null  = document.querySelector('.toolbar');
      if(toolbar instanceof HTMLDivElement) {
        var toolbarHeight = toolbar.offsetHeight;
        var mainNavTitlebarHeightFix : HTMLDivElement | null = document.querySelector('#main-nav');
        if(mainNavTitlebarHeightFix instanceof HTMLDivElement) {
          mainNavTitlebarHeightFix.style.height = 'calc(100vh - ' + toolbarHeight + 'px - ' + titlebarHeight + 'px)';
        }
      }
    }

    if (window.location.href.indexOf("/settings") !== -1 && document.getElementById('settings-button') ) {
      document.getElementById('settings-button')!.style.background = '#F2F2F2'
      if (!document.getElementById('settings-button')!.className.includes(' selected')) document.getElementById('settings-button')!.className += ' selected'
    }
  }

  public componentDidUpdate(prevProps: any) {
    if(this.props.userData !== prevProps.userData && this.props.userData) {
      this._getRepoData();

      const { selNav } = this.state;
      if(selNav === "activity" && this.props.userData) {
        this._getActivityTree();
        this._getRepoUsers();
        this._getActivitySelFilters();
      }
      else if(selNav === "sharing" && this.props.userData) {
        this._getSharingTree();
        this._getRepoUsers();
        this._getSharingSelFilters();
      }
      else if(selNav === "sessions" && this.props.userData) {
        this._getActivityTree();
        this._getRepoUsers();
        this._getActivitySelFilters();
      }
      else if(selNav === "approvals" && this.props.userData) {
        this._getApprovalTree();
        this._getRepoUsers();
        //this._getActivitySelFilters();
      }
    }
        
    if(this.props.searchQuery !== prevProps.searchQuery) {
      this._getActivitySelFilters();
    }

    if (window.location.href.indexOf("/settings") !== -1 && document.getElementById('settings-button') ) {
      document.getElementById('settings-button')!.style.background = '#F2F2F2'
      if (!document.getElementById('settings-button')!.className.includes(' selected')) document.getElementById('settings-button')!.className += ' selected'
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  public render() {
    const { selNav, activityTree, selAction, minDate, maxDate, initialDate, finalDate } = this.state;
    const self = this;
    function toggleSettingsMenu(){
      self._isMounted && self.setState({settingsMenu: !self.state.settingsMenu})
    }
    function togglePersonaMenu(){
      self._isMounted && self.setState({personaMenu: !self.state.personaMenu})
    }

    const actions: any[] = i18n.t('actions:actions',{ returnObjects: true })

    const sortedActions = actions.sort((a:any,b:any) => {
      if(a.text < b.text)
          return -1;
      if(b.text < a.text)
          return 1;

      return 0;
    });

    const DayPickerStrings: IDatePickerStrings = {
      months: i18n.t('sidebar:months', { returnObjects: true }),
    
      shortMonths: i18n.t('sidebar:shortMonths', { returnObjects: true }),
    
      days: i18n.t('sidebar:days', { returnObjects: true }),
    
      shortDays: i18n.t('sidebar:shortDays', { returnObjects: true }),
    
      goToToday:  i18n.t('sidebar:goToToday'),
      prevMonthAriaLabel: i18n.t('sidebar:prevMonthAriaLabel'),
      nextMonthAriaLabel: i18n.t('sidebar:nextMonthAriaLabel'),
      prevYearAriaLabel: i18n.t('sidebar:prevYearAriaLabel'),
      nextYearAriaLabel: i18n.t('sidebar:nextYearAriaLabel'),
      closeButtonAriaLabel: i18n.t('sidebar:closeButtonAriaLabel'),
      monthPickerHeaderAriaLabel: i18n.t('sidebar:monthPickerHeaderAriaLabel'),
      yearPickerHeaderAriaLabel: i18n.t('sidebar:yearPickerHeaderAriaLabel'),
    };

    const settingsItems: IContextualMenuItem[] = [
      /*{
        key: 'webUpdateAvailable',
        text: i18n.t('app:applyUpdateNow'),
        iconProps: { iconName: 'Important' },
        onClick: () => {this._applyWebUpdate()},
        className: 'important',
        hidden: !this.state.webUpdateAvailable
      },*/
      {
        key: 'about',
        text: i18n.t('sidebar:aboutSynergyDrive'),
        iconProps: { iconName: 'Info' },
        //onClick: () => {this._getAppInfo()},
      },
      /*{
        key: 'language',
        text: i18n.t('app:language'),
        iconProps: { iconName: 'LocaleLanguage' },
        subMenuProps: {
          items: [
            {
              key: 'de',
              text: 'Deutsch',
              onClick: () => {i18n.changeLanguage('de')},
            },
            {
              key: 'en',
              text: 'English',
              onClick: () => {i18n.changeLanguage('en')},
            },
            {
              key: 'es',
              text: 'Español',
              onClick: () => {i18n.changeLanguage('es')},
            },
          ],
        },
        id: 'language-button'
      }*/
      {
        key: 'settings',
        text: i18n.t('sidebar:settings'),
        iconProps: { iconName: 'Settings' },
        onClick: this._goToSettingsPage.bind(this)
      },
    ];

    const personaItems: IContextualMenuItem[] = [
      {
        itemType: ContextualMenuItemType.Header,
        key: 'userName',
        text: this.state.userName,
        className: "userName"
      },
      {
        itemType: ContextualMenuItemType.Header,
        key: 'userEmail',
        text: this.props.userData && this.props.userData.user.email,
        className: "userEmail"
      },
      {
        key: 'userSettings',
        text: i18n.t('sidebar:account'),
        iconProps: { iconName: 'Contact' },
        onClick: () => {
          const domain = process.env.NODE_ENV === 'production' ? window.location.hostname.split('.').slice(1).join('.') : process.env.REACT_APP_DOMAIN;
          var url = 'https://account.' + domain
          window.open(url, "_blank");
        },
      },
      {
        key: 'signOut',
        text: i18n.t('sidebar:signOut'),
        iconProps: { iconName: 'signOut' },
        onClick: () => userService.logout(),
      }
    ];

    const treeLine = (style,index,node,isExpanded,hasChildren,handleChange) => {
      let selected = false;
      let subpage = this.props.subpage;
      if (node.type === subpage || node.type === 'taskList') selected = true

      return (
        <>
          <li className={"ms-Nav-navItem " + (selected ? 'bg-selected' : '')} style={style} tabIndex={index} onClick={(ev) => { (node.type !== 'category' && !node.type.includes('tag')) && this._goToPage(ev,node); if(!isExpanded) handleChange()}}>
            <div className={ "ms-Nav-compositeLink"} >
          
              {hasChildren && <button className="chevronButton">
                { hasChildren && !isExpanded?
                  <Icon iconName="ChevronRight"  onClick={(e) => {e.stopPropagation(); handleChange();
                  }} className="ms-Nav-chevron d-inline-block" />
                : hasChildren && isExpanded?
                  <Icon iconName="ChevronDown"  onClick={(e) => {e.stopPropagation();handleChange()}} className="ms-Nav-chevron d-inline-block" />
                :
                  <div className="noChevron"></div>
                }
              </button>}
              
              <button className="ms-Button ms-Button--action ms-Button--command ms-Nav-link ps-0">
                { this._onRenderLink(node) }
              </button>
              {node.type === 'view-custom' && this.checkSelected() &&
                <TooltipHost key='view-custom-tt' styles={{root:{position:'fixed', right: 0, marginRight:'10px',  height: '24px'}}} content={'Save custom view'} setAriaDescribedBy={false}>
                  <button className='custom-view ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Save'/></button>
                </TooltipHost>
              }
              {node.type === 'category' &&
                <TooltipHost key={'category-tt-edit-'+node.id} styles={{root:{position:'fixed', right: 0, marginRight:'36px',  height: '24px'}}} content={'Edit'} setAriaDescribedBy={false}>
                  <button onClick={(ev)=>{this._goToPage(ev,node)}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Edit'/></button>
                </TooltipHost>
              }
              {node.type === 'category' &&
                <TooltipHost key={'category-tt-remove-'+node.id} styles={{root:{position:'fixed', right: 0, marginRight:'10px',  height: '24px'}}} content={'Remove'} setAriaDescribedBy={false}>
                  <button onClick={(ev)=>{this.removeFilterCategory(node.field)}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Delete'/></button>
                </TooltipHost>
              }
              {node.type === 'filtersHeader' &&
                <TooltipHost key={'filtersHeader-tt-remove-'+node.id} styles={{root:{position:'fixed', right: 0, marginRight:'10px',  height: '24px'}}} content={'Remove'} setAriaDescribedBy={false}>
                  <button onClick={(ev)=>{history.push('')}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Delete'/></button>
                </TooltipHost>
              }
              {node.type.includes('tag') &&
                <TooltipHost key={'tag-tt-edit-'+node.id} styles={{root:{position:'fixed', right: 0, marginRight:'36px',  height: '24px'}}} content={'Edit'} setAriaDescribedBy={false}>
                  <button onClick={(ev)=>{this._goToPage(ev,node)}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Edit'/></button>
                </TooltipHost>
              }
              {node.type.includes('tag') &&
                <TooltipHost key={'tag-tt-remove-'+node.id} styles={{root:{position:'fixed', right: 0, marginRight:'10px',  height: '24px'}}} content={'Remove'} setAriaDescribedBy={false}>
                  <button onClick={(ev)=>{this.removeFilter(node)}} className='filter-category ms-Button ms-Button--action ms-Button--command ms-Nav-link'><Icon style={{height: '20px', top: '-2px', position: 'relative'}} iconName='Delete'/></button>
                </TooltipHost>
              }
            </div>
          </li>
        </>
      )
    }

    const itemTree = [
      {
        key: "allMessages-",
        id: "allMessages-",
        name: i18n.t('app:all'),
        isExpanded: false,
        state: {expanded: false},
        hasChildren: true,
        type: "allMessages",
      },
      {
        key: "messages-",
        id: "messages-",
        name: i18n.t('app:dms'),
        isExpanded: false,
        state: {expanded: false},
        hasChildren: true,
        type: "messages",
      },
      {
        key: "conversations-",
        id: "conversations-",
        name: i18n.t('app:conversations'),
        isExpanded: false,
        state: {expanded: false},
        hasChildren: true,
        type: "conversations",
      },
      {
        key: "filesync-",
        id: "filesync-",
        name: i18n.t('app:filesync'),
        isExpanded: true,
        state: {expanded: true},
        hasChildren: true,
        type: "filesync",
        children: [
          {
            key: "filesyncReceived-",
            id: "filesyncReceived-",
            name: i18n.t('app:received'),
            isExpanded: false,
            state: {expanded: false},
            hasChildren: false,
            type: "filesyncReceived",
          },
          {
            key: "filesyncSent-",
            id: "filesyncSent-",
            name: i18n.t('app:sent'),
            isExpanded: false,
            state: {expanded: false},
            hasChildren: false,
            type: "filesyncSent",
          },
        ]
      },
      {
        key: "connectInvites-",
        id: "connectInvites-",
        name: i18n.t('app:connectInvites'),
        isExpanded: true,
        state: {expanded: true},
        hasChildren: true,
        type: "connectInvites",
        children: [
          {
            key: "connectInvitesReceived-",
            id: "connectInvitesReceived-",
            name: i18n.t('app:received'),
            isExpanded: false,
            state: {expanded: false},
            hasChildren: false,
            type: "connectInvitesReceived",
          },
          {
            key: "connectInvitesSent-",
            id: "connectInvitesSent-",
            name: i18n.t('app:sent'),
            isExpanded: false,
            state: {expanded: false},
            hasChildren: false,
            type: "connectInvitesSent",
          },
        ]
      },
    ]

    return(
      <Fabric>
        <div id="main-nav" className=" d-flex flex-row">
          <nav className="main">
            <Nav
              expandButtonAriaLabel="Expand or collapse"
              ariaLabel="Main navigation"
              initialSelectedKey={ selNav }
              styles={{
                root: {
                  width: 48,
                  boxSizing: 'border-box',
                  overflowY: 'auto'
                }
              }}
              groups={[
                {
                  links: [
                    {
                        name: '',
                        url: '',
                        icon: 'Home',
                        key: 'home',
                        ariaLabel: 'Home',
                        title: i18n.t('sidebar:home'),
                        onClick: this._goToHome.bind(this)
                    },
                    {
                        name: '',
                        url: '',
                        icon: 'Contact',
                        key: 'users',
                        ariaLabel: 'Users',
                        title: i18n.t('sidebar:users'),
                        onClick: this._goToUsers.bind(this)
                    },
                    {
                        name: '',
                        url: '',
                        icon: 'Group',
                        key: 'groups',
                        ariaLabel: 'Groups',
                        title: i18n.t('sidebar:groups'),
                        onClick: this._goToGroups.bind(this)
                    },
                    {
                      name: '',
                      url: '',
                      icon: 'CheckboxComposite',
                      key: 'approvals',
                      ariaLabel: 'Approvals',
                      title: i18n.t('sidebar:approvals'),
                      onClick: this._goToApprovals.bind(this)
                    },
                    {
                      name: '',
                      url: '',
                      icon: 'Share',
                      key: 'sharing',
                      ariaLabel: 'Sharing',
                      title: i18n.t('sidebar:sharing'),
                      onClick: this._goToSharing.bind(this)
                    },
                    {
                      name: '',
                      url: '',
                      icon: 'LineChart',
                      key: 'activity',
                      ariaLabel: 'Activity',
                      title: i18n.t('sidebar:activity'),
                      onClick: this._goToActivity.bind(this)
                    },
                    {
                      name: '',
                      url: '',
                      icon: 'Chat',
                      key: 'conversations',
                      onClick: this._goToConversations.bind(this),
                      ariaLabel: 'Conversations',
                      title: i18n.t('app:conversations'),
                      //disabled: !this.props.isOnline || this.props.maintenance
                    },
                    {
                      name: '',
                      url: '',
                      icon: 'Devices3',
                      key: 'sessions',
                      ariaLabel: 'Sessions',
                      title: i18n.t('sidebar:sessions'),
                      onClick: this._goToSessions.bind(this)
                    },
                    {
                      name: '',
                      url: '',
                      icon: 'Repair',
                      key: 'policies',
                      ariaLabel: 'Policies',
                      title: i18n.t('sidebar:policies'),
                      onClick: this._goToPolicies.bind(this)
                    }
                    /*{
                        name: '',
                        url: '',
                        icon: 'AccountManagement',
                        key: 'roles',
                        ariaLabel: 'Roles',
                        title: i18n.t('sidebar:roles'),
                        onClick: this._goToRoles.bind(this)
                    },
                    {
                        name: '',
                        url: '',
                        icon: 'PaymentCard',
                        key: 'billing',
                        ariaLabel: 'Billing',
                        title: i18n.t('sidebar:billing'),
                        onClick: this._goToBilling.bind(this)
                    },*/
                  ]
                }
              ]}
            />
            <div className="fixed-bottom">
              <IconButton
                iconProps={{ iconName: 'Help' }}
                styles={{
                  root: {
                    width: 48,
                    boxSizing: 'border-box',
                    overflowY: 'auto'
                  }
                }}
                title={i18n.t('sidebar:help')}
                ariaLabel="Help"
                onClick={ () => {
                  const domain = localStorage.getItem('serverdomain')
                  const domainApi = 'synergy-api.' + domain;
                  const token = localStorage.getItem('user')
                  window.open("https://synergyos.com/support?chatwoot=on&domain="+domainApi+"&token="+token, '_blank')
                } }
              />
              <div className="settings-wrap">
                <IconButton
                  id="settings-button"
                  iconProps={{ iconName: 'Settings' }}
                  styles={{
                    root: {
                      width: 48,
                      boxSizing: 'border-box',
                      overflowY: 'auto'
                    }
                  }}
                  title={i18n.t('sidebar:settings')}
                  ariaLabel="Settings"
                  onClick={toggleSettingsMenu}
                />
              </div>
              <ContextualMenu
                items={settingsItems}
                hidden={!this.state.settingsMenu}
                target={`#${"settings-button"}`}
                onItemClick={()=> this._isMounted && this.setState({settingsMenu: true})}
                onDismiss={()=> this._isMounted && this.setState({settingsMenu: false})}
                className="contextualSettingsMenu"
              />
              <div className="persona-wrap">
                <Persona 
                  id="persona-button" 
                  styles={{
                    root: {
                      width: 48,
                      height: 48,
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      padding: '0 8px'
                    }
                  }}
                  text={this.state.userName} 
                  size={PersonaSize.size32} 
                  hidePersonaDetails={true}
                  className="persona"
                  onClick={togglePersonaMenu}
                  initialsColor="#7F7F7F"  
                />
              </div>
              <ContextualMenu
                items={personaItems}
                hidden={!this.state.personaMenu}
                target={`#${"persona-button"}`}
                onItemClick={()=> this._isMounted && this.setState({personaMenu: true})}
                onDismiss={()=> this._isMounted && this.setState({personaMenu: false})}
                className="contextualUserMenu"
              />
            </div>
          </nav>
          { selNav === "home" ?
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree">
                <p className="title mb-0">Home</p>
                <Nav
                  styles={{
                    root: {
                      width: "fit-content",
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }
                  }}
                  className="fileExplorer"
                  initialSelectedKey="toHome"
                  expandButtonAriaLabel="Expand or collapse"
                  //ariaLabel="Nav example with nested links"
                  groups={ i18n.t('home:nav', { returnObjects: true }) }
                  onRenderLink={ this._onRenderLink }
                  onLinkClick={ (e,item:any) => history.push(item.page)}
                />
              </nav>
            </Resizable>
          : null }
          { selNav === "users" ?
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree">
                <p className="title mb-0">Users</p>
                <Nav
                  styles={{
                    root: {
                      width: "fit-content",
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }
                  }}
                  className="fileExplorer"
                  //initialSelectedKey={this.props.page === 'import' ? 'import' : 'all'}
                  selectedKey={this.props.page}
                  expandButtonAriaLabel="Expand or collapse"
                  ariaLabel="Nav example with nested links"
                  groups={ i18n.t('users:nav', { returnObjects: true }) }
                  onRenderLink={ this._onRenderLink }
                  onLinkClick={ (e,item:any) => {this.props.callback({usersNav:item.key}); history.push(item.page)} }
                />
              </nav>
            </Resizable>
          : null }

          { selNav === "groups" ?
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree">
                <p className="title mb-0">Groups</p>
                <Nav
                  styles={{
                    root: {
                      width: "fit-content",
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }
                  }}
                  className="fileExplorer"
                  initialSelectedKey={ "all" }
                  expandButtonAriaLabel="Expand or collapse"
                  ariaLabel="Nav example with nested links"
                  groups={ i18n.t('groups:nav', { returnObjects: true }) }
                  onRenderLink={ this._onRenderLink }
                  onLinkClick={ () => {} }
                />
              </nav>
            </Resizable>
          : null }

          { selNav === "activity" ?
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree">
                <p className="title mb-0">Activity</p>
                <Nav
                  styles={{
                    root: {
                      width: "fit-content",
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }
                  }}
                  className="usersFilter"
                  initialSelectedKey={window.location.search && window.location.search !== "" ? "" : "allUsers"}
                  expandButtonAriaLabel="Expand or collapse"
                  ariaLabel="Nav example with nested links"
                  groups={activityTree}
                  onRenderLink={ this._onRenderLink }
                  onLinkClick={ this._filterUser.bind(this) }
                />
                <ComboBox
                  key={'actionsFilter'}
                  allowFreeform={true}
                  autoComplete={'on'}
                  options={sortedActions}
                  shouldRestoreFocus={true}
                  className="m-3"
                  scrollSelectedToTop={true}
                  placeholder="Select an action"
                  onChange={ this._filterAction }
                  selectedKey={selAction}
                />

                <DatePicker
                  className="m-3"
                  firstDayOfWeek={DayOfWeek.Sunday}
                  strings={DayPickerStrings}
                  placeholder="Select start date..."
                  ariaLabel="Select start date"
                  allowTextInput={true}
                  minDate={minDate}
                  maxDate={maxDate}
                  initialPickerDate={initialDate}
                  value={initialDate}
                  onSelectDate={this._oninitialDateSelect}
                />

                <DatePicker
                  className="m-3"
                  firstDayOfWeek={DayOfWeek.Sunday}
                  strings={DayPickerStrings}
                  placeholder="Select end date..."
                  ariaLabel="Select end date"
                  allowTextInput={true}
                  minDate={initialDate}
                  maxDate={maxDate}
                  initialPickerDate={finalDate}
                  value={finalDate}
                  onSelectDate={this._onfinalDateSelect}
                />
              </nav>
            </Resizable>
          : selNav === "sharing" ?
          <Resizable
            size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
            onResizeStop={(e, direction, ref, d) => {
              var currentWidth = this.state.panelWidth.match(/\d+/)[0];
              this.setState({
                panelWidth: +currentWidth + +d.width + "px"
              });
              this._saveResizeState();
            }}
            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
            minWidth={240}
            maxWidth={480}
            handleStyles= {{
              right: {
                "right": "-6px",
                "width": "6px",
                "zIndex": 1
              }
            }}
            handleClasses={{"right": "resize-handle"}}
          >
            <nav className="tree">
              <p className="title mb-0">Sharing</p>
              <Nav
                styles={{
                  root: {
                    width: "fit-content",
                    boxSizing: 'border-box',
                    overflowY: 'auto',
                    overflowX: 'auto'
                  }
                }}
                className="usersFilter"
                initialSelectedKey={window.location.search && window.location.search !== "" ? "" : "allUsers"}
                expandButtonAriaLabel="Expand or collapse"
                ariaLabel="Nav example with nested links"
                groups={activityTree}
                onRenderLink={ this._onRenderLink }
                onLinkClick={ this._filterUser.bind(this) }
              />

              <DatePicker
                className="m-3"
                firstDayOfWeek={DayOfWeek.Sunday}
                strings={DayPickerStrings}
                placeholder="Select start date..."
                ariaLabel="Select start date"
                allowTextInput={true}
                minDate={minDate}
                maxDate={maxDate}
                initialPickerDate={initialDate}
                value={initialDate}
                onSelectDate={this._oninitialDateSelect}
              />

              <DatePicker
                className="m-3"
                firstDayOfWeek={DayOfWeek.Sunday}
                strings={DayPickerStrings}
                placeholder="Select end date..."
                ariaLabel="Select end date"
                allowTextInput={true}
                minDate={initialDate}
                maxDate={maxDate}
                initialPickerDate={finalDate}
                value={finalDate}
                onSelectDate={this._onfinalDateSelect}
              />
            </nav>
          </Resizable>
          : selNav === "conversations" ?
          <>
          <Resizable
            size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
            onResizeStop={(e, direction, ref, d) => {
              var currentWidth = this.state.panelWidth.match(/\d+/)[0];
              this._isMounted && this.setState({
                panelWidth: +currentWidth + +d.width + "px"
              });
              this._saveResizeState();
            }}
            enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
            minWidth={240}
            maxWidth={480}
            handleStyles= {{
              right: {
                "right": "-6px",
                "width": "6px",
                "zIndex": 1
              }
            }}
            handleClasses={{"right": "resize-handle"}}
          >
            <nav className="tree-virtual">
              <p className="title mb-0">{i18n.t('app:conversations')}</p>
              <ul className="ms-Nav-navItems list-page h-100 m-0 ps-0">
                <Tree
                  nodes={itemTree}
                  onChange={this.handleNodesChange}>
                  {({onChange, style, node, index, ...rest}) => {

                    const {hasChildren, isExpanded} = getNodeRenderOptions(node);
                    const handleChange = () =>{
                      onChange({...updateNode(node, {expanded: !isExpanded}) })
                    }
                    if(typeof(style.marginLeft) === "number") style.paddingLeft = style.marginLeft / 2
                    if(typeof(style.marginLeft) === "number") style.marginLeft = 0
                    let item:any = node;
                    return(item.type === 'separator' ? <>
                      <li className="ms-Nav-navItem" style={{...style, background: 'transparent', cursor: 'default'}} tabIndex={index}>
                          <hr/>
                      </li>
                    </> 
                    : treeLine(style,index,node,isExpanded,hasChildren,handleChange))}
                  }
                </Tree>
              </ul>
            </nav>
          </Resizable>
          {/*<FormFilter items={this.props.items}/>*/}
          </>
        : null }
        { selNav === "settings" ?
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree">
                <p className="title mb-0">{i18n.t('sidebar:settings')}</p>
                <Nav
                  styles={{
                    root: {
                      width: "fit-content",
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }
                  }}
                  className="fileExplorer"
                  initialSelectedKey="all"
                  expandButtonAriaLabel="Expand or collapse"
                  //ariaLabel="Nav example with nested links"
                  groups={ i18n.t('settings:nav', { returnObjects: true }) }
                  onRenderLink={ this._onRenderLink }
                  onLinkClick={ (e,item:any) => history.push(item.page)}
                />
              </nav>
            </Resizable>
          : null }
          { selNav === "approvals" ?
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree">
                <p className="title mb-0">{i18n.t('sidebar:approvals')}</p>
                <Nav
                  styles={{
                    root: {
                      width: "fit-content",
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }
                  }}
                  className="fileExplorer"
                  initialSelectedKey="allUsers"
                  expandButtonAriaLabel="Expand or collapse"
                  //ariaLabel="Nav example with nested links"
                  groups={ activityTree }
                  onRenderLink={ this._onRenderLink }
                  onLinkClick={ (e,item:any) => history.push(item.page)}
                />
              </nav>
            </Resizable>
          : null }
          { selNav === "sessions" ?
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree">
                <p className="title mb-0">{i18n.t('sidebar:sessions')}</p>
                <Nav
                  styles={{
                    root: {
                      width: "fit-content",
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }
                  }}
                  className="fileExplorer"
                  initialSelectedKey={window.location.search && window.location.search !== "" ? "" : "allUsers"}
                  expandButtonAriaLabel="Expand or collapse"
                  //ariaLabel="Nav example with nested links"
                  groups={activityTree}
                  onRenderLink={ this._onRenderLink }
                  onLinkClick={ this._filterUser.bind(this) }
                />
              </nav>
            </Resizable>
          : null }
          { selNav === "policies" ?
            <Resizable
              size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
              onResizeStop={(e, direction, ref, d) => {
                var currentWidth = this.state.panelWidth.match(/\d+/)[0];
                this.setState({
                  panelWidth: +currentWidth + +d.width + "px"
                });
                this._saveResizeState();
              }}
              enable={{ top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
              minWidth={240}
              maxWidth={480}
              handleStyles= {{
                right: {
                  "right": "-6px",
                  "width": "6px",
                  "zIndex": 1
                }
              }}
              handleClasses={{"right": "resize-handle"}}
            >
              <nav className="tree">
                <p className="title mb-0">{i18n.t('sidebar:policies')}</p>
                <Nav
                  styles={{
                    root: {
                      width: "fit-content",
                      boxSizing: 'border-box',
                      overflowY: 'auto',
                      overflowX: 'auto'
                    }
                  }}
                  className="fileExplorer"
                  initialSelectedKey="all"
                  expandButtonAriaLabel="Expand or collapse"
                  //ariaLabel="Nav example with nested links"
                  groups={ i18n.t('policies:nav', { returnObjects: true }) }
                  onRenderLink={ this._onRenderLink }
                  onLinkClick={ (e,item:any) => history.push(item.page)}
                />
              </nav>
            </Resizable>
          : null }
        </div>
      </Fabric>
    )
  }

  private _saveResizeState() {
    const { panelHeight, panelWidth } = this.state;
    
    var prefs = {
      height: panelHeight,
      width: panelWidth
    }

    localStorage.setItem("sidebarPrefs", JSON.stringify(prefs))
  }

  private _onRenderLink = (link: any): JSX.Element | null => {
    var subpage = this.props.subpage
    return (
      link.type === "userFilter" ?
        <div className={"link" + (this.state.selUser === link.key ? " selUser": "")}>
          <span>{ link.name }</span>
        </div>
      : link.iconName ?
        <div className="link d-flex">
          <Icon className='me-2' style={{fontSize:'1.4em'}} iconName={link.iconName}/>
          <span>{ link.name }</span>
        </div>
      : link.key === "custom" ?
        <div className="link d-flex">
          <Icon className='me-2' style={{fontSize:'1.4em'}} iconName={"SingleColumnEdit"}/>
          <span>{ link.name }</span>
        </div>
      : link.type === "separator" ?
        <div className="link d-flex w-100">
          <hr/>
        </div>
      : link.key === "import" ?
      <div className="link d-flex">
        <Icon className='me-2' style={{fontSize:'1.4em'}} iconName={link.iconName}/>
        <span>{ link.name }</span>
      </div>
      : link.type === "allMessages" ?
      <div className={"folderLink dir " + (link.type === subpage ? "selItem":"")}>
        <MailInbox20Regular className='me-2'/>
        <span >{ link.name }</span>
      </div>
      : link.type === "conversations" || link.type === "conversations_inbox" ?
      <div className={"folderLink dir " + (link.type === subpage ? "selItem":"")}>
        <ChatMultiple20Regular className='me-2'/>
        <span >{ link.name }</span>
      </div>
      : link.type === "messages" || link.type === "messages_inbox" ?
      <div className={"folderLink dir " + (link.type === subpage ? "selItem":"")}>
        <Chat20Regular className='me-2'/>
        <span >{ link.name }</span>
      </div>
      : link.type === "filesync" || link.type === "filesync_inbox" ?
      <div className={"folderLink dir " + (link.type === subpage ? "selItem":"")}>
        <FolderSync20Regular className='me-2'/>
        <span >{ link.name }</span>
      </div>
      : link.type === "filesyncReceived" || link.type === "filesyncReceived_inbox" ?
      <div className={"ms-5 folderLink dir " + (link.type === subpage ? "selItem":"")}>
        <MailInbox20Regular className='me-2'/>
        <span >{ link.name }</span>
      </div>
      : link.type === "filesyncSent" || link.type === "filesyncSent_inbox" ?
      <div className={"ms-5 folderLink dir " + (link.type === subpage ? "selItem":"")}>
        <Send20Regular className='me-2'/>
        <span >{ link.name }</span>
      </div>
      : link.type === "connectInvites" || link.type === "connectInvites_inbox" ?
      <div className={"folderLink dir " + (link.type === subpage ? "selItem":"")}>
        <PersonChat20Regular className='me-2'/>
        <span >{ link.name }</span>
      </div>
      : link.type === "connectInvitesReceived" || link.type === "connectInvitesReceived_inbox" ?
      <div className={"ms-5 folderLink dir " + (link.type === subpage ? "selItem":"")}>
        <MailInbox20Regular className='me-2'/>
        <span >{ link.name }</span>
      </div>
      : link.type === "connectInvitesSent" || link.type === "connectInvitesSent_inbox" ?
      <div className={"ms-5 folderLink dir " + (link.type === subpage ? "selItem":"")}>
        <Send20Regular className='me-2'/>
        <span >{ link.name }</span>
      </div>
      :
      <div className="link">
        <span>{ link.name }</span>
      </div>
    )
  }

  private checkSelected(){
    const elements:any = document.getElementsByClassName('view-folderlink folderLink selItem');
    if (elements.length > 0) return false;
    else return true;
  }

  private removeFilterCategory(category) {
    let search = new URLSearchParams(document.location.search)
    search.delete(category.toLowerCase())
    history.push('?'+search.toString())
  }

  private removeFilter(item) {
    let search = new URLSearchParams(document.location.search)
    let tags = search.get(item.category.toLowerCase())?.split(';')
    if (tags) {
      let values = tags.filter((value)=>{
        return value !== item.name
      });
      search.delete(item.category.toLowerCase())
      values.length > 0 && search.set(item.category.toLowerCase(), values.join(';'))
      history.push('?'+search.toString())
    }
  }

  private _goToPage(ev: React.MouseEvent<HTMLElement> | undefined, item?: any | undefined) {
    if (ev) {
      ev.preventDefault();
    }

    if (item) {
      if(item.type === "inbox") history.push("/inbox/all")
      else if(item.type === "read") history.push("/inbox/read")
      else if(item.type === "project") history.push("/emails/" + item.id )
      else if(item.type === "projectsHeader") history.push("/emails/all")
      else if(item.type === "settings") history.push("/settings")
      else if(item.type === "allMessages") history.push("/conversations")
      else if(item.type === "messages") history.push("/conversations/messages")
      else if(item.type === "conversations") history.push("/conversations/groups")
      else if(item.type === "filesync") history.push("/conversations/filesync/received")
      else if(item.type === "filesyncReceived") history.push("/conversations/filesync/received")
      else if(item.type === "filesyncSent") history.push("/conversations/filesync/sent")
      else if(item.type === "connectInvites") history.push("/conversations/invites/received")
      else if(item.type === "connectInvitesReceived") history.push("/conversations/invites/received")
      else if(item.type === "connectInvitesSent") history.push("/conversations/invites/sent")
      else if(item.type === "messages_inbox") history.push("/inbox/messages")
      else if(item.type === "conversations_inbox") history.push("/inbox/groups")
      else if(item.type === "filesync_inbox") history.push("/inbox/filesync/received")
      else if(item.type === "filesyncReceived_inbox") history.push("/inbox/filesync/received")
      else if(item.type === "filesyncSent_inbox") history.push("/inbox/filesync/sent")
      else if(item.type === "connectInvites_inbox") history.push("/inbox/invites/received")
      else if(item.type === "connectInvitesReceived_inbox") history.push("/inbox/invites/received")
      else if(item.type === "connectInvitesSent_inbox") history.push("/inbox/invites/sent")
    }
  };

  private handleNodesChange = itemTree => {

  }

  private _goToConversations() {
    const {repoData} = this.state;

    this.toggleNav("conversations")
    if (window.location.href.indexOf("/conversations") === -1) {
      repoData && history.push('/conversations')
    }
  }

  private _getRepoData() {
    var repoData = this.props.userData.repository

    this._isMounted && this.setState({
      repoData: repoData,
      userName: this.props.userData.user.name + " " + this.props.userData.user.surname,
    }, () => { })
  }

  private _getSelNav() {
    if (window.location.href.indexOf("/users") !== -1) {
      return "users";
    } else if (window.location.href.indexOf("/groups") !== -1) {
        return "groups";
    } else if (window.location.href.indexOf("/activity") !== -1) {
      return "activity";
    } else if (window.location.href.indexOf("/sharing") !== -1) {
      return "sharing";
    } else if (window.location.href.indexOf("/home") !== -1) {
      return "home";
    } else if (window.location.href.indexOf("/approvals") !== -1) {
      return "approvals";
    } else if (window.location.href.indexOf("/sessions") !== -1) {
      return "sessions";
    } else if (window.location.href.indexOf("/policies") !== -1) {
      return "policies";
    } else if (window.location.href.indexOf("/settings") !== -1) {
      return "settings";
    } else if (window.location.href.indexOf("/conversations") !== -1) {
      return "conversations";
    }
    return "";
  }

  private _getActivityTree() {
    var users:any = [
      {
          key: "allUsers",
          id: "allUsers",
          name: i18n.t('sidebar:allusers'),
          isExpanded: false,
          type: "userFilter"
      },
    ]

    var companyUsers = this.state.companyUsers.sort(function(a:any, b: any){
      if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
      if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
      return 0;
    })
    
    users = users.concat(companyUsers)


    var activityTree: any = [{
      "links": [
        {
          "key": "views",
          "name": "Views",
          "isExpanded": true,
          "type": "activityFilter",
          "links": [
              {
                  "key": "allUsers",
                  "name": "All users",
                  "url": "",
                  "isExpanded": false,
                  "iconName": "BulletedList",
                  "type": "userFilter"
              },
              {
                  "key": "custom",
                  "name": "Custom view",
                  "isExpanded": false,
                  "disabled": true
              }
          ]
        },
        {
          key: "separator1",
          id: "separator1",
          type: "separator",
          disabled: true,
        },
        {
          "key": "filters",
          "name": "Filters",
          "isExpanded": true,
          "links": users,
          "type": "activityFilter"
        }
      ]
    }]

    this.setState({
        activityTree: activityTree
    })
  }

  private _getSharingTree() {
    var users:any = [
      {
        key: "allUsers",
        id: "allUsers",
        name: i18n.t('sidebar:allusers'),
        isExpanded: false,
        type: "userFilter",
        selected: true
      },
    ]

    var companyUsers = this.state.companyUsers.sort(function(a:any, b: any){
      if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
      if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
      return 0;
    })
    
    users = users.concat(companyUsers)


    var activityTree: any = [
      {"links": [
        {
          "key": "views",
          "name": "Views",
          "isExpanded": true,
          "type": "activityFilter",
          "links": [
              {
                  "key": "allUsers",
                  "name": "All users",
                  "url": "",
                  "isExpanded": false,
                  "iconName": "BulletedList",
                  "type": "userFilter"
              },
              {
                  "key": "custom",
                  "name": "Custom view",
                  "isExpanded": false,
                  "disabled": true
              }
          ]
        },
        {
          key: "separator1",
          id: "separator1",
          type: "separator",
          disabled: true,
        },
        {
          "key": "filters",
          "name": "Filters",
          "isExpanded": true,
          "links": users,
          "type": "activityFilter"
        }
      ]
    }]

    this.setState({
        activityTree: activityTree
    })
  }

  private _getApprovalTree() {
    var users:any = [
      {
        key: "allUsers",
        id: "allUsers",
        name: i18n.t('sidebar:allusers'),
        isExpanded: false,
        type: "userFilter",
        selected: true
      },
    ]

    var companyUsers = this.state.companyUsers.sort(function(a:any, b: any){
      if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
      if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
      return 0;
    })
    
    users = users.concat(companyUsers)


    var activityTree: any = [
      {"links": [
        {
          "key": "Views",
          "name": "Views",
          "isExpanded": true,
          "links": [
              {
                  "key": "allUsers",
                  "name": "All approval requests",
                  "page": "/approvals",
                  "isExpanded": false,
                  "iconName": "BulletedList"
              },
              {
                  "key": "toApprove",
                  "name": "Pending requests",
                  "isExpanded": false,
                  "iconName": "BulletedList"
              },
              {
                  "key": "approved",
                  "name": "Approved requests",
                  "isExpanded": false,
                  "iconName": "BulletedList"
              },
              {
                  "key": "custom",
                  "name": "Custom view",
                  "isExpanded": false,
                  "disabled": true
              }
          ]
        },
        {
          key: "separator1",
          id: "separator1",
          type: "separator",
          disabled: true,
        },
        {
          "key": "filters",
          "name": "Filters",
          "isExpanded": true,
          "links": users,
          "type": "activityFilter"
        }
      ]
    }]

    this.setState({
        activityTree: activityTree
    })
  }

  private _getRepoUsers() {
    userService.getRepoUsers('ALL').then((response: any) => {
      var companyUsers = response.data.map((item:any) => {
        return ({
          key: item.id,
          name: item.name + ' ' + item.surname,
          email: item.email,
          license: item.subscription,
          state: item.state,
          role: item.role || "UNDEFINED",
          activationDate: item.activationDate,
          lastLogin: item.lastLogin,
          type: 'userFilter'
        })
      })
      this._isMounted && this.setState({
        companyUsers: companyUsers
      }, () => {
        if (window.location.href.indexOf("/approvals") !== -1) this._getApprovalTree()
        else this._getActivityTree()
      })
    }).catch((error: any) => {
      console.log(error)
    })
  }

  private _getActivitySelFilters() {
    var searchQuery = this.props.searchQuery
    const params = new URLSearchParams(searchQuery);

    var userQuery = params.get("user_id");
    if(userQuery) {
      this._isMounted && this.setState({ selUser: userQuery })
    } else {
      this._isMounted && this.setState({ selUser: "allUsers" })
    }

    var operationType = params.get("operationTypes");
    var modelType = params.get("modelTypes");
    if(operationType && modelType) {
      var selAction = modelType + "||" + operationType
      this._isMounted && this.setState({ selAction: selAction })
    } else {
      this._isMounted && this.setState({ selAction: "allActions" })
    }

    var startDate = params.get("initialDate");
    if(startDate) {
      var date: Date = new Date(parseInt(startDate))
      this._isMounted && this.setState({ initialDate: date })
    }

    var endDate = params.get("finalDate");
    if(endDate) {
      var finalDate: Date = new Date(parseInt(endDate))
      this._isMounted && this.setState({ finalDate: finalDate })
    }
  }

  private _getSharingSelFilters() {
    var searchQuery = this.props.searchQuery
    const params = new URLSearchParams(searchQuery);

    var userQuery = params.get("user_id");
    if(userQuery) {
      this._isMounted && this.setState({ selUser: userQuery })
    } else {
      this._isMounted && this.setState({ selUser: "allUsers" })
    }

    var startDate = params.get("initialDate");
    if(startDate) {
      var date: Date = new Date(parseInt(startDate))
      this._isMounted && this.setState({ initialDate: date })
    }

    var endDate = params.get("finalDate");
    if(endDate) {
      var finalDate: Date = new Date(parseInt(endDate))
      this._isMounted && this.setState({ finalDate: finalDate })
    }
  }

  private _filterUser(ev: React.MouseEvent<HTMLElement> | undefined, item?: INavLink | undefined) {
    var type: any = item && item.type;
    console.log(item)
    if(type === "userFilter") {
      var queryString = "";
      var key:any = item && item.key

      var searchQuery = this.props.searchQuery
      const params = new URLSearchParams(searchQuery);
      var userQuery = params.get("user_id");
      if(userQuery) {
        params.delete("user_id")
        searchQuery = params.toString();
      }
    
      if(key !== "allUsers") {
        if(searchQuery === "?") {
          queryString = searchQuery + "user_id=" + key;
        } else {
          queryString = searchQuery + "&user_id=" + key;
        }
      } else {
        queryString = searchQuery
      }

      history.push({
        pathname: window.location.pathname,
        search: queryString
      })
    }
  }

  private _filterAction = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number) => {
    if(option) {
      var queryString = "";
      var key:any = option.key
      var query = key.split("||")

      var searchQuery = this.props.searchQuery

      const params = new URLSearchParams(searchQuery);

      var modelType = params.get("modelTypes");
      if(modelType) {
        params.delete("modelTypes")
        searchQuery = params.toString();
      }

      var operationType = params.get("operationTypes");
      if(operationType) {
        params.delete("operationTypes")
        searchQuery = params.toString();
      }

      if(query.length === 2) {
        if(searchQuery === "?" || searchQuery === "") {
          queryString = searchQuery + "modelTypes=" + query[0] + "&operationTypes=" + query[1];
        } else {
          queryString = searchQuery + "&modelTypes=" + query[0] + "&operationTypes=" + query[1];
        }
      } else if (key !== "allActions") {
        queryString = searchQuery + key
      }

      history.push({
        pathname: window.location.pathname,
        search: queryString
      })
    }
  }

  private _oninitialDateSelect = (date: Date | null | undefined) => {
    if(date) {
      this._isMounted && this.setState({ initialDate: date},
        () => {
          var queryString = "";
          var searchQuery = this.props.searchQuery
          const params = new URLSearchParams(searchQuery);

          var startDate = params.get("initialDate");
          if(startDate) {
            params.delete("initialDate")
            searchQuery = params.toString();
          }

          if(searchQuery === "?") {
            queryString = searchQuery + "initialDate=" + date.getTime();
          } else {
            queryString = searchQuery + "&initialDate=" + date.getTime();
          }

          history.push({
            pathname: window.location.pathname,
            search: queryString
          })
        })
    }
  }

  private _onfinalDateSelect = (date: Date | null | undefined) => {
    if(date) {
      this._isMounted && this.setState({ finalDate: date},
        () => {
          var queryString = "";
          var searchQuery = this.props.searchQuery
          const params = new URLSearchParams(searchQuery);

          var endDate = params.get("finalDate");
          if(endDate) {
            params.delete("finalDate")
            searchQuery = params.toString();
          }

          var endOfTheDay = new Date(date);
          endOfTheDay.setHours(23,59,59,999);

          if(searchQuery === "?") {
            queryString = searchQuery + "finalDate=" + endOfTheDay.getTime();
          } else {
            queryString = searchQuery + "&finalDate=" + endOfTheDay.getTime();
          }

          history.push({
            pathname: window.location.pathname,
            search: queryString
          })
        })
    }
  }

  private toggleNav(nav: any) {
    const { selNav } = this.state;
    if (selNav === nav) {
      nav = "";
    }
    this._isMounted && this.setState({ selNav: nav })
  }

  private _goToHome() {
    history.push("/home")

    this.toggleNav("home")
    if (window.location.href.indexOf("/home") === -1) {
      history.push('/home')
    }
  }

  private _goToUsers() {
    history.push("/users/active")

    this.toggleNav("users")
    if (window.location.href.indexOf("/users") === -1) {
      history.push('/users/active')
    }
 }

 private _goToGroups() {
    history.push("/groups/active")

    this.toggleNav("groups")
    if (window.location.href.indexOf("/groups") === -1) {
      history.push('/groups/active')
    }
 }

  private _goToActivity() {
     history.push("/activity")

    this.toggleNav("activity")
    if (window.location.href.indexOf("/activity") === -1) {
      history.push('/activity')
    }
  }

  private _goToSharing() {
    history.push("/sharing")

    this.toggleNav("sharing")
    if (window.location.href.indexOf("/sharing") === -1) {
      history.push('/sharing')
    }
  }

  private _goToApprovals() {
    history.push("/approvals")

    this.toggleNav("approvals")
    if (window.location.href.indexOf("/approvals") === -1) {
      history.push('/approvals')
    }
  }

  private _goToSessions() {
    history.push("/sessions")

    this.toggleNav("sessions")
    if (window.location.href.indexOf("/sessions") === -1) {
      history.push('/sessions')
    }
  }

  private _goToPolicies() {
    history.push("/policies")

    this.toggleNav("policies")
    if (window.location.href.indexOf("/policies") === -1) {
      history.push('/policies')
    }
  }

  private _goToRoles() {}

  private _goToBilling() {}

  private _goToSettingsPage() {
    this.toggleNav("settings")

    if (window.location.href.indexOf("/settings") === -1) {
      history.push('/settings')
    }
  }

  private _logout() {
    userService.logout()
  }
}