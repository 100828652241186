import * as React from 'react';
import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import { history } from '../_helpers';
import { Fabric } from '@fluentui/react';
import { DocumentCard, DocumentCardLogo, DocumentCardTitle, mergeStyles } from '@fluentui/react';
import "moment/min/locales";
import { IDocumentCardStyles } from '@fluentui/react';


export interface Props {
  location: any;
  userData: any;
}

export interface HomeState { 
  userData: any;
  breadcrumb: any[];
  searchQuery: any;
}

export interface IDocument {
  id: string;
  date: string;
  user: string;
  action: string;
  type: string;
  operation: string;
  message: string;
  model_objects: any;
  old_2nd_model_objects: any;
  searchQuery: string;
}

export class Home extends React.Component<Props, HomeState> {
  private _isMounted: boolean;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this.state = {
      userData: null,
      breadcrumb: [],
      searchQuery: []
    }
  }

  public componentDidMount() {
      this._isMounted = true;
      if(this.props.userData) {
        this.setBreadcrumb();
      }

  }

  componentDidUpdate (prevProps: any) {
    if(this.props.userData !== prevProps.userData) {
      this.setBreadcrumb();
    }
  }

  private setBreadcrumb() {
    this._isMounted && this.setState({
      breadcrumb: [
        {
          text: 'Home',
          key: '/home',
          icon: 'Home',
          onClick: this.headerRef._onBreadcrumbItemClicked.bind(this)
        }
      ]
    })
  }

  private cardStyles: IDocumentCardStyles = {
    root: { marginRight: 20, maxWidth: 480 },
  };

  private conversationTileClass = mergeStyles({ height: 240 });



  public render() {
    return(
      <Fabric>
        <Header
          ref={(instance: any) => { this.headerRef = instance; }}
          userData={ this.props.userData }
          breadcrumb={ this.state.breadcrumb }
          actions="home"
          getActivity={ null }
          sidebarRef={this.sidebarRef}
        />

        <div className="content-wrap d-flex flex-row">
          <div className="fakeLine"></div>
          <Sidebar
            ref={(instance: any) => { this.sidebarRef = instance; }}
            userData={ this.props.userData }
            searchQuery={ this.props.location.search }
          />

          <div className="list pt-3 flex-grow-1 justify-content-center" style={{textAlign: 'left', backgroundColor: 'rgb(250, 249, 248)'}}>
              <span style={{fontSize: 18}} className='m-4 ps-2 pb-4'>{this.props.userData ? this.props.userData.repository.name : ""}</span>
              <div className='d-flex ms-4 mr-0' style={{maxWidth: '1200px', flexWrap: 'wrap'}}>
                <DocumentCard
                  className='px-0 me-4 mt-4 home-card info-card'
                  aria-label='Users'
                  styles={this.cardStyles}
                  onClick={()=>history.push('/users/active')} >
                  <div className={this.conversationTileClass}>
                    <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                      <DocumentCardLogo className='p-0' {...{logoIcon: 'Contact'}} />
                      <DocumentCardTitle title="Users" shouldTruncate />
                    </div>
                    <hr className='my-2 separator'/>
                    <DocumentCardTitle
                      title="Add, edit and delete users."
                      shouldTruncate
                      showAsSecondaryTitle
                    />
                  </div>
                </DocumentCard>
                <DocumentCard
                  className='px-0 me-4 mt-4 home-card info-card'
                  aria-label='Groups'
                  styles={this.cardStyles}
                  onClick={()=>history.push('/groups')} >
                  <div className={this.conversationTileClass}>
                    <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                      <DocumentCardLogo className='p-0' {...{logoIcon: 'Group'}} />
                      <DocumentCardTitle title="Groups" shouldTruncate />
                    </div>
                    <hr className='my-2 separator'/>
                    <DocumentCardTitle
                      title="Add, edit and delete groups."
                      shouldTruncate
                      showAsSecondaryTitle
                    />
                  </div>
                </DocumentCard>
                <DocumentCard
                  className='px-0 me-4 mt-4 home-card info-card'
                  aria-label='Approvals'
                  styles={this.cardStyles}
                  onClick={()=>history.push('/approvals')}>
                  <div className={this.conversationTileClass}>
                    <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                      <DocumentCardLogo className='p-0' {...{logoIcon: 'CheckboxComposite'}} />
                      <DocumentCardTitle title="Approvals" shouldTruncate />
                    </div>
                    <hr className='my-2 separator'/>
                    <DocumentCardTitle
                      title="Approve users requests."
                      shouldTruncate
                      showAsSecondaryTitle
                    />
                  </div>
                </DocumentCard>
                <DocumentCard
                  className='px-0 me-4 mt-4 home-card info-card'
                  aria-label='Sharing'
                  styles={this.cardStyles}
                  onClick={()=>history.push('/sharing')}>
                  <div className={this.conversationTileClass}>
                    <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                      <DocumentCardLogo className='p-0' {...{logoIcon: 'Share'}} />
                      <DocumentCardTitle title="Sharing" shouldTruncate />
                    </div>
                    <hr className='my-2 separator'/>
                    <DocumentCardTitle
                      title="See sharing activity."
                      shouldTruncate
                      showAsSecondaryTitle
                    />
                  </div>
                </DocumentCard>
                <DocumentCard
                  className='px-0 me-4 mt-4 home-card info-card'
                  aria-label='Activity'
                  styles={this.cardStyles}
                  onClick={()=>history.push('/activity')}>
                  <div className={this.conversationTileClass}>
                    <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                      <DocumentCardLogo className='p-0' {...{logoIcon: 'LineChart'}} />
                      <DocumentCardTitle title="Activity" shouldTruncate />
                    </div>
                    <hr className='my-2 separator'/>
                    <DocumentCardTitle
                      title="See all activity from Synergy users."
                      shouldTruncate
                      showAsSecondaryTitle
                    />
                  </div>
                </DocumentCard>
                <DocumentCard
                  className='px-0 me-4 mt-4 home-card info-card'
                  aria-label='Sessions'
                  styles={this.cardStyles}
                  onClick={()=>history.push('/sessions')}>
                  <div className={this.conversationTileClass}>
                    <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                      <DocumentCardLogo className='p-0' {...{logoIcon: 'Devices3'}} />
                      <DocumentCardTitle title="Sessions" shouldTruncate />
                    </div>
                    <hr className='my-2 separator'/>
                    <DocumentCardTitle
                      title="View active sessions from Synergy users."
                      shouldTruncate
                      showAsSecondaryTitle
                    />
                  </div>
                </DocumentCard>
                <DocumentCard
                  className='px-0 me-4 mt-4 home-card info-card'
                  aria-label='Policies'
                  styles={this.cardStyles}
                  onClick={()=>history.push('/policies')}>
                  <div className={this.conversationTileClass}>
                    <div className='d-flex align-items-center' style={{padding: '1rem 1rem 0 1rem'}}>
                      <DocumentCardLogo className='p-0' {...{logoIcon: 'Repair'}} />
                      <DocumentCardTitle title="Policies" shouldTruncate />
                    </div>
                    <hr className='my-2 separator'/>
                    <DocumentCardTitle
                      title="Check all the repository policies."
                      shouldTruncate
                      showAsSecondaryTitle
                    />
                  </div>
                </DocumentCard>
            </div>
          </div>
        </div>
      </Fabric>
    )
  }
}