import * as React from 'react';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import "moment/min/locales";

import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import { ActionButton, FocusZone, FocusZoneDirection, Icon, List, Persona, PersonaSize, Spinner, SpinnerSize, TooltipHost } from '@fluentui/react';
import Moment from 'moment';
import i18n from "i18next";
import { DetailsPanel } from '../_components/DetailsPanel';
import { userService } from '../_services';

export interface ActiveSessionsPageState {
  userData?: any;
  repoData?: any;
  showLeftPanel: boolean;
  selLink: any;
  syncStatus: any;
  downloadingFiles: any;
  uploadsQueue: any;
  isLoading: boolean;
  goBack: number;
  contextualMenuProps?: IContextualMenuProps;
  breadcrumb:any;
  showRightPanel: boolean;
  selItem: any;
  sessions: any;
}

export interface Props {
  match: {
    url: string
  },
  location: any,
  appPlatform: string,
  isOnline: boolean,
  maintenance: boolean,
  socket: any,
  pusher: any,
  appUpdateAvailable: boolean,
  webUpdateAvailable: boolean,
  fileExplorerType: string,
  userData: any,
}

export class ActiveSessionsPage extends React.Component<Props, ActiveSessionsPageState> {
  private _isMounted: boolean;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this.state = {
      userData: null,
      repoData: null,
      showLeftPanel: true,
      selLink: null,
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      downloadingFiles: [],
      uploadsQueue: this._getImports(),
      isLoading: false,
      goBack: 0,
      contextualMenuProps: undefined,
      breadcrumb: [],
      showRightPanel: true,
      selItem: null,
      sessions: null
    }
  }

  public componentDidMount() {
    this._isMounted = true;
    this.setState({breadcrumb: [{
      text: 'Sessions',
      key: '/sessions',
      onClick: this.headerRef._onBreadcrumbItemClicked.bind(this)
    }]});
    if (!localStorage.openFilePath) localStorage.setItem('openFilePath','askToOpen')
    if (!localStorage.pdfApplication) localStorage.setItem('pdfApplication','Default')
    if (this.props.userData) {
      this.getSessions();
    }
  }

  public componentDidUpdate(prevProps) {
    if (this.props.userData !== prevProps.userData || prevProps.location.search !== this.props.location.search) {
      this.getSessions();
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  private getSessions() {
    this._isMounted && this.setState({
      syncStatus: {
        message: "Syncing",
        type: "info",
        icon: "spinner"
      },
      isLoading: true
    })
    const newSearchQuery = this.props.location.search;
      const params = new URLSearchParams(newSearchQuery);
      var user = params.get("user_id") || '';
      userService.getSessions(this.props.userData.repository.id).then((response) => {
        let sessions = user ? response.data.filter((session)=>{
          return session.user.id === user
        }) : response.data
        this.setState({
          sessions: sessions.sort((a:any,b:any) => {
            if(a.loginDate < b.loginDate)
                return 1;
            if(b.loginDate < a.loginDate)
                return -1;
            return 0;
          }),
          syncStatus: null,
          isLoading: false
        })
      }).catch(()=>{
        this._isMounted && this.setState({
          syncStatus: null,
          isLoading: false
        })
      })
  }

  private _deleteSession(id: string) {
    userService.deleteSession(id).then(()=>{
      this.getSessions();
    })
  }

  public render() {

    const { sessions, isLoading, selItem } = this.state;

    const renderCell = (session: any, index: number | undefined) => {
      return (
        <div key={index} className={'d-flex session w-100 px-3 pt-3'  + (selItem === session ? ' selected' : '') } onClick={()=>{setTimeout(()=>{this._isMounted && this.setState({selItem: session})},50)}}>
        <iframe title='map' className='me-3 mb-3' src={"https://maps.google.com/maps?ll="+session.latitude+","+session.longitude+"&z=10&output=embed"} frameBorder={0} width={150} height={150}></iframe>
        <div>
        <div className='session-header w-100'>
          <div className='d-flex align-items-center mb-3'>
            <Icon className='me-3' iconName='Devices3' style={{fontSize: 24}}/>
            <TooltipHost
              key={'createdBy'}
              content= {
                <div className='m-1'>
                  <Persona className='mb-2' text={session.user.name + " " + session.user.surname} initialsColor="#7F7F7F" size={PersonaSize.size32}/>
                  <span className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</span>
                  <p className='p-0 m-0' style={{fontSize:'14px'}}>{session.user.email}</p>
                </div>
              }
              id={'createdBy'}
              calloutProps={{ gapSpace: 0 }}
            >
              <span className='me-3 persona-link' style={{fontWeight: 600}}>{session.user.name + " " + session.user.surname}</span>
            </TooltipHost>
            <span>{Moment(session.loginDate).format('Y-MM-DD HH:mm')}</span>
            <div className='ms-auto me-0'>
              <ActionButton onClick={()=>this._deleteSession(session.id)} className='session-signout' iconProps={{iconName:'SignOut'}} text='Sign out'/>
            </div>
          </div>
          <hr className='w-100'/>
        </div>
        <div className='d-flex w-100  session-body'>
          <div className='me-4'>
            <p>Location</p>
            <span>{(session.city ? session.city + ', ' : '') + (session.country || '-')}</span>
          </div>
          <div className='me-4'>
            <p>Operating system</p>
            <span>{session.browser === 'CFNetwork' ? 'iOS' : session.deviceOs ? session.deviceOs : '-'}</span>
          </div>
          <div className='me-4'>
            <p>Browser</p>
            <span>{session.browser ? session.browser : '-'}</span>
          </div>
          <div className='me-4'>
            <p>IP</p>
            <span>{session.ip}</span>
          </div>
          <div className='me-4'>
            <p>App</p>
            <span>{session.application ? session.application : '-'}</span>
          </div>
        </div>
          </div>
      </div>
      )
    }

    return(
      <Fabric>
        <Header
          ref={instance => { this.headerRef = instance; }}
          headerSection="sessions"
          appPlatform={ this.props.appPlatform }
          isOnline={ this.props.isOnline }
          maintenance={ this.props.maintenance }
          pusher={this.props.pusher}
          repoData={ this.state.repoData }
          folderId={ this.props.match['params'].id }
          syncStatus={ this.state.syncStatus }
          downloadingFiles={ [] }
          uploadsQueue={ [] }
          getCurrentContent={ this._getCurrentContent.bind(this) }
          fileExplorerType={this.props.fileExplorerType}
          sidebarRef={this.sidebarRef}
          breadcrumb={this.state.breadcrumb}
          callbackFunction={ this.callbackFunction }
          showRightPanel={ this.state.showRightPanel }

        />
        <div className="d-flex flex-row">      
          <Sidebar
            ref={instance => { this.sidebarRef = instance; }}
            appPlatform={ this.props.appPlatform }
            isOnline={ this.props.isOnline }
            maintenance={ this.props.maintenance }
            appUpdateAvailable={ this.props.appUpdateAvailable }
            webUpdateAvailable={ this.props.webUpdateAvailable }
            userData={ this.props.userData }
            downloadingFiles={ this.state.downloadingFiles }
            uploadsQueue={ this.state.uploadsQueue }
            headerRef={ this.headerRef }
            searchQuery={ this.props.location.search }
            fileExplorerType={this.props.fileExplorerType}
            callbackFunction={ this.callbackFunction }
            sidebarNav="sessions"
            headerSection="sessions"
          />
          <div className={"list mr-auto flex-grow-1 "  + this.props.appPlatform} style={{background:'rgb(250, 249, 248)'}}>
            <div className='m-3'>
            <FocusZone direction={FocusZoneDirection.vertical}>
              <List
                items={sessions}
                onRenderCell={renderCell}
                version={selItem}
              />
            </FocusZone>
              { (!sessions || (sessions && sessions.length === 0)) && isLoading ?
                <div className="load-more text-center p-3 w-100">
                  <Spinner size={SpinnerSize.small} className="d-inline-block ms-2" />
                </div>
              : sessions && sessions.length === 0 ?
              <div className="empty-folder-msg p-3">
                <p className="text-center text-secondary mx-auto px-3">
                  No session matches the current filters
                </p>
              </div>
            : null }
            </div>
          </div>
          <DetailsPanel
            //ref={instance => { this.detailsPanelRef = instance; }}
            userData={ this.props.userData }
            //items={ companies }
            showRightPanel={ this.state.showRightPanel }
            selItem={ this.state.selItem }
            headerRef={ this.headerRef }
            detailsPanelSection="sessions"
            callbackFunction={ this.callbackFunction }
          />
        </div>
      </Fabric>
    )
  }

  private _getCurrentContent() {
    this.getSessions();
  }

  private _getImports() {
    if(localStorage.getItem("uploadsQueue")) {
      return JSON.parse(localStorage.getItem("uploadsQueue") || "{}");
    } else {
      return [];
    }
  }
}
