import * as React from 'react';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import "moment/min/locales";

import { Header } from '../_components/Header';
import { Sidebar } from '../_components/Sidebar';
import { ChoiceGroup } from '@fluentui/react';

import i18n from "i18next";

export interface SettingsPageState {
  userData?: any;
  repoData?: any;
  showLeftPanel: boolean;
  selLink: any;
  syncStatus: any;
  downloadingFiles: any;
  uploadsQueue: any;
  isLoading: boolean;
  goBack: number;
  contextualMenuProps?: IContextualMenuProps;
  pdfApplication: string;
  language: string;
  breadcrumb:any;
}

export interface Props {
  match: {
    url: string
  },
  location: any,
  appPlatform: string,
  isOnline: boolean,
  maintenance: boolean,
  socket: any,
  pusher: any,
  appUpdateAvailable: boolean,
  webUpdateAvailable: boolean,
  fileExplorerType: string,
  userData: any,
}

export class SettingsPage extends React.Component<Props, SettingsPageState> {
  private _isMounted: boolean;
  private headerRef:any = React.createRef();
  private sidebarRef:any = React.createRef();

  constructor(props: any) {
    super(props);
    this._isMounted = false;

    this.state = {
      userData: null,
      repoData: null,
      showLeftPanel: true,
      selLink: null,
      syncStatus: {
        message: "Synced",
        type: "success",
        icon: "SkypeCircleCheck"
      },
      downloadingFiles: [],
      uploadsQueue: this._getImports(),
      isLoading: false,
      goBack: 0,
      contextualMenuProps: undefined,
      pdfApplication: localStorage.pdfApplication || 'Default',
      language: i18n.language || 'en',
      breadcrumb: []
    }
  }

  public componentDidMount() {
    this._isMounted = true;
    this.setState({language: i18n.language, breadcrumb: [{
      text: 'Settings',
      key: '/settings',
      onClick: this.headerRef._onBreadcrumbItemClicked.bind(this)
    }]});
    if (!localStorage.openFilePath) localStorage.setItem('openFilePath','askToOpen')
    if (!localStorage.pdfApplication) localStorage.setItem('pdfApplication','Default')
  }

  public componentDidUpdate(prevProps) {
    if(this.state.language !== i18n.language) {
      this.setState({language: i18n.language});
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
  }

  callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  public render() {
    const { language } = this.state;

    return(
      <Fabric>
        <Header
          ref={instance => { this.headerRef = instance; }}
          headerSection="settingsOptions"
          appPlatform={ this.props.appPlatform }
          isOnline={ this.props.isOnline }
          maintenance={ this.props.maintenance }
          pusher={this.props.pusher}
          repoData={ this.state.repoData }
          folderId={ this.props.match['params'].id }
          syncStatus={ this.state.syncStatus }
          downloadingFiles={ [] }
          uploadsQueue={ [] }
          getCurrentContent={ this._getCurrentContent.bind(this) }
          fileExplorerType={this.props.fileExplorerType}
          sidebarRef={this.sidebarRef}
          breadcrumb={this.state.breadcrumb}
        />
        <div className="d-flex flex-row">      
          <Sidebar
            ref={instance => { this.sidebarRef = instance; }}
            appPlatform={ this.props.appPlatform }
            isOnline={ this.props.isOnline }
            maintenance={ this.props.maintenance }
            appUpdateAvailable={ this.props.appUpdateAvailable }
            webUpdateAvailable={ this.props.webUpdateAvailable }
            userData={ this.props.userData }
            downloadingFiles={ this.state.downloadingFiles }
            uploadsQueue={ this.state.uploadsQueue }
            headerRef={ this.headerRef }
            searchQuery={ this.props.location.search }
            fileExplorerType={this.props.fileExplorerType}
            callbackFunction={ this.callbackFunction }
            sidebarNav="settings"
            headerSection="settingsOptions"
          />
          <div className={"list mr-auto flex-grow-1 "  + this.props.appPlatform}>
            <div className='m-3'>
              <ChoiceGroup
                label={i18n.t('settings:language')+':'}
                options={[
                  {
                    key: 'de',
                    text: 'Deutsch'
                  },
                  {
                    key: 'en',
                    text: 'English'
                  },
                  {
                    key: 'es',
                    text: 'Español'
                  },
                ]}
                selectedKey={language}
                onChange={(e,v:any) => {i18n.changeLanguage(v.key)}}
              />
            </div>
          </div>
          
        </div>
      </Fabric>
    )
  }

  private _getCurrentContent() {
    this._isMounted && this.setState({
      syncStatus: {
        message: "Syncing",
        type: "info",
        icon: "spinner"
      },
      isLoading: true
    })

  }

  private _getImports() {
    if(localStorage.getItem("uploadsQueue")) {
      return JSON.parse(localStorage.getItem("uploadsQueue") || "{}");
    } else {
      return [];
    }
  }
}
