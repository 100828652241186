import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from './_helpers';
import { PrivateRoute } from './_components';
import { alertActions } from './_actions';
import { userService } from './_services';
import { LoginPage } from './_pages/LoginPage';
import { Users } from './_pages/Users';
import { Groups } from './_pages/Groups';
import { Activity } from './_pages/Activity';
import { SettingsPage } from './_pages/SettingsPage';
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import { withTranslation } from 'react-i18next';
import { initializeIcons } from '@fluentui/react';
import { Sharing } from './_pages/Sharing';
import { Home } from './_pages/Home';
import i18n from './i18n';
import { ApprovalsPage } from './_pages/ApprovalsPage';
import { PoliciesPage } from './_pages/PoliciesPage';
import { ActiveSessionsPage } from './_pages/ActiveSessionsPage';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import ConversationsPage from './_pages/ConversationsPage';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';

export interface Props {
  dispatch?: any;
}

export interface AppState {
  userData: any;
}

class App extends React.Component<Props, AppState> {
  private _tokenSchedule = false;
  constructor(props:any) {
    super(props);

    this.state = {
      userData: null
    }

    const { dispatch } = this.props;
    history.listen((location, action) => {
        // clear alert on location change
        dispatch(alertActions.clear());
    });

    let user = localStorage.getItem('user');
    if(user) {
      checkTokenExpirationDate().then(()=>{
        if (this._tokenSchedule === false) setInterval(checkTokenExpirationDate,600000)
        this._tokenSchedule = true;
        this._getUserData()
      }).catch(error => {
        console.log(error)
      })
    } else {
        history.push('/login')
    }

    initializeIcons();
    initializeFileTypeIcons();
  }

  public resetDataOnLogout() {
    this.setState({
        userData: null
    })
  }

  public componentDidUpdate() {
    var language = localStorage.getItem("i18nextLng") || 'en'

        if (i18n.language !== language) {
            i18n.changeLanguage(language)
        }
  }

  render() {
    const { userData } = this.state

    return (
      <FluentProvider theme={webLightTheme}>
        <Router history={history}>
          <Switch>
              <PrivateRoute exact path="/home" component={ Home } data={ { userData: userData } } />
              <PrivateRoute exact path="/activity" component={ Activity } data={ { userData: userData } } />
              <PrivateRoute exact path="/sharing" component={ Sharing } data={ { userData: userData } } />
              <PrivateRoute exact path="/users/active" component={ Users } data={ { userData: userData, page: 'all'} } />
              <PrivateRoute exact path="/users/import" component={ Users } data={ { userData: userData, page: 'import' } }/>
              <Route exact path="/groups">
                  <Redirect to={{ pathname: '/groups/active' }}/>
              </Route>
              <PrivateRoute exact path="/groups/active" component={ Groups } data={ { userData: userData } } />
              <PrivateRoute exact path="/approvals" component={ ApprovalsPage }  data={ { userData: userData } } />
              <PrivateRoute exact path="/sessions" component={ ActiveSessionsPage }  data={ { userData: userData } } />
              <PrivateRoute exact path="/policies" component={ PoliciesPage }  data={ { userData: userData } } />
              <PrivateRoute exact path="/settings" component={ SettingsPage }  data={ { userData: userData } } />
              <PrivateRoute exact path="/conversations" component={ConversationsPage} data={ { userData: userData, page: 'conversations', subpage: 'allMessages' } } />
              <PrivateRoute exact path="/conversations/messages" component={ConversationsPage} data={ { userData: userData, page: 'conversations', subpage: 'messages' } } />
              <PrivateRoute exact path="/conversations/groups" component={ConversationsPage} data={ { userData: userData, page: 'conversations', subpage: 'conversations' } } />
              <PrivateRoute exact path="/conversations/filesync/received" component={ConversationsPage} data={ { userData: userData, page: 'conversations', subpage: 'filesyncReceived' } } />
              <PrivateRoute exact path="/conversations/filesync/sent" component={ConversationsPage} data={ { userData: userData, page: 'conversations', subpage: 'filesyncSent' } } />
              <PrivateRoute exact path="/conversations/invites/received" component={ConversationsPage} data={ { userData: userData, page: 'conversations', subpage: 'connectInvitesReceived' } } />
              <PrivateRoute exact path="/conversations/invites/sent" component={ConversationsPage} data={ { userData: userData, page: 'conversations', subpage: 'connectInvitesSent' } } />
              <Route path="/login" render={(props) => <LoginPage {...props} resetDataOnLogout={this.resetDataOnLogout.bind(this)} getUserData={this._getUserData} />} />
              
              <Route exact path="/">
                  <Redirect to={{ pathname: '/home' }}/>
              </Route>
          </Switch>
        </Router>
      </FluentProvider>
    );
  }

  private _getUserData = () => {
    userService.getUserData().then(response => {
      this.setState({
        userData: response
      }, () => {
        if (window.location.pathname.includes("/login")
          || window.location.href.indexOf("null") !== -1) {
          history.push('/home')
        }
      })
    }).catch(error => console.log(error))
  }
}

function mapStateToProps(state: any) {
  const { alert } = state;
  return {
      alert
  };
}

async function checkTokenExpirationDate() {
  console.log('Checking refresh token')
  let tokenttl = parseInt(localStorage.tokenttl);
  let currentDate = new Date().getTime();
  let pathname = window.location.pathname;
  if (tokenttl && pathname.indexOf("/login") === -1) {
      if ((tokenttl - currentDate) < (24*60*60*1000)) { 
          console.log('Trying to refresh token')
          return userService.refreshToken();
      }
  }
}

const connectedApp = withTranslation()(connect(mapStateToProps)(App));
export { connectedApp as App }; 
