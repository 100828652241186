import { Icon, Spinner, IPersonaProps, IPeoplePickerItemSelectedProps, PeoplePickerItem, TooltipHost, Modal, Dialog, DialogFooter, DialogType, Text } from '@fluentui/react';
import { ValidationState } from '@fluentui/react/lib/Pickers';
import { FluentProvider, Persona, SelectTabEvent, Button, webLightTheme } from '@fluentui/react-components';
import { IconButton } from '@fluentui/react/lib/Button';
import * as React from 'react';
import i18n from "i18next";
import { userService } from '../../_services/user.service';
import * as DOMPurify from 'dompurify';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import ReactDOM from 'react-dom';
import { FilePicker } from '../_filePicker/FilePicker';
import { Checkmark20Regular, ClockArrowDownload20Regular, PresenceBlocked20Regular } from '@fluentui/react-icons';

export interface Props {
  allCardsCollapsed: boolean;
  item: any;
  userData: any;
}

export interface States {
  collapsed: boolean;
  message: string;
  item: any;
  possibleContacts: any[];
  currentContacts: any[];
  contactsCC: any[];
  contactsBCC: any[];
  replyTo: any[];
  replyToAll: any[];
  replyCcAll: any[];
  showCurrentContacts: boolean;
  loadingEntities: boolean;
  loading: boolean;
  page: number;
  totalPages: number;
  editingMessage: string;
  editMessage: string;
  markdown: any;
  tabSelected: string;
  markdownNew: any;
  tabSelectedNew: string;
  showReply: boolean;
  showCC: boolean;
  showBCC: boolean;
  attachments: any[];
  attachmentsInline: any[];
  attachmentsOutline: any[];
  savingAttachment: boolean;
}

export class FileSent extends React.Component<Props, States> {
  private _isMounted: boolean;
  private throttleTimer: boolean;
  private loadingMoreResults: boolean
  private listener: boolean = false;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.throttleTimer = false;
    this.loadingMoreResults = false;
    this.props = props

    this.state = {
      collapsed: this.props.allCardsCollapsed,
      message: '',
      item: this.props.item,
      possibleContacts: this.props.possibleContacts,
      currentContacts: [],
      contactsCC: [],
      contactsBCC: [],
      replyTo: [],
      replyToAll: [],
      replyCcAll: [],
      showCurrentContacts: false,
      loadingEntities: true,
      loading: true,
      page: this.props.item.page,
      totalPages: this.props.item.totalPages || 0,
      editingMessage: '',
      editMessage: '',
      markdown: null,
      tabSelected: 'write',
      markdownNew: null,
      tabSelectedNew: 'write',
      showReply: false,
      showCC: false,
      showBCC: false,
      attachments: [],
      attachmentsInline: [],
      attachmentsOutline: [],
      savingAttachment: false
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.setState({loading: false})
    const params = new URLSearchParams(document.location.search);
    let messageId = params.get('messageId') || params.get('message') || null
    if (this.state.possibleContacts && this.state.possibleContacts.length > 0) {
      this._isMounted && this.setState({loadingEntities: false, loading: false}, ()=>{
        if (this.props.target || messageId) document.getElementById(messageId || this.props.target)?.scrollIntoView(true)
      })
    } else {
      userService.getRepoUsers('ADMIN').then((response)=>{
        let admins = response.data;
        for (let i=0; i<admins.length; i++) {
          admins[i].key = admins[i].id
          admins[i].text = admins[i].name + (admins[i].surname ? (' ' + admins[i].surname) : '')
        }
        userService.getRepoUsers('INTERNAL').then((response)=>{
          let contacts = response.data;
          for (let i=0; i<contacts.length; i++) {
              contacts[i].key = contacts[i].id
              contacts[i].text = contacts[i].name + (contacts[i].surname ? (' ' + contacts[i].surname) : '')
          }
          this.setState({possibleContacts: contacts.concat(admins)})
          this._isMounted && this.setState({loadingEntities: false, loading: false}, ()=>{
            if (this.props.target || messageId) document.getElementById(messageId || this.props.target)?.scrollIntoView(true)
          })
        }).catch(()=>{
          this._isMounted && this.setState({loadingEntities: false, loading: false})
        })
      }).catch(()=>{
        this._isMounted && this.setState({loadingEntities: false, loading: false})
      })
    }
  }
  
  public componentDidUpdate(prevProps:any, prevState: any) {
    if (prevProps.allCardsCollapsed !== this.props.allCardsCollapsed) {
      this._isMounted && this.setState({collapsed: this.props.allCardsCollapsed})
    }
    if (this.props.item !== prevProps.item) {
      this._isMounted && this.setState({item: this.props.item, totalPages: this.props.item.totalPages, page: this.props.item.page})
    }
    if (this.state.loading !== prevState.loading && !this.listener) {
      document.getElementById('main-view')?.addEventListener("scroll", this.handleInfiniteScroll);
      this.listener = true;
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    document.getElementById('main-view')?.removeEventListener("scroll", this.handleInfiniteScroll, false);
    this.listener = false;
  }

  private getContactName(id: string) {
    let user = this.state.possibleContacts.find((item) => {
      return item.id === id;
    })

    return user ? (user.name || '') + ' ' + (user.surname || '') : ''
  }

  private getContactNameTooltip(id: string) {
    let user = this.state.possibleContacts.find((item) => {
      return item.id === id;
    })

    return (
      user ? <TooltipHost
        content= {
          <div className='m-1'>
            <FluentProvider>
              <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
              <Persona className='mb-2' name={(user.name || '') + ' ' + (user.surname || '')} secondaryText={user.email} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
            </FluentProvider>
          </div>
        }
      >
        <span>{(user.name || '') + ' ' + (user.surname || '')}</span>
      </TooltipHost> : ''
    )
  }

  private getPersona(id: string) {
    let user = this.state.possibleContacts.find((item) => {
      return item.id === id;
    })
    return (
      <TooltipHost
        content= {
          <div className='m-1'>
            <FluentProvider>
              <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
              <Persona className='mb-2' name={(user?.name || '') + ' ' + (user?.surname || '')} secondaryText={user?.email || ''} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
            </FluentProvider>
          </div>
        }
      >
        <Persona className='pe-2' size='extra-small' name={(user?.name || '') + ' ' + (user?.surname || '')} textAlignment='center' avatar={{ color: "colorful" }}/>
      </TooltipHost>
    )
  }

  private loadNextPage() {
    if (this.state.page < (this.state.totalPages - 1)) {
      let page = this.state.page;
      page += 1;
      this.loadingMoreResults = true;
      userService.getConversationMessages(this.state.item.id, page).then((response) => {
        let messages:any = response.data.content;
        let requests:any = [];
        for (let i in messages) {
          for (let j in messages[i].attachments) {
            if (messages[i].attachments[j] && messages[i].attachments[j].inline) {
              requests.push(userService.downloadConversationAttachment(this.state.item.id, messages[i].id, messages[i].attachments[j].id).then((response)=>{
                const byteArray = new Uint8Array(response.data);
                const blob = new Blob([byteArray], { type: messages[i].attachments[j].contentType });
                const url = URL.createObjectURL( blob );
                messages[i].attachments[j].blob = url;
              }).catch((error)=>{
                console.log(error)
              }))
            }
          }
        }
        Promise.all(requests).then(() => {
          let item = this.state.item;
          let userIds:string[] = [];
          let totalPages = response.data.totalPages;
          for (let i in messages) {
            if (!userIds.includes(messages[i].createdByUserId)) {
              userIds.push(messages[i].createdByUserId)
            }
          }
          this.setState({page: page, totalPages: totalPages});
          item.messages = item.messages.concat(messages);
          item.totalPages = totalPages;
          item.page = page;
          this.props.updateConversation(item);
          this.loadingMoreResults = false;
        })
      })
    }
  }

  private throttle = (callback, time) => {
    if (this.throttleTimer) return;
    this.throttleTimer = true;
    setTimeout(() => {
      callback();
      this.throttleTimer = false;
    }, time);
  };

  private handleInfiniteScroll = () => {
    this.throttle(() => {
      const endOfPage =
        (document.getElementById('main-view')?.offsetHeight || 0) + (document.getElementById('main-view')?.scrollTop || 0) >= (document.getElementById('main-view-conversation')?.offsetHeight || 0) - 300;
        if (endOfPage) {
          this.loadNextPage();
        }
    }, 500);
  };

  private _reply() {
    let to = this.state.currentContacts.map((item) => {
      if (item.id && item.email) return item.email
      else return item.key
    })
    let cc = this.state.contactsCC.map((item) => {
      if (item.id && item.email) return item.email
      else return item.key
    })
    let bcc = this.state.contactsBCC.map((item) => {
      if (item.id && item.email) return item.email
      else return item.key
    })
    let attachments:string[] = []
    for (let i in this.state.attachments) {
      if (this.state.message.includes('('+this.state.attachments[i]+')')) {
        attachments.push(this.state.attachments[i])
      }
    }
    attachments = attachments.concat(this.state.attachmentsOutline.map((a)=>{return a.id}))
    userService.createMessage(this.state.item.id, this.state.message, to, cc, bcc, attachments).then((response)=>{
      let item = this.state.item;
      let message = response.data;
      let requests:any = [];
      for (let j in message.attachments) {
        if (message.attachments[j] && message.attachments[j].inline) {
          requests.push(userService.downloadConversationAttachment(item.id, message.id, message.attachments[j].id).then((response)=>{
            const byteArray = new Uint8Array(response.data);
            const blob = new Blob([byteArray], { type: message.attachments[j].contentType });
            const url = URL.createObjectURL( blob );
            message.attachments[j].blob = url;
          }).catch((error)=>{
            console.log(error)
          }))
        }
      }
      Promise.all(requests).then(() => {
        if (!item.messages) item.messages = [];
        item.messages.push(message);
        this.props.updateConversation(item)
        this._isMounted && this.setState({showReply: false, message: '', currentContacts: [], contactsCC: [], contactsBCC: [], tabSelectedNew: 'write', markdownNew: null, attachments: [], attachmentsInline: [], attachmentsOutline: []})
      })
    }).catch((error)=>{
      console.log(error)
    })
  }

  private _updateMessage(message, id) {
    let attachments:string[] = []
    for (let i in this.state.attachments) {
      if (this.state.editMessage.includes('('+this.state.attachments[i]+')')) {
        attachments.push(this.state.attachments[i])
      }
    }
    for (let i in message.attachments) {
      if (this.state.editMessage.includes('('+message.attachments[i].id+')')) {
        attachments.push(message.attachments[i].id)
      }
    }
    attachments = attachments.concat(this.state.attachmentsOutline.map((a)=>{return a.id}))
    userService.updateMessage(this.state.item.id, id, this.state.editMessage, attachments).then((response)=>{
      let item = this.state.item;
      for (let i in item.messages) {
        if (item.messages[i].id === id) {
          item.messages[i] = response.data;
          let requests:any = [];
          for (let j in item.messages[i].attachments) {
            if (item.messages[i].attachments[j] && item.messages[i].attachments[j].inline) {
              requests.push(userService.downloadConversationAttachment(item.id, item.messages[i].id, item.messages[i].attachments[j].id).then((response)=>{
                const byteArray = new Uint8Array(response.data);
                const blob = new Blob([byteArray], { type: item.messages[i].attachments[j].contentType });
                const url = URL.createObjectURL( blob );
                item.messages[i].attachments[j].blob = url;
              }).catch((error)=>{
                console.log(error)
              }))
            }
          }
          Promise.all(requests).then(() => {
            this._closeEditMode(id)
            this.props.updateConversation(item)
          })
        }
      }
    }).catch((error)=>{
      console.log(error)
    })
  }

  private _deleteMessage(id) {
    userService.deleteMessage(this.state.item.id, id).then(()=>{
      let item = this.state.item;
      item.messages = item.messages.filter((x)=>{return x.id !== id})
      this.props.updateConversation(item)
    }).catch((error)=>{
      console.log(error)
    })
  }

  private _openEditMode(id) {
    let message = this.state.item.messages.find((item)=>{return item.id === id})
    this._isMounted && this.setState({editingMessage: id, editMessage: message.content, attachments: [], attachmentsInline: [], attachmentsOutline: message.attachments.filter((a)=>{return !a.inline}), showReply: false})
  }

  private _closeEditMode(id) {
    let message = this.state.item.messages.find((item)=>{return item.id === id})
    this._isMounted && this.setState({editingMessage: '', editMessage: message.content, attachments: [], attachmentsInline: [], attachmentsOutline: []})
  }

  private convertToMarkdown(item, message = '') {
    try {
      var MarkdownIt = require('markdown-it');
      var emoji = require('markdown-it-emoji');
      var md = new MarkdownIt();
      md.use(emoji);
      var result = md.render(DOMPurify.sanitize(message));
      const attachmentsInline = item.attachments.filter((a)=>{return a.inline})
      for (let i in attachmentsInline) {
        var replace = "src=\""+attachmentsInline[i].id+"\"";
        result = result.replace(replace, 'src="' + attachmentsInline[i].blob + '"')
      }
      let markdown:any = { __html: result };
      return markdown;
    } catch {
      return message;
    }

  }

  private getMarkdown(messageId:string) {
    var MarkdownIt = require('markdown-it');
    var emoji = require('markdown-it-emoji');
    var md = new MarkdownIt();
    md.use(emoji);
    var result = md.render(DOMPurify.sanitize(this.state.editMessage || ""));

    const {attachmentsInline, item} = this.state;
    let message = item.messages.find(m => {return m.id === messageId})
    if (message) {
      console.log(message.attachments)
      for (let i in message.attachments) {
        var replace = "src=\""+message.attachments[i].id+"\"";
        result = result.replace(replace, 'src="' + message.attachments[i].blob + '"')
      }
    }
    for (let i in attachmentsInline) {
      var replace = "src=\""+attachmentsInline[i].id+"\"";
      result = result.replace(replace, 'src="' + attachmentsInline[i].file + '"')
    }

    let markdown = { __html: result };
    this.setState({markdown: markdown})
  }

  private _handleTabSelect = (e:SelectTabEvent, data: any, id:string): void => {
    if (data.value === 'preview' && this.state.tabSelected === 'write') {
      this.getMarkdown(id);
    }
    this._isMounted && this.setState({
      tabSelected: data.value
    });
  };

  private getMarkdownNew() {
    let message = this.state.message || "";
    var MarkdownIt = require('markdown-it');
    var emoji = require('markdown-it-emoji');
    var md = new MarkdownIt();
    md.use(emoji);
    var result = md.render(DOMPurify.sanitize(message));

    const {attachmentsInline} = this.state;
    for (let i in attachmentsInline) {
      var replace = "src=\""+attachmentsInline[i].id+"\"";
      result = result.replace(replace, 'src="' + attachmentsInline[i].file + '"')
    }

    let markdown = { __html: result };
    this.setState({markdownNew: markdown})
  }

  private _handleTabSelectNew = (e:SelectTabEvent, data: any): void => {
    if (data.value === 'preview' && this.state.tabSelected === 'write') {
      this.getMarkdownNew();
    }
    this._isMounted && this.setState({
      tabSelectedNew: data.value
    });
  };

  public render() {

    const filterPromise = (personasToReturn: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
      return personasToReturn;
    };

    const filterContactsByText = (
      filterText: string,
      currentPersonas: IPersonaProps[],
      limitResults?: number,
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
      if (filterText) {
          let filteredPersonas: IPersonaProps[] = this.state.possibleContacts.filter(item => doesTextStartWith(item, filterText));      
          filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
          filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
          return filterPromise(filteredPersonas);
      } else {
          return [];
      }
    };

    const menuPropsAttachment: any = (attachment, message?) => {
      return {
        items: [
          {
            key: 'downloadAttachment',
            text: i18n.t('app:downloadAttachment'),
            iconProps: { iconName: 'Download' },
            onClick: ()=>{this.downloadAttachment(message, attachment)},
          },
          {
            key: 'openInDrive',
            text: i18n.t('app:openInDrive'),
            iconProps: { iconName: 'OpenFile' },
            onClick: ()=>{window.open('https://drive.'+this.props.userData.repository.deploymentDomain + '/path/' + attachment.driveFileId, '_blank')},
            hidden: !message || !attachment.driveFileId
          },
        ],
        directionalHintFixed: true,
      }
    };

    const menuPropsAttachmentEdit: any = (attachment, message?) => {
      return {
        items: [
          {
            key: 'downloadAttachment',
            text: i18n.t('app:downloadAttachment'),
            iconProps: { iconName: 'Download' },
            onClick: ()=>{this.downloadAttachment(message, attachment)},
          },
          {
            key: 'openInDrive',
            text: i18n.t('app:openInDrive'),
            iconProps: { iconName: 'OpenFile' },
            onClick: ()=>{window.open('https://drive.'+this.props.userData.repository.deploymentDomain + '/path/' + attachment.driveFileId, '_blank')},
            hidden: !message || !attachment.driveFileId
          },
          {
            key: 'delete',
            text: i18n.t('app:delete'),
            iconProps: { iconName: 'Delete' },
            onClick: ()=>{
              let attachments = this.state.attachmentsOutline;
              attachments = attachments.filter((a)=>{return a.id !== attachment.id})
              this._isMounted && this.setState({attachmentsOutline: attachments})
            }
          },
        ],
        directionalHintFixed: true,
      }
    };

    const renderItemWithSecondaryText: any = (props: IPeoplePickerItemSelectedProps) => {
      const newProps = {
        ...props,
        item: {
          ...props.item,
          ValidationState: ValidationState.valid,
          //showSecondaryText: true,
        },
      };
  
      return <PeoplePickerItem {...newProps} className={"conversationDestinatary " + (props.item.id ? "internal" : "external") } />;
    }
    const menuProps: any = (item, i) => {
      return {
        items: [
          {
            key: 'createTask',
            text: i18n.t('app:createTask'),
            iconProps: { iconName: 'IconSetsFlag' },
            onClick: ()=>{this.props.headerRef && this.props.headerRef._showTaskForm(item.id)}
          },
          {
            key: 'editMessage',
            text: i18n.t('app:editMessage'),
            iconProps: { iconName: 'Edit' },
            onClick: ()=>{this._openEditMode(item.id)},
            hidden: this.props.userData && this.props.userData.user.id !== item.createdByUserId
          },
          {
            key: 'deleteMessage',
            text: i18n.t('app:deleteMessage'),
            iconProps: { iconName: 'Delete' },
            onClick: ()=>{this.props.headerRef && this.props.headerRef.showConfirmDialog(i18n.t('app:deleteMessage'), i18n.t('app:areYouSureMessage')).then(result=>{
              if (result) {
                this._deleteMessage(item.id)
              }
            })},
            hidden: i === 0 || (this.props.userData && this.props.userData.user.id !== item.createdByUserId)
          },
        ],
        directionalHintFixed: true,
      }
    };

    var onDragEnter = (event) => {
      event.target.style.background = "#C7E0F4";
    }

    var onDragLeave = (event) => {
      event.target.style.background = "transparent";
    }

    var onDragOver = (event) => {
      event.stopPropagation();
      event.preventDefault();
    }

    var saveAttachments = (files, id) => {
      for (let i in files) {
        if (files[i] && files[i].size >= 10000000) {
          this.props.headerRef.showAlertDialog('Error', "Maximum attachment allowed size: 10 Mb.")
        } else {
          const dropFormData = new FormData();
          const fileExtension = files[i].name.split('.').pop();
          const inlineTypes = ['jpg', 'jpeg', 'png', 'svg' , 'jfif', 'pjpeg', 'pjp', 'gif', 'webp'];
          dropFormData.append('attachment', files[i], files[i].name);
          dropFormData.append('inline', inlineTypes.includes(fileExtension) ? 'true' : 'false');
          this._isMounted && this.setState({savingAttachment: true});
          userService.saveAttachment(dropFormData).then((response) => {
            if (inlineTypes.includes(fileExtension)) {
              let attachments = this.state.attachments
              attachments.push(response.data.id)
              let attachmentsInline = this.state.attachmentsInline
              attachmentsInline.push({id: response.data.id, file: URL.createObjectURL(files[i])})
              this._isMounted && this.setState({attachments: attachments, attachmentsInline: attachmentsInline})
              const insertText = `![${response.data.name}](${response.data.id})`
              id === 'response-textbox' ? this.insertAtCursor(document.getElementById(id), insertText) :  this.insertAtCursor(document.getElementById(id), insertText, true);
              this._isMounted && this.setState({savingAttachment: false});
            } else {
              let attachments = this.state.attachments
              attachments.push(response.data.id)
              let attachmentsOutline = this.state.attachmentsOutline
              attachmentsOutline.push({...response.data, file: files[i]})
            }
            this._isMounted && this.setState({savingAttachment: false});
          }).catch((error) => {
            console.log(error)
            this._isMounted && this.setState({savingAttachment: false});
            if (error && error.data && error.data.message && this.props.headerRef) {
              this.props.headerRef.showAlertDialog('Error', error.data.message)
            } else {
              this.props.headerRef.showAlertDialog('Error', "An error occurred trying to save the attachment. Please try again.")
            }
          })
        }
      }
    }

    var onFileDrop = (event, id) => {
      event.stopPropagation();
      event.preventDefault();

      event.target.style.background = "transparent";

      if(event.dataTransfer.files.length > 0) {
        const files:File[] = Array.from(event.dataTransfer.files)
        saveAttachments(files, id)
      }
    }

    var onPaste = (e, id) => {
      // Prevent the default behavior, so you can code your own logic.
      e.preventDefault();
      if (!e.clipboardData.files.length) {
        return;
      }
      const files:File[] =  Array.from(e.clipboardData.files)
      saveAttachments(files, id)
    }

    return(
      this.state.loading ? <Spinner className='mt-4'/> : 
      <div className='h-100 d-flex flex-column'>
        <div style={{background: 'white', borderBottom: '1px solid #f2f2f2'}}>
          <div>
            <div className='d-flex align-items-center mx-3 mt-3' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
              <span>File sync sent to</span>
              <span className='mt-1 ps-2'>{this.getPersona(this.state.item.createdByUserId)}</span>
            </div>
          </div>
          <div className='file-info' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
            <div className='d-flex align-items-center mx-3 mt-3'>
              <Icon {...getFileTypeIconProps({ extension: 'docx', size: 24, imageFileType: 'svg' }) } />
              <span className='ms-2'>{'Monthly report.docx'}</span>
              <span className='ms-2' style={{color: 'grey'}}>{'(456KB)'}</span>
            </div>
          </div>
          {this.state.item.fileAccepted ? <div className='d-flex align-items-center mx-3 my-3'>
            <span className='ms-auto'>{'File received'}</span>
            <Checkmark20Regular className='ms-2' style={{color: 'green'}}/>
          </div> : this.state.item.fileDeclined ? <div className='d-flex align-items-center mx-3 my-3'>
            <span className='ms-auto'>{'File declined'}</span>
            <PresenceBlocked20Regular className='ms-2' style={{color: 'firebrick'}}/>
          </div> : <div className='d-flex align-items-center mx-3 my-3'>
            <span className='ms-auto'>{'Waiting for approval'}</span>
            <ClockArrowDownload20Regular className='ms-2'/>
          </div>}
        </div>
        <div id='main-view' className='flex-grow-1' style={{overflowY: 'auto', backgroundColor: 'rgb(250, 249, 248)'}}>
          <div id='main-view-conversation'>

          </div>
        </div>
      </div>
    )
  }

  private validateInput(input: string): ValidationState {
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  }

  private getUserFromEmail(email: string) {
    const {possibleContacts} = this.state;
    let user  = possibleContacts.find(user => {return user.email === email})
    return user ? <Persona key={email} name={(user.name || '') + ' ' + (user.surname || '')} size='extra-small' avatar={{ color: "colorful"}}/> 
    : <Persona className='me-2' key={email} name={email} size='extra-small' avatar={{ color: "colorful"}}/> 
  }

  private insertAtCursor(myField, myValue, messageEdit = false) {
    if (myField.selectionStart || myField.selectionStart == '0') {
        var startPos = myField.selectionStart;
        var endPos = myField.selectionEnd;
        if (messageEdit) {
          this._isMounted && this.setState({editMessage: myField.value.substring(0, startPos)
            + myValue
            + myField.value.substring(endPos, myField.value.length)}, () => {
              myField.selectionStart = startPos + myValue.length;
              myField.selectionEnd = startPos + myValue.length;
          })
        } else {
          this._isMounted && this.setState({message: myField.value.substring(0, startPos)
            + myValue
            + myField.value.substring(endPos, myField.value.length)}, () => {
              myField.selectionStart = startPos + myValue.length;
              myField.selectionEnd = startPos + myValue.length;
          })
        }
    } else {
      if (messageEdit) {
        this._isMounted && this.setState({editMessage: myField.value + myValue})
      } else {
        this._isMounted && this.setState({message: myField.value + myValue})
      }
    }
  }

  private _showFilePicker() {
    var input = document.createElement('input');
    input.type = 'file';
    input.multiple = true

    input.onchange = e => {
      let event:any = e as Event;
      event.stopPropagation();
      event.preventDefault();
      const files:File[] = Array.from(event.target.files)
      if(files.length > 0) {
        for (let i in files) {
          if (files[i] && files[i].size >= 25000000) {
            this.props.headerRef.showAlertDialog('Error', "Maximum attachment allowed size: 25 Mb.")
          } else {
            const dropFormData = new FormData();
            dropFormData.append('attachment', files[i], files[i].name);
            dropFormData.append('inline', 'false');
            this._isMounted && this.setState({savingAttachment: true});
            userService.saveAttachment(dropFormData).then((response) => {
              let attachments = this.state.attachments
              attachments.push(response.data.id)
              let attachmentsOutline = this.state.attachmentsOutline
              attachmentsOutline.push({...response.data, file: files[i]})
              this._isMounted && this.setState({savingAttachment: false, attachments: attachments, attachmentsOutline: attachmentsOutline})
            }).catch((error) => {
              console.log(error)
              this._isMounted && this.setState({savingAttachment: false});
              if (error && error.data && error.data.message && this.props.headerRef) {
                this.props.headerRef.showAlertDialog('Error', error.data.message)
              } else {
                this.props.headerRef.showAlertDialog('Error', "An error occurred trying to save the attachment. Please try again.")
              }
            })
          }
        }
      }
    }

    input.click();
  }

  private downloadAttachment(message, item) {
    userService.downloadConversationAttachment(this.state.item.id, message.id, item.id, true).then((response)=>{
      var url = window.URL.createObjectURL(response.data);
      var anchorElem = document.createElement("a");
      anchorElem.href = url;
      anchorElem.download = item.name;

      document.body.appendChild(anchorElem);
      anchorElem.click();

      document.body.removeChild(anchorElem);
    })
  }

  private openSynergyOSpicker() {
    this._openFilePicker().then((result:any)=>{
        if (result && result.length > 0) {
          let item = this.state.item
          item.fileAccepted = true;
          this.props.updateConversation(item)
        }
    }).catch((error)=>{
      if (error && this.props.headerRef) {
        this.props.headerRef.showAlertDialog('Error', "An error occurred trying to open Drive Filepicker. Please try again.")
      }
    })
  }

  private _openFilePicker = async () => {
    return await new Promise((resolve, reject) => {
        var showDialog = true;

        var confirm = (id) => {
          showDialog = false;
          renderDialog();
          resolve(id);
        }

        var cancel = () => {
          showDialog = false;
          renderDialog()
          reject()
        }

        var div = document.createElement('div');
        var renderDialog = () => {
          ReactDOM.render(
          <Modal
            isOpen={showDialog}
            onDismiss={()=>{cancel()}}
            isBlocking={true}
            styles={{main: {maxWidth: '1100px', width: '100%', maxHeight: '700px'}}}
            className="file-picker"
          >
            <div className="w-100 h-100">
              <div className="d-flex flex-row noselect align-items-center" style={{height: '28px', backgroundColor: '#2c2c2c', color: 'white', fontWeight: 400, fontSize: '14px'}}>
                <Icon iconName='DriveSynergyOS' className='pe-2 ps-2' style={{height: '24px'}}/><span style={{fontWeight: 600}}>SynergyOS File Picker</span>
                <IconButton onClick={()=>{showDialog=false;renderDialog()}} iconProps={{iconName: 'Cancel'}} className='me-0 ms-auto file-picker-close-button' style={{height: '28px', color: 'white'}}/>
              </div>
              <FilePicker
                {...this.props}
                startFolder={this.props.userData.repository.id}
                location={undefined}
                database={undefined}
                isOnline={true}
                maintenance={false}
                fileExplorerType='fileExplorer'
                userData={this.props.userData}
                repoData={this.props.userData.repository} 
                foldersList={this.props.foldersList}
                newFolder={null}
                repoUsers={this.props.repoUsers}
                callback={(id) => {confirm(id)}}
                selectDocument={false}
              />
            </div>
          </Modal>
          , div
          )
        }
      renderDialog();
    })
  }

  private _confirmAction = async () => {
    return await new Promise((resolve, reject) => {
        var showDialog = true;
        var loading= false;

        var confirm = () => {
          showDialog = false;
          renderDialog();
          resolve(true);
        }

        var cancel = () => {
          showDialog = false;
          renderDialog()
          reject()
        }

        var div = document.createElement('div');
        var renderDialog = () => {
          ReactDOM.render(
            <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: i18n.t('app:declineFile'),
              onDismiss: ()=> {showDialog = false}
            }}
            modalProps={{
              isBlocking: true,
              styles: { main: { maxWidth: 360 } },
              dragOptions: undefined,
              className: "ask-delete-item-dialog",
              onDismiss: ()=> {showDialog = false}
            }}
            styles={{main: {minHeight: '100px'}}}
          >
            <FluentProvider theme={webLightTheme} className="w-100">
              <Text block variant={'medium'}>
                {i18n.t('app:declineFile2')}
              </Text>
              
              <DialogFooter>
                <Button disabled={loading} onClick={()=>{cancel()}}>{i18n.t('users:cancel')}</Button>
                <Button appearance='primary' disabled={loading} autoFocus onClick={ ()=>{confirm()} }>{i18n.t('users:decline')}</Button>
              </DialogFooter>
            </FluentProvider>
          </Dialog>
          , div
          )
        }
      renderDialog();
    })
  }

  private declineFile() {
    this._confirmAction().then((result:any)=>{
      console.log(result)
      if (result) {
        let item = this.state.item
        item.fileDeclined = true;
        this.props.updateConversation(item)
      }
    }).catch((error)=>{
      if (error && this.props.headerRef) {
        this.props.headerRef.showAlertDialog('Error', "An error occurred trying decline the file sync.")
      }
    })
  }

}

function doesTextStartWith(item: any, filterText: string): boolean {
  let email = item.email
  return item.text.toLowerCase().indexOf(filterText.toLowerCase()) > -1 || (email && email.toLowerCase().indexOf(filterText.toLowerCase()) > -1);
}

function removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
  return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
}

function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
  if (!personas || !personas.length || personas.length === 0) {
      return false;
  }
  return personas.filter(item => item.text === persona.text).length > 0;
}
