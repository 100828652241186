import * as React from 'react';
import { Resizable } from 're-resizable';
import { ActionButton, CheckboxVisibility, ConstrainMode, DetailsList, DetailsListLayoutMode, Icon, IconButton, IDetailsHeaderProps, IDetailsListCheckboxProps, IRenderFunction, Persona, PersonaSize, ScrollablePane, ScrollbarVisibility, SearchBox, SelectionMode, ShimmeredDetailsList, TooltipHost, Selection } from '@fluentui/react';
import i18n from "i18next";
import { SelectTabEvent, Tab, TabList } from '@fluentui/react-components';


export interface Props {
  id: string;
  item: any;
}

export interface State {
  repoData: any,
  showRightPanel: boolean,
  panelHeight: string,
  panelWidth: any
  item: any
  activeTab: string;
  showEditCompanyForm: boolean;
  relationsCompanies: boolean;
  relationsContacts: boolean;
  relationsProjects: boolean;
  showFormRelations: boolean;
  showCurrentCompanies: boolean;
  showCurrentContacts: boolean;
  showCurrentProjects: boolean;
  currentCompanies: any[];
  currentContacts: any[];
  currentProjects: any[];
  sortedAZ: boolean;
  sortedFields: any[];
  loadingFields: boolean;
  selItem: any;
}

export class DataPanel extends React.Component<Props, State> {
  private _isMounted: boolean;
  private _windowWidth: number;
  private _templateId: string = '';
  private _selection: Selection;


  private defaultProperties = {
    name: i18n.t('app:name'),
    domain: i18n.t('app:domain'),
    contactIds: i18n.t('app:contactIds'),
    urls: i18n.t('app:urls'),
    phoneNumbers: i18n.t('app:phoneNumbers'),
    emailAddresses: i18n.t('app:emailAddresses'),
    locationAddresses: i18n.t('app:locationAddresses'),
    nickName: i18n.t('app:nickName'),
    description: i18n.t('app:description'),
    trash: i18n.t('app:trash'),
    createdAt: i18n.t('app:createdAt'),
    modifiedAt: i18n.t('app:modifiedAt'),
  }

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._windowWidth = window.innerWidth;

    this._selection = new Selection({
      onSelectionChanged: () => {
        let selItem = this._selection.getSelection().length === 1 ? this._selection.getSelection()[this._selection.getSelection().length - 1] : null;
        if (selItem) {
          this.props.callbackFunction({currentEmail: selItem})
        } else {
          this.props.callbackFunction({currentEmail: this.state.item})
        }
      }
    });

    this.props = props

   var panelWidth = "380px"
   if (typeof(localStorage.getItem("detailsPanelPrefs")) === "string") {
      var panelPrefs = JSON.parse(localStorage.getItem("detailsPanelPrefs") || "[]");
      panelWidth = panelPrefs.width
   }

    this.state = {
      repoData: props.repoData,
      showRightPanel: props.showRightPanel,
      panelHeight: 'auto',
      panelWidth: panelWidth,
      item: this.props.item,
      activeTab: 'summary',
      showEditCompanyForm: false,
      relationsCompanies: true,
      relationsContacts: true,
      relationsProjects: true,
      showFormRelations: false,
      showCurrentCompanies: true,
      showCurrentContacts: true,
      showCurrentProjects: true,
      currentCompanies: [],
      currentContacts: [],
      currentProjects: [],
      sortedAZ: true,
      sortedFields: [],
      loadingFields: true,
      selItem: null
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    window.addEventListener('resize', this._onWindowResize);
  }

  public componentDidUpdate(prevProps, prevState) {
    if (prevProps.showRightPanel !== this.props.showRightPanel) {
      this._isMounted && this.props.callbackFunction({showRightPanel: this.props.showRightPanel})
      this._isMounted && this.setState({showRightPanel: this.props.showRightPanel})
    }
    if (prevProps.item !== this.props.item) {
      this._isMounted && this.setState({item: this.props.item})
    }

    if (prevProps.item !== this.props.item) {
      this._selection.setAllSelected(false);
    }

  }

  public componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this._onWindowResize, false);
  }

  private callbackFunction = (childData) => {
    this._isMounted && this.setState(childData)
  }

  private _onWindowResize = () => {
    if (window.innerWidth <= 980 && window.innerWidth < this._windowWidth) {
      this._isMounted && this.props.callbackFunction({showRightPanel: false})
      this.setState({showRightPanel: false});
    }
    this._windowWidth = window.innerWidth;
  }

  private _handleLinkClick = (e:SelectTabEvent, data: any): void => {
    this._isMounted && this.setState({
      activeTab: data.value
    });
    if (this._isMounted && this.state.activeTab ===  data.value) this.props.callbackFunction({showRightPanel: !this.state.showRightPanel})
    else if(this._isMounted && this.state.activeTab !==  data.value) this.props.callbackFunction({showRightPanel: true})
  };

  private removeFrom(item: any, type: string) {
    if (type === 'Project') this.setState({currentProjects: this.state.currentProjects.filter((i)=>{return i.id !== item.id})})
    else if (type === 'Company') this.setState({currentCompanies: this.state.currentCompanies.filter((i)=>{return i.id !== item.id})})
    else if (type === 'Contact') this.setState({currentContacts: this.state.currentContacts.filter((i)=>{return i.id !== item.id})})
  }

  private toggleAll() {
    if (this.state.showCurrentCompanies && this.state.showCurrentContacts && this.state.showCurrentProjects) {
      this._isMounted && this.setState({showCurrentCompanies: false, showCurrentContacts: false, showCurrentProjects: false})
    } else {
      this._isMounted && this.setState({showCurrentCompanies: true, showCurrentContacts: true, showCurrentProjects: true})
    }
  }

  private toggleSort() {
    this._isMounted && this.setState({
      sortedAZ: !this.state.sortedAZ,
      currentCompanies: this.sortItems(this.state.currentCompanies, !this.state.sortedAZ),
      currentContacts: this.sortItems(this.state.currentContacts, !this.state.sortedAZ),
      currentProjects: this.sortItems(this.state.currentProjects, !this.state.sortedAZ),
    })
  }

  private sortItems(items, asc:boolean) {
    if (asc) {
      return items.slice(0).sort((a: any, b: any) => {
        if (a.name < b.name || (a.firstName + ' ' + a.lastName) < (b.firstName + ' ' + b.lastName)) return -1;
        if (a.name > b.name || (a.firstName + ' ' + a.lastName) > (b.firstName + ' ' + b.lastName)) return 1;
        else return 0;
      })
    } else {
      return items.slice(0).sort((a: any, b: any) => {
        if (a.name < b.name || (a.firstName + ' ' + a.lastName) < (b.firstName + ' ' + b.lastName)) return 1;
        if (a.name > b.name || (a.firstName + ' ' + a.lastName) > (b.firstName + ' ' + b.lastName)) return -1;
        else return 0;
      })
    }
  }

  public render() {
    const { showRightPanel, item, activeTab } = this.state;

    const columnsCompanies:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentCompanies: !this.state.showCurrentCompanies})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse') || ''}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentCompanies ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:companies') + ' (' + this.state.currentCompanies.length + ')' }</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex align-items-center h-100" style={{userSelect:'none'}}>
            <Persona
              key={ item.id }
              size={PersonaSize.size24}
              className="persona mr-2"
              text={item.name}
              hidePersonaDetails
              styles={{root: {marginLeft: '33px'} }}
            />
            <div>
              <p className='p-0 m-0' style={{fontSize: 12, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.name}</p>
              <p className='p-0 m-0' style={{fontSize: 10, color:'rgb(96, 94, 92)'}}><a target='_blank' href={'https://'+item.domain}>{item.domain}</a></p>
            </div>
            <TooltipHost
              content={i18n.t('app:remove') || ''}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
            >
              <IconButton className='ml-auto remove-onhover' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,item.id.split('-')[0])}}/>
            </TooltipHost>
          </div>
        </>)
      }
    }]
  
    const columnsContacts:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentContacts: !this.state.showCurrentContacts})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:expand/collapse') || ''}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentContacts ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:contacts') + ' (' + this.state.currentContacts.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex align-items-center h-100" style={{userSelect:'none'}}>
            <Persona
              key={ item.id }
              size={PersonaSize.size24}
              className="persona mr-2"
              text={item.firstName + ' ' + item.lastName}
              hidePersonaDetails
              styles={{root: {marginLeft: '33px'} }}
            />
            <div>
              <p className='p-0 m-0' style={{fontSize: 12, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.firstName + ' ' + item.lastName}</p>
              <p className='p-0 m-0' style={{fontSize: 10, color:'rgb(96, 94, 92)'}}><a target='_blank' href={'mailto:'+(item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email)}>{item.emailAddresses && item.emailAddresses[0] && item.emailAddresses[0].email}</a></p>
            </div>
            <TooltipHost
              content={i18n.t('app:remove') || ''}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
            >
              <IconButton className='ml-auto remove-onhover' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,item.id.split('-')[0])}}/>
            </TooltipHost>
          </div>
        </>)
      }
    }]
  
    const columnsProjects:any[] = [{
      key: 'name',
      name: <div 
      onClick={()=>{this._isMounted && this.setState({showCurrentProjects: !this.state.showCurrentProjects})}} 
      className='d-flex align-items-center relation-header'>
        <TooltipHost
          content={i18n.t('app:remove') || ''}
          calloutProps={{ gapSpace: 0 }}
          styles={{root: {marginLeft: 'auto', height: '36px' }}}
        >
          <IconButton className='mr-2' iconProps={{iconName: this.state.showCurrentProjects ? 'ChevronDown' : 'ChevronRight'}}/>
        </TooltipHost>
        <span>{i18n.t('app:projects') + ' (' + this.state.currentProjects.length + ')'}</span>
      </div>,
      fieldName: 'name',
      //isSorted: true,
      isPadded: false,
      onRender: (item)=>{
        return (<>
          <div key={item.id} className="d-flex align-items-center h-100" style={{userSelect:'none'}}>
          <div className='d-flex align-items-center'>
            <Persona
              key={ item.id }
              size={PersonaSize.size24}
              className="persona mr-2"
              text={item.name}
              hidePersonaDetails
              styles={{root: {marginLeft: '33px'} }}
            />
            <div>
              <p className='p-0 m-0' style={{fontSize: 12, fontWeight: 400, color: 'rgb(50, 49, 48)'}}>{item.name}</p>
              <p className='p-0 m-0' style={{fontSize: 10, color:'rgb(96, 94, 92)'}}><a target='_blank' href='#'>Link</a></p>
            </div>
            </div>
            <TooltipHost
              content={i18n.t('app:remove') || ''}
              calloutProps={{ gapSpace: 0 }}
              styles={{root: {marginLeft: 'auto'}}}
            >
              <IconButton className='ml-auto remove-onhover' iconProps={{iconName:'Cancel'}} style={{color:'red'}} onClick={()=>{this.removeFrom(item,item.id.split('-')[0])}}/>
            </TooltipHost>
          </div>
        </>)
      }
    }]

    const onRenderDetailsHeaderCompanies = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center'>
          {defaultRender!({
            ...props
          })}
        </div>
      );
    }

    const onRenderDetailsHeaderContacts = (props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center'>
          {defaultRender!({
            ...props
          })}
        </div>
      );
    }

    const onRenderDetailsHeaderProjects =(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>) => {
      return (
        <div className='d-flex align-items-center' style={{backgroundColor: 'white'}}>
          {defaultRender!({
            ...props
          })}
        </div>
      );
    }

    return(
      item ?
        <div className='d-flex'>
        {showRightPanel && <Resizable
          size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
          onResizeStop={(e, direction, ref, d) => {
            var currentWidth = this.state.panelWidth.match(/\d+/)[0];
            this.setState({
              panelWidth: +currentWidth + +d.width + "px"
            });
            this._saveResizeState();
          }}
          enable={{ top:false, right:false, bottom:false, left:true, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
          minWidth={320}
          maxWidth={440}
          handleStyles= {{
            left: {
              "left": "0px",
              "width": "6px"
            }
          }}
          handleClasses={{"left": "resize-handle"}}
        >
          <div className="details-panel pt-0 pb-3 px-3 flex-grow-1">
            {!item.type && <>
              {
              activeTab === "summary" ? 
              <div>
              <div className="text-left py-3 pb-2 sticky-top" style={{background: '#f2f2f2', borderBottom: '1px solid #bfbfbf'}}>
                <p className="lead font-weight-bold m-0">{i18n.t('app:summary')}</p>
              </div>
              <div className='d-none align-items-center pb-2'>
                <div className='mr-3 d-flex' style={{background: 'white', borderRadius: '40px', height: '64px', width: '64px'}}>
                  <img className='d-none m-auto' src={ process.env.PUBLIC_URL + "/img/icon_synergy.png"} style={{width: '90%'}} />
                </div>
                <div>
                  <p className="m-0" style={{fontSize: '20px'}}>{item.name}</p>
                </div>
              </div>
              <div className='mt-2 justify-content-center'>
              { this.props.page === 'emails' ?
                <>
                  <div className='d-flex align-items-center pt-2'>
                    <TooltipHost
                      content={i18n.t('app:expand/collapseAll') || ''}
                      calloutProps={{ gapSpace: 0 }}
                      styles={{root: {marginLeft: '8px', marginRight: '8px'}}}
                    >
                      <IconButton
                        onClick={()=>this.toggleAll()} 
                        className='expand-icon' 
                        iconProps={{iconName: this.state.showCurrentCompanies && this.state.showCurrentContacts && this.state.showCurrentProjects ? 'CollapseContent' : 'ExploreContent', styles: {root: {fontSize: 18, color: 'rgb(0, 120, 212)'}}}} 
                      />
                    </TooltipHost>
                    <span style={{fontWeight: 600, fontSize: 14}}>{i18n.t('app:relations')}</span>
                    <TooltipHost
                      key={'sort'}
                      content={i18n.t('app:switchOrder') || ''}
                      id={'sort'}
                      calloutProps={{ gapSpace: 0 }}
                      styles={{root: {marginLeft: 'auto'}}}
                    >
                      <ActionButton
                        onClick={()=>this.toggleSort()} 
                        className='expand-icon'
                        text={i18n.t('app:ByName')}
                        iconProps={{iconName: this.state.sortedAZ ? 'SortDown' : 'SortUp'}}
                        style={{height: '32px'}} 
                      />
                    </TooltipHost>
                  </div>
                  <div className='relation-list panel'>
                    <ShimmeredDetailsList
                      className={this.state.currentCompanies.length === 0 || !this.state.showCurrentCompanies ? 'mt-3 height-fix' : 'mt-3'}
                      items={this.state.showCurrentCompanies ? this.state.currentCompanies : []}
                      columns={columnsCompanies}
                      onRenderDetailsHeader={onRenderDetailsHeaderCompanies}
                      selectionMode={SelectionMode.none}
                      selectionPreservedOnEmptyClick={true}
                      setKey="set"
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible={true}
                      ariaLabelForShimmer="Content is being fetched"
                      enterModalSelectionOnTouch={true}
                      shimmerLines={12}
                      constrainMode={ConstrainMode.unconstrained}
                    />
                    <ShimmeredDetailsList
                      className={this.state.currentContacts.length === 0 || !this.state.showCurrentContacts ? 'mt-4 height-fix' : 'mt-4'}
                      items={this.state.showCurrentContacts ? this.state.currentContacts : []}
                      columns={columnsContacts}
                      onRenderDetailsHeader={onRenderDetailsHeaderContacts}
                      selectionMode={SelectionMode.none}
                      selectionPreservedOnEmptyClick={true}
                      setKey="set"
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible={true}
                      ariaLabelForShimmer="Content is being fetched"
                      enterModalSelectionOnTouch={true}
                      shimmerLines={12}
                      constrainMode={ConstrainMode.unconstrained}
                    />
                    <ShimmeredDetailsList
                      className={this.state.currentProjects.length === 0 || !this.state.showCurrentProjects ? 'mt-4 height-fix' : 'mt-4'}
                      items={this.state.showCurrentProjects ? this.state.currentProjects : []}
                      columns={columnsProjects}
                      onRenderDetailsHeader={onRenderDetailsHeaderProjects}
                      selectionMode={SelectionMode.none}
                      selectionPreservedOnEmptyClick={true}
                      setKey="set"
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible={true}
                      ariaLabelForShimmer="Content is being fetched"
                      enterModalSelectionOnTouch={true}
                      shimmerLines={12}
                      constrainMode={ConstrainMode.unconstrained}
                    />
                  </div>
                </>
              : null }
              </div>
              </div>
              : activeTab === "details" ? 
              <div>
                <div className="text-left py-3 pb-2 sticky-top" style={{borderBottom: '1px solid #bfbfbf'}}>
                  <p className="lead font-weight-bold m-0">{i18n.t('app:details')}</p>
                </div>
                <div className='d-none align-items-center pb-2'>
                  <div className='mr-3 d-flex' style={{background: 'white', borderRadius: '40px', height: '64px', width: '64px'}}>
                    <img className='d-none m-auto' src={ process.env.PUBLIC_URL + "/img/icon_synergy.png"} style={{width: '90%'}} />
                  </div>
                  <div>
                    <p className="m-0" style={{fontSize: '20px'}}>{item['Company name'] || item.name}</p>
                  </div>
                </div>
                <div className='mt-2 justify-content-center'>
                  {this.props.page === 'emails' ? 
                    <div>
                      <nav className="tree second email-list mt-3">
                        <SearchBox
                          className='searchbox m-0 p-0'
                        />
                        { item && item.children && item.children.length > 0 ?
                        <ScrollablePane className='mt-5' style={{width: 'calc(100% - 1px)', top:'70px'}} scrollbarVisibility={ScrollbarVisibility.auto}>
                        <DetailsList
                          className='list-sidebar emails'
                          styles={{}}
                          items={item.children}
                          constrainMode={ConstrainMode.unconstrained}
                          onRenderCheckbox={checkboxColumn}
                          selectionMode={SelectionMode.multiple}
                          checkboxVisibility={CheckboxVisibility.always}
                          enterModalSelectionOnTouch
                          columns={[
                            {
                              key: 'title',
                              name: 'Title',
                              fieldName: 'title',
                              minWidth: 1,
                              isRowHeader: true,
                              data: 'string',
                              isPadded: true,
                              onRender: (item: any) => {
                                return (
                                  <>
                                    <div className='d-flex align-items-center my-1'>
                                    <Persona styles={{root: {position:'absolute', left: '-37px', display: this._selection.getSelection()[0] && this._selection.getSelection()[0].key === item.key || this._selection.getSelection().length > 1  ? 'none' : 'block'}}} hidePersonaDetails text={item.from} size={PersonaSize.size32}/>
                                      <div className='align-items-center ml-3 flex-grow-1'>
                                        <div className='d-flex'>
                                          <span className='span-title'>{item.title}</span>
                                        </div>
                                        <div className='d-flex'>
                                          <span className='' style={{fontSize:12, color: '#424242'}}>{item.from}</span>
                                          <span className='ml-auto mr-3' style={{fontSize:12, color: '#424242'}}>{new Date(item.content.date).toLocaleDateString()}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              },
                              onRenderHeader: (item: any)=>{
                                return(
                                  <div className='d-flex align-items-center w-100'>
                                    <span> 
                                    Title
                                    </span>
                                    <ActionButton 
                                      className='ml-auto mr-3 px-0' 
                                      style={{color: 'rgb(50, 49, 48)'}} 
                                      iconProps={{iconName:'SortLines'}}
                                      //text={cardsSortedBy} 
                                      menuProps={{items: [
                                        {
                                          key: 'byDate',
                                          text: 'By date',
                                          iconProps: {iconName:'Calendar'},
                                          //onClick:()=>this.sortItems('date')
                                        },
                                        {
                                          key: 'byName',
                                          text: 'By title',
                                          iconProps: {iconName:'HalfAlpha'},
                                          //onClick:()=>this.sortItems('title')
                                        }
                                      ]}}
                                    />
                                  </div>
                              )},

                            },
                          ]}
                          selection={ this._selection }
                        /></ScrollablePane> : <div className='mt-2 text-center'>Empty</div> }
                      </nav>
                    </div>
                  : null }
                </div>
                {/*Object.keys(item).map(function(key) {
                  return (
                    item[key] && <div className='mt-2' key={key}>
                      <div className="text-secondary mr-2">{key}</div>
                      <div className="username font-weight-bold">{item[key]}</div>
                    </div>
                  )
                })*/}
              </div> 
              : null}
            </>}
            {item.type && <>
              <div className="tab-title text-center" style={{paddingTop: '13px', paddingBottom: '13px', background: '#f2f2f2'}}>
                <p className="lead font-weight-bold m-0">{i18n.t('app:summary')}</p>
              </div>
                <div className='mt-2' key={'name'}>
                  <div className="text-secondary mr-2">Activity title</div>
                  <div className="username font-weight-bold">{item.title}</div>
                </div>
                <div className='mt-2' key={'closeDate'}>
                  <div className="text-secondary mr-2">Author</div>
                  <div className="username font-weight-bold">{item.content.authorName || '---'}</div>
                </div>
                <div className='mt-2' key={'stage'}>
                  <div className="text-secondary mr-2">Date</div>
                  <div className="username font-weight-bold">{new Date(item.content.date).toLocaleDateString() || '---'}</div>
                </div>
            </>}
            
          
          </div>
        </Resizable>}

        {!item.type && <div className="" style={{padding: '0 3px 0 4px', background: '#f2f2f2', alignItems: 'flex-start',display: 'flex',flexDirection: 'column',justifyContent: 'flex-start',rowGap: '20px'}}>
          <TabList selectedValue={activeTab} onTabSelect={this._handleLinkClick} vertical>
            <Tab icon={<Icon iconName='Info'/>} value="summary" style={{height:'48px'}}></Tab>
            <Tab icon={<Icon iconName='DietPlanNotebook'/>} value="details" style={{height:'48px'}}></Tab>
          </TabList>
        </div>}

        </div>
      : null
    )
  }

  private _saveResizeState() {
    const { panelHeight, panelWidth } = this.state;
    var prefs = {
      height: panelHeight,
      width: panelWidth
    }
    localStorage.setItem("detailsPanelPrefs", JSON.stringify(prefs))
  }

}

function checkboxColumn(props: any, defaultRender?: IRenderFunction<IDetailsListCheckboxProps>): JSX.Element {
  return  (
    <div className='d-flex align-items-center justifty-content-center h-100 w-100'>
    {defaultRender!({
      ...props
    })}
  </div>
  )
}
