import * as React from 'react';
import "moment/min/locales";
import { Resizable } from 're-resizable';
import { Icon } from '@fluentui/react/lib/Icon';
import i18n from "i18next";
import { FileIconType, getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { Callout, DirectionalHint, Link, Persona, PersonaSize, TooltipHost } from '@fluentui/react';
import Moment from 'moment';
import { ActivityDetails } from './ActivityDetails';
import { userService } from '../_services';

export interface Props {
  match: {
    url: string
  }
}

export interface DetailsPanelState {
  repoData: any;
  showRightPanel: boolean;
  panelHeight: any;
  panelWidth: any;
  selItem: any;
  toolbarHeight: number;
  activeTab: string;
  calloutTarget: any;
  selUsers: any[];
}

export class DetailsPanel extends React.Component<Props, DetailsPanelState> {
  private _isMounted: boolean;
  private _windowWidth: number;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this._windowWidth = window.innerWidth;

    this.props = props

   var panelWidth = "420px"
   if (typeof(localStorage.getItem("detailsPanelPrefs")) === "string") {
      var panelPrefs = JSON.parse(localStorage.getItem("detailsPanelPrefs") || "[]");
      panelWidth = panelPrefs.width
   }

    this.state = {
      repoData: props.repoData,
      showRightPanel: props.showRightPanel,
      panelHeight: 'auto',
      panelWidth: panelWidth,
      selItem: props.selItem,
      toolbarHeight: 89,
      activeTab: 'info',
      calloutTarget: null,
      selUsers: [],
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    window.addEventListener('resize', this._onWindowResize);
  }

  public componentDidUpdate(prevProps, prevState) {
    if(this.props.repoData !== prevProps.repoData) {
      this._isMounted && this.setState({repoData: this.props.repoData})
    }

    if(this.props.selItem !== prevProps.selItem) {
      var savedActiveTab = localStorage.getItem("detailsPanelActiveTab")
      var activeTab = savedActiveTab ? savedActiveTab : "info"
      this._isMounted && this.setState({
        activeTab: activeTab,
        selItem: this.props.selItem
      })
    }

    if(this.props.showRightPanel !== prevProps.showRightPanel) {
      this._isMounted && this.props.callbackFunction({showRightPanel: this.props.showRightPanel})
      this._isMounted && this.setState({showRightPanel:  this.props.showRightPanel});
    }

    if (this.props.detailsPanelSection === 'groups' && this.props.selItem && this.props.selItem !== prevProps.selItem) {
      let userString = '';
      for (let i in this.props.selItem.users) {
        userString += ('&user='+this.props.selItem.users[i])
      }
      if (this.props.selItem.users.length > 0) {
        userService.getRepoUsers('ALL', userString).then((response)=>{
          let selUsers = response.data;
          this._isMounted && this.setState({selUsers: selUsers})
        })
      } else {
        this._isMounted && this.setState({selUsers: []})
      }

    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this._onWindowResize, false);
  }

  private _onWindowResize = () => {
    var toolbar : HTMLDivElement | null  = document.querySelector('.toolbar');
    this.setState({toolbarHeight: toolbar!.offsetHeight})
    if (window.innerWidth <= 980 && window.innerWidth < this._windowWidth) {
      this._isMounted && this.props.callbackFunction({showRightPanel: false})
      this._isMounted && this.setState({showRightPanel: false});
    }
    this._windowWidth = window.innerWidth;
  }

  private itemRender = (item: any) => {
    var messages:any = i18n.t('activityMessages:messages', {returnObjects: true})
    var itemText: string = "";
    if(item.type === "DOCUMENT"
          && item.operationType === "SEARCH") {
            itemText = "Files";
            return (
              <div>
                <span title={ itemText }>{ itemText }</span>
              </div>
            )
    } else if(item.operationType === "DUPLICATE" && item.targets && item.targets.length > 0 && item.targets[0].modelType === 'FILE') {
      return (
        <div>
          <Link className='mr-2' href={'https://drive.'+this.props.userData.repository.deploymentDomain+'/path/'+item.targets[0].id} title={item.targets[0].name ?? 'FILE'}>{item.targets[0].name ?? 'FILE'}</Link>
        </div>
      )
    } else if(item.operationType === "DUPLICATE" && item.destination && item.destination.modelType === 'FILE') {
      return (
        <div>
          <Link className='mr-2' href={'https://drive.'+this.props.userData.repository.deploymentDomain+'/path/'+item.destination.id} title={item.destination.name ?? 'FILE'}>{item.destination.name ?? 'FILE'}</Link>
        </div>
      )
    } else if(item.operationType === "DUPLICATE" && item.source && item.source.modelType === 'FOLDER') {
      return (
        <div>
          <Link className='mr-2' href={'https://drive.'+this.props.userData.repository.deploymentDomain+'/files/'+item.source.id} title={item.source.name ?? 'FOLDER'}>{item.source.name ?? 'FOLDER'}</Link>
        </div>
      )
    } else if (item.operationType === 'SEARCH' && item.targets && item.targets[0] && (item.targets[0].modelType === 'FILE' || item.targets[0].modelType === 'FOLDER')) {
      return (
        <div>
          <span>{item.targets[0].modelType === 'FOLDER' ?  i18n.t('policies:folders') : i18n.t('policies:files')}</span>
        </div>
      )
    } else if(item.targets && item.targets.length > 0) {
      let files = item.targets.filter((t)=>{return t.modelType === 'FILE'})
      let folders = item.targets.filter((t)=>{return t.modelType === 'FOLDER'})
      if (files.length > 0 && folders.length > 0) {
        return (
          <div>
            <span id={'details'+item.id} title={ itemText } onClick={() => this.setState({calloutTarget: item})} className="cursor-pointer mr-2">
              { files.length + folders.length + ' ' + i18n.t('activity:items') } <Icon iconName="CaretDownSolid8" className="small align-bottom ml-1" />
            </span>
          </div>
          
        )
      } else if (files.length > 1) {
        return (
          <div>
            <span id={'details'+item.id} title={ itemText } onClick={() => this.setState({calloutTarget: item})} className="cursor-pointer">
              { files.length + ' ' + i18n.t('activity:files') } <Icon iconName="CaretDownSolid8" className="small align-bottom ml-1" />
            </span>
          </div>
        )
      } else if (folders.length > 1) {
        return (
          <div>
            <span id={'details'+item.id} title={ itemText } onClick={() => this.setState({calloutTarget: item})} className="cursor-pointer">
              { folders.length + ' ' + i18n.t('activity:folders') } <Icon iconName="CaretDownSolid8" className="small align-bottom ml-1" />
            </span>
          </div>
        )
      } else if (item.targets[0] && item.targets[0].modelType === 'GROUP') {
        return (
          <div>
            <span title={item.targets[0].name}>{item.targets[0].name}</span>
          </div>
        )
      } else {
        return item.targets.map((target) => {
          if (target.modelType === 'USER') {
            return (
              <div>
                <Link href={'mail:'+target.email} title={target.email}>{target.email}</Link>
              </div>
            )
          } else if (target.modelType === 'FILE' || target.modelType === 'COMMENT' ) {
            return (
              <div>
                <Link className='mr-2' href={'https://drive.'+this.props.userData.repository.deploymentDomain+'/path/'+target.id} title={target.name ?? 'FILE'}>{target.name ?? 'FILE'}</Link>
              </div>
            )
          } else if (target.modelType === 'FOLDER') {
            return (
              <div>
                <Link className='mr-2' href={'https://drive.'+this.props.userData.repository.deploymentDomain+'/files/'+target.id} title={target.name ?? 'FOLDER'}>{target.name ?? 'FOLDER'}</Link>
              </div>
            )
          } else if (target.modelType === 'REVISION') {
            return (
              <div>
                <Link className='mr-2' href={'https://drive.'+this.props.userData.repository.deploymentDomain+'/path/'+target.id} title={target.name ?? item.source?.name ?? 'FILE'}>{target.name ?? item.source?.name ?? 'FILE'}</Link>
              </div>
            )
          } else if (target.modelType === 'TAG') {
            return (
              <div>
                <Link className='mr-2' href={'https://drive.'+this.props.userData.repository.deploymentDomain+'/path/'+target.id} title={target.name ?? 'FILE'}>{target.name ?? 'FILE'}</Link>
              </div>
            )
          } else if (target.modelType === 'SMART_FOLDER') {
            return (
              <div>
                <span></span>
              </div>
            )
          } else if (target.modelType === 'ZIP') {
            return (
              <div>
                <span title={target.name}>{target.name ?? 'ZIP'}</span>
              </div>
            )
          } else {
            return (
              <div>
                <span title={ messages.UNKNOWN_TARGET.DEFAULT }>{ messages.UNKNOWN_TARGET.DEFAULT }</span>
              </div>
            )
          }
        })
      }
    } else {
      return (
        <div>
          <span title={ itemText || "Unknown" }>{ itemText || "Unknown" }</span>
        </div>
      )
    }
  }

  public render() {
    const { showRightPanel, selItem, activeTab } = this.state;

    return(
      showRightPanel ?
        <Resizable
          size={{ width: this.state.panelWidth, height: this.state.panelHeight }}
          onResizeStop={(e, direction, ref, d) => {
            var currentWidth = this.state.panelWidth.match(/\d+/)[0];
            this.setState({
              panelWidth: +currentWidth + +d.width + "px"
            });
            this._saveResizeState();
          }}
          enable={{ top:false, right:false, bottom:false, left:true, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
          minWidth={360}
          maxWidth={480}
          handleStyles= {{
            left: {
              "left": "0px",
              "width": "6px"
            }
          }}
          handleClasses={{"left": "resize-handle"}}
        >
          <div className="details-panel pt-0 pb-3 px-3">
          { (selItem !== null && this.props._selection && this.props._selection.getSelectedCount() === 1) 
            || (selItem && this.props._selection && this.props._selection.getSelectedCount() === 0)
            || (selItem && !this.props._selection) ?
              <div>
                <div
                  aria-labelledby={this._getTabId(this.state.activeTab)}
                  role="tabpanel" className="position-relative"
                >
                  { activeTab === "info" ?
                    <div>
                      <div className="tab-title text-center sticky-top">
                        <p className="lead font-weight-bold m-0">{i18n.t('details:info')}</p>
                      </div>
                      <div className="d-flex my-3">
                        <div className="mr-0">
                          { selItem.fileType === "repo" ?
                            <Icon iconName="OfflineStorageSolid" className="file-type repoIcon" />
                          : selItem.fileType === "trash" ?
                            <Icon   iconName="Trash" className="file-type trash" />
                          : selItem.fileType === 'dire' ?
                            <Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 20, imageFileType: 'svg' }) } aria-hidden="true" className="file-type"/>
                          : selItem.fileType === 'file' ?
                            <Icon {...getFileTypeIconProps({ extension: selItem.icon, size: 20, imageFileType: 'svg' }) } aria-hidden="true" className="file-type"/>
                          : this.props.detailsPanelSection === 'users' ?
                            <Persona styles={{primaryText: {fontWeight: 700}}} size={12} text={selItem.name ? selItem.name + " " + (selItem.surname || '') : selItem.email}/> 
                          : this.props.detailsPanelSection === 'activity' || this.props.detailsPanelSection === 'sharing' ?
                            <span style={{fontWeight:700, fontSize:16}}>{selItem.action}</span>
                          : this.props.detailsPanelSection === 'approvals' ?
                            <span style={{fontWeight:700, fontSize:14}}>{selItem.action}</span>
                          : this.props.detailsPanelSection === 'sessions' ?
                            <Persona styles={{primaryText: {fontWeight: 700}}} size={12} text={selItem.name ? selItem.name + " " + (selItem.surname || '') : selItem.email}/> 
                          : <span style={{fontWeight:700, fontSize:14}}>{selItem.name}</span>
                          }
                        </div>
                      </div>
                      
                      {this.props.detailsPanelSection === 'users' && <ul className="p-0 my-3" style={{textAlign: 'left'}}>
                        { selItem.email &&<li className="mb-2">
                          <div className="text-secondary mr-2">Email:</div>
                          <div className="username font-weight-bold">{ selItem.email || "" }</div>
                        </li>}
                        { selItem.role &&<li className="mb-2">
                          <div className="text-secondary mr-2">Category:</div>
                          <div className="username font-weight-bold capitalize">{ selItem.role || "" }</div>
                        </li>}
                        { <li className="mb-2">
                          <div className="text-secondary mr-2">Last login:</div>
                          <div className="username font-weight-bold">{selItem.lastLogin ? Moment(selItem.lastLogin).format('Y-MM-DD HH:mm') : "-" }</div>
                        </li>}
                        { selItem.state &&<li className="mb-2">
                          <div className="text-secondary mr-2">State:</div>
                          <div className="username font-weight-bold capitalize">{ selItem.state || "" }</div>
                        </li>}
                      </ul>}
                      {this.props.detailsPanelSection === 'groups' && <ul className="p-0 my-3" style={{textAlign: 'left'}}>
                        <li className="mb-2">
                          <div className="text-secondary mr-2">Date created:</div>
                          <div className="username font-weight-bold">{ Moment(selItem.date_created).format('Y-MM-DD HH:mm') }</div>
                        </li>
                        <li className="mb-2">
                          <div className="text-secondary mr-2">Users:</div>
                          {this.state.selUsers.sort((a:any,b:any) => {
                            return ((a.name || '') + (a.surname || '')).localeCompare((b.name || '') + (b.surname || ''));
                          }).map((user)=>{
                          return (
                            <div key={user.id} className='d-flex mt-2'>
                              <Persona styles={{primaryText: {fontSize: 12}}} size={10} text={user.name ? (user.name || '') + " " + (user.surname || '') : user.email} secondaryText={user.email} showSecondaryText
                              onRenderSecondaryText={()=>{return(<a href={'mailto:'+user.email}>{user.email}</a>)}} />
                            </div>
                          )
                        })}
                        </li>
                      </ul>}
                      {(this.props.detailsPanelSection === 'activity' ||  this.props.detailsPanelSection === 'sharing') && <ul className="p-0 my-3" style={{textAlign: 'left'}}>
                        <li className="mb-2">
                          <div className="text-secondary mr-2">User:</div>
                          <div className="username font-weight-bold">{ selItem.user || "" }</div>
                        </li>
                        <li className="mb-2">
                          <div className="text-secondary mr-2">Date:</div>
                          <div className="username font-weight-bold">{ selItem.date ? Moment(selItem.date).format('Y-MM-DD HH:mm') : "-" }</div>
                        </li>
                       {<li className="mb-2">
                          <div className="text-secondary mr-2">Item:</div>
                          <div className="username font-weight-bold">{this.itemRender(selItem)}</div>
                        </li>}
                        {<li className="mb-2">
                          <div className="username font-weight-bold"><ActivityDetails item={ selItem } detailspanel repoUsers={this.props.repoUsers} userData={this.props.userData} /></div>
                        </li>}
                       
                      </ul>}
                      {(this.props.detailsPanelSection === 'approvals') && <ul className="p-0 my-3" style={{textAlign: 'left'}}>
                        <li className="mb-2">
                          <div className="text-secondary mr-2">State:</div>
                          <div className="username font-weight-bold">{selItem.approved ? 'Approved' : selItem.denied ? 'Denied' : 'Pending' }</div>
                        </li>
                        <li className="mb-2">
                          <div className="text-secondary mr-2">Created by:</div>
                          <TooltipHost
                            key={'createdBy'}
                            content= {
                              <div className='m-1'>
                                <Persona className='mb-2' text={selItem.name} initialsColor="#7F7F7F" size={PersonaSize.size32}/>
                                <span className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</span>
                                <p className='p-0 m-0' style={{fontSize:'14px'}}>{selItem.account}</p>
                              </div>
                            }
                            id={'createdBy'}
                            calloutProps={{ gapSpace: 0 }}
                          >
                            <span className="font-weight-bold persona-link">{ selItem.name || "" }</span>
                          </TooltipHost>
                        </li>
                        {selItem.requestMessage && selItem.requestMessage.length > 0 &&<li className="mb-2">
                          <div className="text-secondary mr-2">Message:</div>
                          <div className="username font-weight-bold">{selItem.requestMessage}</div>
                        </li>}
                        <li className="mb-2">
                          <div className="text-secondary mr-2">Date:</div>
                          <div className="username font-weight-bold">{Moment(selItem.date).format('Y-MM-DD HH:mm') }</div>
                        </li>
                       {<li className="mb-2">
                          <div className="text-secondary mr-2">Item:</div>
                          <a href='#' className="username font-weight-bold">{selItem.item}</a>
                        </li>}
                        {selItem.item && <li className="mb-2">
                          <div className="text-secondary mr-2">Details:</div>
                          <a href='#' className="username font-weight-bold">View</a>
                        </li>}
                        {(selItem.approved || selItem.denied) && <li className="mb-2">
                          <div className="text-secondary mr-2">{selItem.approved  ? 'Approved by:' : 'Denied by:'}</div>
                          <TooltipHost
                            key={'by'}
                            content= {
                              <div className='m-1'>
                                <Persona className='mb-2' text={selItem.by} initialsColor="#7F7F7F" size={PersonaSize.size32}/>
                                <span className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</span>
                                <p className='p-0 m-0' style={{fontSize:'14px'}}>{'jaime@synergy.page'}</p>
                              </div>
                            }
                            id={'by'}
                            calloutProps={{ gapSpace: 0 }}
                          >
                            <span className="font-weight-bold persona-link">{ selItem.by}</span>
                          </TooltipHost>
                        </li>}
                        {selItem.finalMessage && selItem.finalMessage.length > 0 &&<li className="mb-2">
                          <div className="text-secondary mr-2">{selItem.approved  ? 'Approved message:' : 'Denied message:'}</div>
                          <div className="username font-weight-bold">{selItem.finalMessage}</div>
                        </li>}
                       
                      </ul>}
                      {(this.props.detailsPanelSection === 'sessions') && <ul className="p-0 my-3" style={{textAlign: 'left'}}>
                        <li className="mb-2">
                          <div className="text-secondary mr-2">Email:</div>
                          <div className="username font-weight-bold">{selItem.user.email}</div>
                        </li>
                        <li className="mb-2">
                          <div className="text-secondary mr-2">Date:</div>
                          <div className="username font-weight-bold">{ Moment(selItem.loginDate).format('Y-MM-DD HH:mm') }</div>
                        </li>
                        <li className="mb-2">
                          <div className="text-secondary mr-2">Location:</div>
                          <div className="username font-weight-bold">{(selItem.city ? selItem.city + ', ' : '') + (selItem.country || '-')}</div>
                        </li>
                        <li className="mb-2">
                          <div className="text-secondary mr-2">Operating system:</div>
                          <div className="username font-weight-bold">{selItem.browser === 'CFNetwork' ? 'iOS' : selItem.deviceOs ? selItem.deviceOs : '-'}</div>
                        </li>
                        <li className="mb-2">
                          <div className="text-secondary mr-2">Browser:</div>
                          <div className="username font-weight-bold">{selItem.browser ? selItem.browser : '-'}</div>
                        </li>
                        <li className="mb-2">
                          <div className="text-secondary mr-2">IP:</div>
                          <div className="username font-weight-bold">{selItem.ip}</div>
                        </li>
                        <li className="mb-2">
                          <div className="text-secondary mr-2">App:</div>
                          <div className="username font-weight-bold">{selItem.application ? selItem.application : '-'}</div>
                        </li>
                        {selItem.item && <li className="mb-2">
                          <div className="username font-weight-bold"><ActivityDetails item={ selItem } detailspanel repoUsers={this.props.repoUsers} userData={this.props.userData} /></div>
                        </li>}
                       
                      </ul>}
                    </div>
                  : null}
                </div>
              </div>
            : this.props._selection && this.props._selection.getSelectedCount() > 1 ?
              <p className="lead font-weight-bold text-center my-2">{ this.props._selection.getSelectedCount() } {i18n.t('details:itemsSelected')}</p>
            :
              <p className="lead font-weight-bold text-center my-2">{i18n.t('details:noItemSelected')}</p>
            }
          </div>
          {this.state.calloutTarget ? (
            <Callout
              className={"activity-items-callout"}
              gapSpace={0}
              target={`#details${this.state.calloutTarget.id}`}
              isBeakVisible={true}
              onDismiss={() => this.setState({calloutTarget: null})}
              directionalHint={DirectionalHint.bottomLeftEdge}
              setInitialFocus
            >
              <ul className="m-0 p-0" style={{listStyleType: 'none'}}>
                {this.state.calloutTarget.targets.map((target) => {
                  if (target.modelType === 'FOLDER') {
                    return (
                      <li key={target.id} className="mb-2">
                        <Link href={'https://drive.'+this.props.userData.repository.deploymentDomain+'/files/'+target.id} title={target.id}>{target.name ?? 'Folder'}</Link>
                      </li>
                    )
                  } else if (target.modelType === 'FILE') {
                    return (
                      <li key={target.id} className="mb-2">
                        <Link href={'https://drive.'+this.props.userData.repository.deploymentDomain+'/path/'+target.id} title={target.id}>{target.name ?? 'FILE'}</Link>
                      </li>
                    )
                  }
                })}
              </ul>
            </Callout>
          ) : null}
        </Resizable>

      : null
    )
  }

  private _saveResizeState() {
    const { panelHeight, panelWidth } = this.state;
    
    var prefs = {
      height: panelHeight,
      width: panelWidth
    }

    localStorage.setItem("detailsPanelPrefs", JSON.stringify(prefs))
  }

  private _handleLinkClick = (item: any): void => {
    this.setState({
      activeTab: item.props.itemKey
    }, () => {
      localStorage.setItem("detailsPanelActiveTab", this.state.activeTab)
    });
  };

  private _getTabId = (itemKey: string): string => {
    return `${itemKey}`;
  };

}