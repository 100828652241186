import { Icon, Spinner, IPersonaProps, TooltipHost, Modal, Dialog, DialogFooter, DialogType, Text } from '@fluentui/react';
import { ValidationState } from '@fluentui/react/lib/Pickers';
import { FluentProvider, Persona, SelectTabEvent, Button, webLightTheme, Avatar, Textarea, Link } from '@fluentui/react-components';
import * as React from 'react';
import i18n from '../../i18n';
import { userService } from '../../_services/user.service';
import * as DOMPurify from 'dompurify';
import ReactDOM from 'react-dom';
import { ClockArrowDownload20Regular, Checkmark20Regular, PresenceBlocked20Regular } from '@fluentui/react-icons';

export interface Props {
  allCardsCollapsed: boolean;
  item: any;
  userData: any;
}

export interface States {
  collapsed: boolean;
  message: string;
  item: any;
  possibleContacts: any[];
  currentContacts: any[];
  contactsCC: any[];
  contactsBCC: any[];
  replyTo: any[];
  replyToAll: any[];
  replyCcAll: any[];
  showCurrentContacts: boolean;
  loadingEntities: boolean;
  loading: boolean;
  page: number;
  totalPages: number;
  editingMessage: string;
  editMessage: string;
  markdown: any;
  tabSelected: string;
  markdownNew: any;
  tabSelectedNew: string;
  showReply: boolean;
  showCC: boolean;
  showBCC: boolean;
  attachments: any[];
  attachmentsInline: any[];
  attachmentsOutline: any[];
  savingAttachment: boolean;
}

export class ConnectInvite extends React.Component<Props, States> {
  private _isMounted: boolean;
  private throttleTimer: boolean;
  private loadingMoreResults: boolean
  private listener: boolean = false;

  props: any;

  constructor(props: any) {
    super(props);
    this._isMounted = false;
    this.throttleTimer = false;
    this.loadingMoreResults = false;
    this.props = props

    this.state = {
      collapsed: this.props.allCardsCollapsed,
      message: '',
      item: this.props.item,
      possibleContacts: this.props.possibleContacts,
      currentContacts: [],
      contactsCC: [],
      contactsBCC: [],
      replyTo: [],
      replyToAll: [],
      replyCcAll: [],
      showCurrentContacts: false,
      loadingEntities: true,
      loading: true,
      page: this.props.item.page,
      totalPages: this.props.item.totalPages || 0,
      editingMessage: '',
      editMessage: '',
      markdown: null,
      tabSelected: 'write',
      markdownNew: null,
      tabSelectedNew: 'write',
      showReply: false,
      showCC: false,
      showBCC: false,
      attachments: [],
      attachmentsInline: [],
      attachmentsOutline: [],
      savingAttachment: false
    };
  }

  public componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.setState({loading: false})
    const params = new URLSearchParams(document.location.search);
    let messageId = params.get('messageId') || params.get('message') || null
    if (this.state.possibleContacts && this.state.possibleContacts.length > 0) {
      this._isMounted && this.setState({loadingEntities: false, loading: false}, ()=>{
        if (this.props.target || messageId) document.getElementById(messageId || this.props.target)?.scrollIntoView(true)
      })
    } else {
      userService.getRepoUsers('ADMIN').then((response)=>{
        let admins = response.data;
        for (let i=0; i<admins.length; i++) {
          admins[i].key = admins[i].id
          admins[i].text = admins[i].name + (admins[i].surname ? (' ' + admins[i].surname) : '')
        }
        userService.getRepoUsers('INTERNAL').then((response)=>{
          let contacts = response.data;
          for (let i=0; i<contacts.length; i++) {
              contacts[i].key = contacts[i].id
              contacts[i].text = contacts[i].name + (contacts[i].surname ? (' ' + contacts[i].surname) : '')
          }
          this.setState({possibleContacts: contacts.concat(admins)})
          this._isMounted && this.setState({loadingEntities: false, loading: false}, ()=>{
            if (this.props.target || messageId) document.getElementById(messageId || this.props.target)?.scrollIntoView(true)
          })
        }).catch(()=>{
          this._isMounted && this.setState({loadingEntities: false, loading: false})
        })
      }).catch(()=>{
        this._isMounted && this.setState({loadingEntities: false, loading: false})
      })
    }
  }
  
  public componentDidUpdate(prevProps:any, prevState: any) {
    if (prevProps.allCardsCollapsed !== this.props.allCardsCollapsed) {
      this._isMounted && this.setState({collapsed: this.props.allCardsCollapsed})
    }
    if (this.props.item !== prevProps.item) {
      this._isMounted && this.setState({item: this.props.item, totalPages: this.props.item.totalPages, page: this.props.item.page})
    }
    if (this.state.loading !== prevState.loading && !this.listener) {
      document.getElementById('main-view')?.addEventListener("scroll", this.handleInfiniteScroll);
      this.listener = true;
    }
  }

  public componentWillUnmount() {
    this._isMounted = false;
    document.getElementById('main-view')?.removeEventListener("scroll", this.handleInfiniteScroll, false);
    this.listener = false;
  }

  private getContactName(id: string) {
    let user = this.state.possibleContacts.find((item) => {
      return item.id === id;
    })

    return user ? (user.name || '') + ' ' + (user.surname || '') : ''
  }

  private getContactNameTooltip(id: string) {
    let user = this.state.possibleContacts.find((item) => {
      return item.id === id;
    })

    return (
      user ? <TooltipHost
        content= {
          <div className='m-1'>
            <FluentProvider>
              <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
              <Persona className='mb-2' name={(user.name || '') + ' ' + (user.surname || '')} secondaryText={user.email} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
            </FluentProvider>
          </div>
        }
      >
        <span>{(user.name || '') + ' ' + (user.surname || '')}</span>
      </TooltipHost> : ''
    )
  }

  private getPersona(id: string, text = true) {
    let user = this.state.possibleContacts.find((item) => {
      return item.id === id;
    })
    return (
      <TooltipHost
        content= {
          <div className='m-1'>
            <FluentProvider>
              <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
              <Persona className='mb-2' name={(user?.name || '') + ' ' + (user?.surname || '')} secondaryText={user?.email || ''} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
            </FluentProvider>
          </div>
        }
      >
        {text ? <Persona style={{position: 'relative', top: '4px', fontWeight: 600}} className='pe-1' size='extra-small' name={(user?.name || '') + ' ' + (user?.surname || '')} textAlignment='center' avatar={{ color: "colorful" }}/>
        : <Avatar name={(user?.name || '') + ' ' + (user?.surname || '')} color='colorful'/>}
      </TooltipHost>
    )
  }

  private getUsername(id: string) {
    let user = this.state.possibleContacts.find((item) => {
      return item.id === id;
    })
    return (
      <TooltipHost
        content= {
          <div className='m-1'>
            <FluentProvider>
              <p className='font-weight-bold' style={{fontSize:'14px'}}>{i18n.t('app:Contact')}</p>
              <Persona className='mb-2' name={(user?.name || '') + ' ' + (user?.surname || '')} secondaryText={user?.email || ''} size='medium' textAlignment='center' avatar={{ color: "colorful" }}/>
            </FluentProvider>
          </div>
        }
      >
        <span className='me-1 messages-username'>{(user?.name || '') + ' ' + (user?.surname || '')}</span>
      </TooltipHost>
    )
  }

  private throttle = (callback, time) => {
    if (this.throttleTimer) return;
    this.throttleTimer = true;
    setTimeout(() => {
      callback();
      this.throttleTimer = false;
    }, time);
  };

  private handleInfiniteScroll = () => {
    this.throttle(() => {
      const endOfPage =
        (document.getElementById('main-view')?.offsetHeight || 0) + (document.getElementById('main-view')?.scrollTop || 0) >= (document.getElementById('main-view-conversation')?.offsetHeight || 0) - 300;
        if (endOfPage) {
          //this.loadNextPage();
        }
    }, 500);
  };

  private _openEditMode(id) {
    let message = this.state.item.messages.find((item)=>{return item.id === id})
    this._isMounted && this.setState({editingMessage: id, editMessage: message.content, attachments: [], attachmentsInline: [], attachmentsOutline: message.attachments.filter((a)=>{return !a.inline}), showReply: false})
  }

  private _closeEditMode(id) {
    let message = this.state.item.messages.find((item)=>{return item.id === id})
    this._isMounted && this.setState({editingMessage: '', editMessage: message.content, attachments: [], attachmentsInline: [], attachmentsOutline: []})
  }

  private convertToMarkdown(item, message = '') {
    try {
      var MarkdownIt = require('markdown-it');
      var emoji = require('markdown-it-emoji');
      var md = new MarkdownIt();
      md.use(emoji);
      var result = md.render(DOMPurify.sanitize(message));
      const attachmentsInline = item.attachments.filter((a)=>{return a.inline})
      for (let i in attachmentsInline) {
        var replace = "src=\""+attachmentsInline[i].id+"\"";
        result = result.replace(replace, 'src="' + attachmentsInline[i].blob + '"')
      }
      let markdown:any = { __html: result };
      return markdown;
    } catch {
      return message;
    }

  }

  private getMarkdown(messageId:string) {
    var MarkdownIt = require('markdown-it');
    var emoji = require('markdown-it-emoji');
    var md = new MarkdownIt();
    md.use(emoji);
    var result = md.render(DOMPurify.sanitize(this.state.editMessage || ""));

    const {attachmentsInline, item} = this.state;
    let message = item.messages.find(m => {return m.id === messageId})
    if (message) {
      console.log(message.attachments)
      for (let i in message.attachments) {
        var replace = "src=\""+message.attachments[i].id+"\"";
        result = result.replace(replace, 'src="' + message.attachments[i].blob + '"')
      }
    }
    for (let i in attachmentsInline) {
      var replace = "src=\""+attachmentsInline[i].id+"\"";
      result = result.replace(replace, 'src="' + attachmentsInline[i].file + '"')
    }

    let markdown = { __html: result };
    this.setState({markdown: markdown})
  }

  private _handleTabSelect = (e:SelectTabEvent, data: any, id:string): void => {
    if (data.value === 'preview' && this.state.tabSelected === 'write') {
      this.getMarkdown(id);
    }
    this._isMounted && this.setState({
      tabSelected: data.value
    });
  };

  private getMarkdownNew() {
    let message = this.state.message || "";
    var MarkdownIt = require('markdown-it');
    var emoji = require('markdown-it-emoji');
    var md = new MarkdownIt();
    md.use(emoji);
    var result = md.render(DOMPurify.sanitize(message));

    const {attachmentsInline} = this.state;
    for (let i in attachmentsInline) {
      var replace = "src=\""+attachmentsInline[i].id+"\"";
      result = result.replace(replace, 'src="' + attachmentsInline[i].file + '"')
    }

    let markdown = { __html: result };
    this.setState({markdownNew: markdown})
  }

  private _handleTabSelectNew = (e:SelectTabEvent, data: any): void => {
    if (data.value === 'preview' && this.state.tabSelected === 'write') {
      this.getMarkdownNew();
    }
    this._isMounted && this.setState({
      tabSelectedNew: data.value
    });
  };

  public render() {

    return(
      this.state.loading ? <Spinner className='mt-4'/> : 
      <div className='h-100 d-flex flex-column'>

        <div id='main-view' className='flex-grow-1' style={{overflowY: 'auto', backgroundColor: 'rgb(250, 249, 248)'}}>
          <div id='main-view-conversation'>
            <div className='d-flex'>
              <div className='mx-2' style={{marginTop: '20px'}}>{this.getPersona(this.state.item.createdByUserId,false)}</div>
              <div className='me-2 mt-2 flex-grow-1' style={{background: 'white', border: '1px solid #bfbfbf', borderRadius: '4px'}}>
                <div>
                  <div className='d-flex align-items-center mx-3 pt-3' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
                    <span className='pl-1'>{this.getUsername(this.state.item.createdByUserId)}</span>
                    <span>is inviting you to connect to</span>
                    <Link className='px-1' href='https://greendation.org'>https://greendation.org</Link>
                  </div>
                </div>
                <div className='flex-grow-1 markdown-frame mx-3 mt-3' style={{overflowY: 'auto', backgroundColor: 'white', borderRadius: '4px', border: '1px solid #d1d1d1', padding: '6px 12px'}}>
                  <div className='markdown-body' dangerouslySetInnerHTML={this.state.markdown}>
                  <p className=''>
                    Hello,
                    <br/><br/>
                    As discussed before, I'm sending an invite so you can have access to the folder right away.
                  </p>
                  </div>
                </div>
                {this.state.item.accepted ? <div className='d-flex align-items-center mx-3 my-3'>
                  <span className='ms-auto'>{'Connection approved'}</span>
                  <Checkmark20Regular className='ms-2' style={{color: 'green'}}/>
                </div> : this.state.item.declined ? <div className='d-flex align-items-center mx-3 my-3'>
                  <span className='ms-auto'>{'Connection declined'}</span>
                  <PresenceBlocked20Regular className='ms-2' style={{color: 'firebrick'}}/>
                </div> : <div className='d-flex align-items-center mx-3 my-3'>
                  <span className='ms-auto'>{'Waiting for admin approval'}</span>
                  <ClockArrowDownload20Regular className='ms-2'/>
                </div>}
              </div>
            </div>
            {<div className='activity-line'/>}
            {<div className='activity-content'>
              <div className='activity-icon'>
                <Icon className='m-auto' iconName='RedEye'/>
              </div>
              <div className='activity-body'>
                <span>{this.getPersona(this.state.item.createdByUserId)}</span>
                <span >requested approval from Admin 1 hour ago</span>
              </div>
            </div>}
            {(!this.state.item.accepted && !this.state.item.declined) && <div className='d-flex'>
              <div className='mx-2' style={{marginTop: '12px'}}>{this.getPersona(this.state.item.createdByUserId,false)}</div>
              <div className='me-2 flex-grow-1' style={{background: 'white', border: '1px solid #bfbfbf', borderRadius: '4px'}}>
                <div>
                  <div className='d-flex align-items-center mx-3 mt-3' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
                    <span>Approval required</span>
                  </div>
                  
                  <div className='d-flex align-items-center mx-3 mt-3' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
                    <Textarea
                      id={'text-area'}
                      className='flex-grow-1 h-100'
                      //value={this.state.text} 
                      style={{height: '100px'}}
                      textarea={{style:{height: '100%', maxHeight: '100%'}}}
                    />
                  </div>
                  {!this.state.item.accepted && !this.state.item.declined && !this.state.item.pending && <div className='d-flex align-items-center mx-3 my-3'>
                    <Button className='ms-auto' onClick={()=>{this.decline()}} >{i18n.t('app:decline')}</Button>
                    <Button appearance='primary' className='ms-3' onClick={()=>{this.accept()}} >{i18n.t('app:accept')}</Button>
                  </div>}
                  {this.state.item.accepted ? <div className='d-flex align-items-center mx-3 my-3'>
                    <span className='ms-auto'>{'Connection approved'}</span>
                    <Checkmark20Regular className='ms-2' style={{color: 'green'}}/>
                  </div> : this.state.item.declined ? <div className='d-flex align-items-center mx-3 my-3'>
                    <span className='ms-auto'>{'Connection declined'}</span>
                    <PresenceBlocked20Regular className='ms-2' style={{color: 'firebrick'}}/>
                  </div> : this.state.item.pending ? <div className='d-flex align-items-center mx-3 my-3'>
                    <span className='ms-auto'>{'Waiting for admin approval'}</span>
                    <ClockArrowDownload20Regular className='ms-2'/>
                  </div> : null}
                </div>
              </div>
            </div>}
            {this.state.item.accepted && <div className='activity-content'>
              <div className='activity-icon'>
                <Icon className='m-auto' iconName='CheckMark'/>
              </div>
              <div className='activity-body'>
                <span>{this.getPersona(this.state.item.createdByUserId)}</span>
                <span>approved connection request 42 minutes ago</span>
              </div>
            </div>}
            {this.state.item.declined && <div className='activity-content'>
              <div className='activity-icon'>
                <Icon className='m-auto' iconName='Cancel'/>
              </div>
              <div className='activity-body'>
                <span>{this.getPersona(this.state.item.createdByUserId)}</span>
                <span>declined connection request 42 minutes ago</span>
              </div>
            </div>}
            {(this.state.item.declined || this.state.item.accepted) &&<div className='d-flex'>
              <div className='mx-2' style={{marginTop: '12px'}}>{this.getPersona(this.state.item.createdByUserId,false)}</div>
              <div className='me-2 flex-grow-1' style={{background: 'white', border: '1px solid #bfbfbf', borderRadius: '4px'}}>
                <div>
                  <div className='d-flex align-items-center mx-3 pt-3' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
                    <span className='pl-1'>{this.getUsername(this.state.item.createdByUserId)}</span>
                    <span>left a comment</span>
                  </div>
                </div>
                <div className='flex-grow-1 markdown-frame mx-3 my-3' style={{overflowY: 'auto', backgroundColor: 'white', borderRadius: '4px', border: '1px solid #d1d1d1', padding: '6px 12px'}}>
                  <div className='markdown-body' dangerouslySetInnerHTML={this.state.markdown}>
                  <p className=''>
                    {this.state.item.accepted ? "Request approved, you're connected with the company now." : "Request declined. This company does not come up in any current project."}
                  </p>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    )
  }

  private validateInput(input: string): ValidationState {
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  }

  private _confirmAction = async () => {
    return await new Promise((resolve, reject) => {
        var showDialog = true;
        var loading= false;

        var confirm = () => {
          showDialog = false;
          renderDialog();
          resolve(true);
        }

        var cancel = () => {
          showDialog = false;
          renderDialog()
          reject()
        }

        var div = document.createElement('div');
        var renderDialog = () => {
          ReactDOM.render(
            <Dialog
            hidden={!showDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: i18n.t('app:declineConnection'),
              onDismiss: ()=> {showDialog = false}
            }}
            modalProps={{
              isBlocking: true,
              styles: { main: { maxWidth: 360 } },
              dragOptions: undefined,
              className: "ask-delete-item-dialog",
              onDismiss: ()=> {showDialog = false}
            }}
            styles={{main: {minHeight: '100px'}}}
          >
            <FluentProvider theme={webLightTheme} className="w-100">
              <Text styles={{root: {paddingBottom: '20px'}}} block variant={'medium'}>
                {i18n.t('app:declineConnection2')}
              </Text>
              
              <DialogFooter>
                <Button disabled={loading} onClick={()=>{cancel()}}>{i18n.t('app:cancel')}</Button>
                <Button appearance='primary' disabled={loading} autoFocus onClick={ ()=>{confirm()} }>{i18n.t('app:decline')}</Button>
              </DialogFooter>
            </FluentProvider>
          </Dialog>
          , div
          )
        }
      renderDialog();
    })
  }

  private decline() {
    this._confirmAction().then((result:any)=>{
      if (result) {
        let item = this.state.item
        item.pending = false;
        item.declined = true;
        this.props.updateConversation(item)
      }
    }).catch((error)=>{
      if (error && this.props.headerRef) {
        this.props.headerRef.showAlertDialog('Error', "An error occurred trying to decline invitation.")
      }
    })
  }

  private accept() {
    let item = this.state.item
    item.pending = false;
    item.accepted = true;
    this.props.updateConversation(item)
  }

}

function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
  if (!personas || !personas.length || personas.length === 0) {
      return false;
  }
  return personas.filter(item => item.text === persona.text).length > 0;
}
